var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-container',[_c('div',{staticClass:"test-wrap"},[_c('step-line',{attrs:{"active":_vm.realStep,"list":_vm.steps},on:{"update":_vm.changeStep}}),_c('div',{staticStyle:{"margin-left":"20px"}},[(_vm.realStep > 0)?_c('div',[_c('div',{staticStyle:{"position":"sticky","top":"0","right":"0","z-index":"1","background":"#f8f8f8","padding":"20px","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.formList.content)+" ")]),_vm._l((_vm.formList.componentList),function(label,index){return _c('el-card',{key:index,staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v(_vm._s(label.optionName))])]),_c('div',[_c('testProject',{key:label.id,ref:'testProject',refInFor:true,attrs:{"keyType":label.id,"type":label.optionType},model:{value:(label.content),callback:function ($$v) {_vm.$set(label, "content", $$v)},expression:"label.content"}},[_c('div',{attrs:{"slot":"tube"},slot:"tube"},[_c('el-select',{attrs:{"placeholder":"请选择用药方案"},on:{"change":function($event){return _vm.handleDosage(index,$event)}},model:{value:(label.content.dosageId),callback:function ($$v) {_vm.$set(label.content, "dosageId", $$v)},expression:"label.content.dosageId"}},_vm._l((_vm.dosageList),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1),(label.plate)?_c('div',{staticStyle:{"margin-top":"20px"}},[[(label.plate.dosageDrugList)?_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":label.plate.dosageDrugList,"border":""}},[_c('el-table-column',{attrs:{"prop":"drugName","label":"药物名称"}}),_c('el-table-column',{attrs:{"prop":"holeName","label":"孔位"}}),_c('el-table-column',{attrs:{"prop":"cmaxValue","label":"Cmax"}})],1):_vm._e()]],2):_vm._e(),_c('test-tube-plus',{attrs:{"data":{
                    count: label.plateHoleCount  || 0,
                    experimentId: _vm.experimentId,
                    hole: label.content.$specifications,
                    barcode: label.content.barcode,
                    optionType: label.optionType
                  }},model:{value:(label.plate),callback:function ($$v) {_vm.$set(label, "plate", $$v)},expression:"label.plate"}})],1),_c('div',{attrs:{"slot":"tubeDay"},slot:"tubeDay"},[_c('testTubeDayPlus',{attrs:{"data":{
experimentId: _vm.experimentId,
type: 5,
stepId: _vm.stepId
                  }},model:{value:(label.plate),callback:function ($$v) {_vm.$set(label, "plate", $$v)},expression:"label.plate"}})],1),_c('div',{attrs:{"slot":"drugTube"},slot:"drugTube"},[_c('testTubeDosing',{attrs:{"data":{experimentId: _vm.experimentId, stepId: _vm.stepId}},model:{value:(label.content.drugTube),callback:function ($$v) {_vm.$set(label.content, "drugTube", $$v)},expression:"label.content.drugTube"}})],1),_c('div',{attrs:{"slot":"ATPTest"},slot:"ATPTest"},[_c('testTubeDosingAtp',{attrs:{"data":{experimentId: _vm.experimentId}},model:{value:(label.content),callback:function ($$v) {_vm.$set(label, "content", $$v)},expression:"label.content"}})],1)])],1)])})],2):_c('div',{staticStyle:{"margin-top":"20px"}},[_c('step1',{ref:"step1Valid",attrs:{"exp-id":_vm.experimentId},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',{staticClass:"button-group"},[(_vm.realStep + 1 !== _vm.steps.length)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("下一步"),_c('i',{staticClass:"el-icon-arrow-right el-icon--right"})]):_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("保存")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }