var render = function render(){var _vm=this,_c=_vm._self._c;return _c('basic-container',[_c('div',{staticClass:"test-wrap"},[_c('step-line',{attrs:{"active":_vm.realStep,"list":_vm.steps},on:{"update":_vm.changeStep}}),_c('div',[(_vm.realStep > 0)?_c('div',[_c('div',{staticStyle:{"position":"sticky","top":"0","right":"0","z-index":"1","background":"#f8f8f8","padding":"20px","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.formList.content)+" ")]),_vm._l((_vm.formList.componentList),function(label,index){return _c('el-card',{key:index,staticClass:"box-card"},[_c('div',{staticClass:"clearfix",attrs:{"slot":"header"},slot:"header"},[_c('span',[_vm._v(_vm._s(label.optionName))])]),_c('div',[_c('testProject',{key:label.stepId,ref:"testProject",refInFor:true,attrs:{"type":label.optionType},model:{value:(label.content),callback:function ($$v) {_vm.$set(label, "content", $$v)},expression:"label.content"}},[(label.optionType == 9)?_c('div',{attrs:{"slot":"tube"},slot:"tube"},[_c('test-tube-plus',{attrs:{"data":{
                    count: _vm.plateHoleCount || parseInt(label.content.$specifications),
                    experimentId: _vm.experimentId,
                    hole: label.content.$specifications,
                    barcode: label.content.barcode,
                  }},model:{value:(label.plate),callback:function ($$v) {_vm.$set(label, "plate", $$v)},expression:"label.plate"}})],1):_vm._e(),_c('div',{attrs:{"slot":"tubeDay"},slot:"tubeDay"},[_c('testTubeDayPlus',{attrs:{"data":{
type: 1,
experimentId: _vm.experimentId,
stepId: _vm.stepId
                  }},model:{value:(label.plate),callback:function ($$v) {_vm.$set(label, "plate", $$v)},expression:"label.plate"}})],1)])],1)])})],2):_c('div',{staticStyle:{"margin-top":"20px"}},[_c('step1',{ref:"step1Valid",attrs:{"exp-id":_vm.experiment.id},model:{value:(_vm.form),callback:function ($$v) {_vm.form=$$v},expression:"form"}})],1),_c('div',{staticClass:"button-group"},[(_vm.realStep + 1 !== _vm.steps.length)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("下一步"),_c('i',{staticClass:"el-icon-arrow-right el-icon--right"})]):_c('el-button',{attrs:{"icon":"el-icon-check","type":"primary"},on:{"click":_vm.nextStep}},[_vm._v("保存")])],1)])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }