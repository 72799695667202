<template>
    <basic-container>
        <div class="filter-container space-between">
            <div class="search-container start">
                <el-input
@keyup.enter.native="handleFilter"
class="filter-item"
placeholder="类型"
                          v-model="page.type"></el-input>
                <el-button class="filter-item" type="primary" icon="search" @click="handleFilter">搜索</el-button>
                <el-button class="filter-item" @click="handleCreate" type="primary" icon="edit">
                    添加
                </el-button>
            </div>
            <div>
            </div>
        </div>
        <el-table
            lazy
            @expand-change="expandTable"
:data="list"
v-loading.body="listLoading"
element-loading-text="拼命加载中"
border
fit
                  highlight-current-row>
          <el-table-column type="expand" :key="detailLoading">
            <template slot-scope="props">
              <div style="padding: 20px">
                  <el-skeleton :rows="6" animated v-if="!props.row.detail"/>
                  <el-form v-else label-position="left">
                    <el-form-item label="方案名称">
                      <span>{{ props.row.detail.name }}</span>
                    </el-form-item>
                    <el-form-item label="">
                      <div style="border:1px solid #F2F6FC;border-radius: 2px; padding: 20px" v-for="(item, index) in props.row.detail.catalogueList" :key="index">
                        <div>{{item.drugAlias}}</div>
                        <div>终浓度: {{item.finalConcentration}}<i> umol/L</i></div>
                        <el-row>
                          <el-col :span="8">
                          <div class="dosage-info-l" v-for="(y, l) in item.drugs" :key="l">
                          <div class="item"><i>用药方案:</i> {{y.drugPlan == 1?'化疗':'靶向'}}</div>
                          <div class="item"><i>药品名称:</i> {{y.drugId | getDrugName(y.drugPlan)}}</div>
                          <div class="item"><i>母药浓度:</i> {{y.motherLiquorConcentration}}<i>umol/L</i></div>
                        </div>
                          </el-col>
                          <el-col :span="12">
                            <div class="dosage-info-r" v-for="(p, pl) in item.dosages" :key="pl">
                              <span><i>配比浓度:</i> {{p.compoundingConcentration}}<i>umol/L</i></span>
                              <span><i>Cmax:</i> {{p.cmaxValue}}</span>
                              <span><i>配药比例:</i> {{p.dosageRate}}<i>逐级稀释</i></span>
                              <span><i>配药量:</i> {{p.dosageCount}}<i>每孔ul</i></span>
                              <span><i>是否加药:</i> {{p.addFlag?'是':'否'}}</span>
                              <span><i>加药量:</i> {{p.dosageCount}}<i>每孔ul</i></span>
                              <span><i>复孔数:</i> {{p.repeatHoles}}</span>
                            </div>
                          </el-col>
                        </el-row>
                      </div>
                    </el-form-item>
                  </el-form>
              </div>
            </template>
          </el-table-column>
            <el-table-column label="方案名称" prop="name">
                <template slot-scope="scope">
                    <span>{{scope.row.name}}</span>
                </template>
            </el-table-column>
            <el-table-column label="对照孔" prop="compareCount">
                <template slot-scope="scope">
                    <span>{{scope.row.compareCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="空白孔" prop="blankCount">
                <template slot-scope="scope">
                    <span>{{scope.row.blankCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="用药数量" prop="drugCount">
                <template slot-scope="scope">
                    <span>{{scope.row.drugCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="配药总孔数" prop="drugHoleCount">
                <template slot-scope="scope">
                    <span>{{scope.row.drugHoleCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="铺板总孔数" prop="plateHoleCount">
                <template slot-scope="scope">
                    <span>{{scope.row.plateHoleCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="实验中心id" prop="centerId">
                <template slot-scope="scope">
                    <span>{{scope.row.centerId}}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
                    <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                               @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div v-show="!listLoading" class="end pagination-container">
            <el-pagination
:current-page.sync="page.currentPage"
background
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"
                           :page-sizes="[10,20,30, 50]"
                           :page-size="page.pageSize"
                           layout="total, sizes, prev, pager, next, jumper"
:total="total">
            </el-pagination>
        </div>
    </basic-container>
</template>

<script>
import { getList, remove, getDetail, getDrugDict } from '@/api/exp/dosage'
let that
export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      index: 0,
      detailLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      drugList: Array(2)
    }
  },
  beforeCreate () {
    that = this
  },
  filters: {
    getDrugName (id, type) {
      let name = ''
      for (const item of that.drugList[type - 1]) {
        if (item.value === id) {
          name = item.label
          break
        } else {
          name = '--'
        }
      }
      return name
    }
  },
  created () {
    this.onLoad(this.page)
    getDrugDict(1).then(res => {
      this.drugList[0] = res.data
    })
    getDrugDict(2).then(res => {
      this.drugList[1] = res.data
    })
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    expandTable (row) {
      const { id } = row
      this.detailLoading = true
      getDetail({ id }).then((res) => {
        this.detailLoading = false
        row.detail = res.data
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.$router.push({
        path: '/exp/dosagedetails'
      })
    },
    handleUpdate (id) {
      this.$router.push({
        path: '/exp/dosagedetails?id=' + id
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove({ deleteType: 1, ids: row.id }).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    }
  }
}
</script>
<style lang="scss">
.pagination-container{
  margin-top: 20px;
}
.filter-item{
  margin-right: 10px;
}
.search-container{
  margin-bottom: 20px;
}
.dosage-info-l{
  padding-bottom: 10px;
  margin-top: 10px;
  .item{
    display: inline-block;
    min-width: 50%;
    font-weight: bold;
    i{
      margin-left: 5px;
      font-style: normal;
      color: #909399;
      display: inline-block;
      width: 80px;
      font-weight: 200;
    }
  }
}
.dosage-info-r{
 border-bottom: 1px dashed #E4E7ED;
  padding-bottom: 10px;
  margin-top: 10px;
  span{
    display: inline-block;
    min-width: 50%;
    color: #5B8DD7;
    font-weight: bold;
    i{
      margin-left: 5px;
      font-style: normal;
      color: #909399;
      display: inline-block;
      width: 80px;
      font-weight: 200;
    }
  }
}
</style>
