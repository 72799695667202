<template>
  <basic-container>
<!--    <el-steps :active="step" align-center>-->
<!--      <template v-for="(item, index) in formList">-->
<!--        <el-step :key="index" v-if="!item.ignore">-->
<!--          <div class="step-title" slot="title">{{item.name}}</div>-->
<!--        </el-step>-->
<!--      </template>-->
<!--    </el-steps>-->
    <div class="test-wrap">
    <step-line :active="realStep" :list="steps" @update="changeStep"></step-line>
      <div style="margin-left: 20px">
        <div v-if="realStep > 0">
          <div style="position: sticky; top: 0; right: 0; z-index: 1; background: #fff; padding: 20px; background: #f8f8f8; font-size: 12px">
            {{formList.content}}
          </div>
          <el-card class="box-card" v-for="(label,index) in formList.componentList" :key="index">
            <div slot="header" class="clearfix">
              <span>{{ label.optionName }}</span>
            </div>
            <div>
              <testProject
                  :key="label.id"
                  :keyType="label.id"
                  :ref="'testProject'"
                  :type="label.optionType"
                  v-model="label.content" >
                <div slot="tube">
                  <el-select @change="handleDosage(index,$event)" v-model="label.content.dosageId" placeholder="请选择用药方案">
                    <el-option
v-for="item in dosageList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"></el-option>
                  </el-select>

                  <div v-if="label.plate" style="margin-top: 20px">
                    <template>
                      <el-table
                          v-if="label.plate.dosageDrugList"
                          :data="label.plate.dosageDrugList"
                          border
                          style="width: 100%">
                        <el-table-column
                            prop="drugName"
                            label="药物名称">
                        </el-table-column>
                        <el-table-column
                            prop="holeName"
                            label="孔位">
                        </el-table-column>
                        <el-table-column
                            prop="cmaxValue"
                            label="Cmax">
                        </el-table-column>
                      </el-table>
                    </template>
                  </div>
                  <test-tube-plus
                      :data="{
                    count: label.plateHoleCount  || 0,
                    experimentId: experimentId,
                    hole: label.content.$specifications,
                    barcode: label.content.barcode,
                    optionType: label.optionType
                  }"
                      v-model="label.plate"></test-tube-plus>
                </div>
                <div slot="tubeDay">
                  <testTubeDayPlus
                      :data="{
experimentId: experimentId,
type: 5,
stepId: stepId
                  }"
                      v-model="label.plate"></testTubeDayPlus>
                </div>
                <div slot="drugTube">
                  <testTubeDosing
                      :data="{experimentId: experimentId, stepId: stepId}"
                      v-model="label.content.drugTube"></testTubeDosing>
                </div>

                <div slot="ATPTest">
                  <testTubeDosingAtp
                      :data="{experimentId: experimentId}"
                      v-model="label.content"></testTubeDosingAtp>
                </div>
              </testProject>
            </div>
          </el-card>
        </div>
        <div v-else style="margin-top: 20px">
          <step1 :exp-id="experimentId" ref="step1Valid" v-model="form"></step1>
        </div>
        <div class="button-group">
          <el-button @click="nextStep" type="primary" v-if="realStep + 1 !== steps.length">下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          <el-button @click="nextStep" icon="el-icon-check" type="primary" v-else>保存</el-button>
        </div>
      </div>

<!--    <el-dialog-->
<!--            :close-on-click-modal="false"-->
<!--            :close-on-press-escape="false"-->
<!--            :show-close="false"-->
<!--            :visible.sync="stepSwitch"-->
<!--            center-->
<!--            title="设置步骤"-->
<!--            width="20%">-->
<!--      <div>-->
<!--        <el-checkbox :indeterminate="isIndeterminate" @change="handleCheckAllChange" v-model="checkAll">全选</el-checkbox>-->
<!--        <div style="margin: 15px 0;"></div>-->
<!--        <el-checkbox-group @change="handleCheckedCitiesChange" v-model="checkedStep">-->
<!--          <el-checkbox :disabled="index===0" :key="index" :label="index" style="display: block" v-for="(s,index) in formList">{{index+1}}.{{s.name}}</el-checkbox>-->
<!--        </el-checkbox-group>-->
<!--      </div>-->
<!--      <span class="dialog-footer" slot="footer">-->
<!--        <el-button @click="handleStepInfo" type="primary">确 定</el-button>-->
<!--      </span>-->
<!--    </el-dialog>-->

    </div>
  </basic-container>
</template>

<script>
import { submitVr, getStepDetail, getDosageDict } from '@/api/exp/drugtest'
import step1 from '@/views/exp/drugtest/step1'
import stepLine from '@/components/step-form/step-line'
import { getStepByTemplate, getTestStepList } from '@/api/exp/primaryculture'

import testTubePlus from '@/components/form/test-tube-plus'
import testTubeDayPlus from '@/components/view-comp/test-tube-day-plus'

import testTubeDosingAtp from '@/components/form/test-tube-dosing-atp'
import testTubeDosing from '@/components/form/test-tube-dosing'
// import testProject from '@/components/awd-test-step/test-form'
export default {
  components: {
    testTubeDosing,
    testTubeDosingAtp,
    testTubePlus,
    testTubeDayPlus,
    step1,
    stepLine,
    testProject: () => import('@/components/awd-test-step/test-form')
  },
  data () {
    return {
      isFinal: false,
      checkAll: true,
      isIndeterminate: false,
      checkedStep: [],
      experiment: {},
      experimentId: '',
      templateType: '',
      step: 1,
      stepNo: 0,
      formList: [],
      form: {
        tubeList: []
      },
      stepSwitch: false,
      realStep: 0,
      steps: [],
      dosageList: [],
      plateHoleCount: 0,
      stepId: 0
    }
  },
  created () {
    const { templateType, experimentId, stepId } = this.$route.query
    this.stepId = stepId
    this.experimentId = experimentId
    this.templateType = templateType
    this.detail()
  },
  mounted () {
    this.detail()
  },
  methods: {

    handleStepInfo () {
      console.log(this.checkedStep)
      this.formList = this.formList.map((item) => {
        item.ignore = true
        return item
      })
      for (const index of this.checkedStep) {
        this.formList[index].ignore = false
      }
      this.stepSwitch = false
    },
    handleCheckAllChange (val) {
      this.checkedStep = val
        ? this.formList.map((item, index) => {
          return index
        })
        : [0]
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      const checkedStep = value.length
      this.checkAll = checkedStep === this.formList.length
      this.isIndeterminate = checkedStep > 0 && checkedStep < this.formList.length
    },
    detail () {
      // 若是第一步
      if (!this.stepId) {
        getStepByTemplate(this.templateType).then(res => {
          if (res.success) {
            this.steps = res.data
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
            this.$router.push({
              path: '/exp/drugtest/index'
            })
          }
        })
      } else {
        getTestStepList(this.experimentId).then(res => {
          this.steps = res.data
          for (let i = 0; i < this.steps.length; i++) {
            if (this.stepId === this.steps[i].id) {
              this.realStep = i
            }
          }
        })
        getStepDetail(this.stepId).then(async (res) => {
          if (this.realStep === 0) {
            this.form = res.data.componentList[0].content
            this.form.tubeList = res.data.componentList[0].tubeList
          } else {
            this.formList = res.data
            const res1 = await getDosageDict()
            this.dosageList = res1.data
            for (const i of this.formList.componentList) {
              if (i.content.dosageId) {
                const item = this.dosageList.find(res => res.id === i.content.dosageId)
                let cont = 0
                if (i.optionType === 9) {
                  cont = item.plateHoleCount
                } else if (i.optionType === 24) {
                  cont = item.drugHoleCount
                }
                i.plateHoleCount = cont
                i.dosageDrugList = item.dosageDrugList
              }
            }
          }
        })
      }
    },
    handleDosage (index, e) {
      const item = this.dosageList.find(res => res.id === e)
      this.formList.componentList[index].plate.holeList.map((i) => {
        i.checkFlag = false
        return i
      })
      this.formList.componentList[index].plate.dosageDrugList = []
      let cont = 0
      if (this.formList.componentList[index].optionType === 9) {
        cont = item.plateHoleCount
      } else if (this.formList.componentList[index].optionType === 24) {
        cont = item.drugHoleCount
      }
      this.formList.componentList[index].plateHoleCount = cont
    },
    changeStep (e) {
      this.realStep = e
      this.stepId = this.steps[e].id
      this.$router.push({
        path: '/exp/drugtest/step',
        query: { experimentId: this.experimentId, step: e, stepId: this.steps[e].id }
      })
      this.detail()
    },
    nextStep () {
      if (this.realStep < 1) {
        const data = {
          componentList: [
            {
              content: this.form,
              tubeList: this.form.tubeList
            }
          ]
        }
        delete data.componentList[0].content.tubeList
        if (this.experimentId) {
          data.experimentId = this.experimentId
          data.id = this.stepId
        } else {
          data.templateId = this.templateType
        }
        const testForm = this.$refs.step1Valid
        testForm.$refs.form.validate((valid, msg) => {
          if (!valid) {
            this.$message.error(Object.values(msg)[0][0].message)
          } else {
            submitVr(data).then(res => {
              if (res.success) {
                this.$router.push({
                  path: '/exp/drugtest/step',
                  query: { experimentId: res.data[1].experimentId, stepId: res.data[1].id }
                })
                this.stepId = res.data[1].id
                this.experimentId = res.data[1].experimentId
                this.detail()
                // this.experiment = res.data
                // this.formList = this.experiment.content
                // this.forwardStep()
                // this.checkoutTube([11, 9])
                // this.initTubeDay([11, 9], [15, 29])
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      } else {
        this.$refs.testProject[0].refreshForm()
        this.validate().then((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '正在保存...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.3)'
            })
            submitVr(this.formList).then(res => {
              if (res.success) {
                if (this.realStep !== res.data.length - 1) {
                  const index = this.realStep + 1
                  this.$router.push({
                    path: '/exp/drugtest/step',
                    query: { experimentId: res.data[index].experimentId, stepId: res.data[index].id }
                  })
                  this.stepId = res.data[index].id
                  this.experimentId = res.data[index].experimentId
                  this.detail()
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
              loading.close()
            })
          }
        })
      }
    },
    validate () {
      const that = this
      return new Promise((resolve) => {
        const testForm = this.$refs.testProject
        getValid(0)
        function getValid (i) {
          testForm[i].$refs.form.validate((valid, done, msg) => {
            console.log(valid, done, msg)
            done()
            if (valid) {
              if (i + 1 >= testForm.length) {
                resolve(valid)
              } else {
                getValid(i + 1)
              }
            } else {
              that.$message.error(Object.values(msg)[0][0].message)
              resolve(valid)
            }
          })
        }
      })
    }
  }

}
</script>
<style lang="scss">
.step-title{
  white-space: nowrap;
  text-overflow: ellipsis
}
.button-group{
  text-align: center;
  margin-top: 20px;
}
.test-wrap{
  display: flex;
  align-items: flex-start;
}
</style>
