import testProject from '@/components/awd-test-step/test-form'
import goodSteps from '@/components/view-comp/good-steps'
export default {
  components: {
    testProject,
    goodSteps
  },
  data () {
    return {
      isFinal: false,
      step: 1,
      formList: []
    }
  },
  methods: {
    changeStep (e) {
      this.step = e + 1
      this.stepIsFinal()
    },
    backStep () {
      if (this.formList[this.step - 2].ignore) {
        this.step--
        this.backStep()
      } else {
        this.step--
      }
    },
    prevStep () {
      this.backStep()
      this.isFinal = false
    },
    stepIsFinal () {
      // console.log('this.step ===', this.step)
      // console.log('this.formList.length ===', this.formList.length)
      let max = 0
      for (let i = 0; i < this.formList.length; i++) {
        if (!this.formList[i].ignore) {
          max = i
        }
      }
      // console.log('max ===', max)
      let s = true
      if (this.step === (max + 1)) {
        s = true
      } else {
        s = false
      }
      // let s = true
      // if (this.step === this.formList.length) {
      //   s = true
      // } else {
      //   for (let i = this.step - 1; i < this.formList.length; i++) {
      //     if (!this.formList[i].ignore) {
      //       s = false
      //       break
      //     } else {
      //       s = true
      //     }
      //   }
      // }
      this.isFinal = s
    },
    checkoutTube (tubeIds) {
      const tubeData = this.checkOutTubeByType(tubeIds)
      function checkStep (id) {
        const l = [15, 16, 19, 20]
        let flag = false
        for (const i of l) {
          if (id === i) {
            flag = true
            break
          }
        }
        return flag
      }
      for (let i = 0; i < this.formList.length; i++) {
        const item1 = this.formList[i]
        if (checkStep(item1.id)) {
          for (const item2 of item1.forms) {
            if (item2.form.tubeDay !== undefined) {
              if (!item2.form.tubeDay.length) {
                item2.form.tubeDay = [
                  {
                    day: 0,
                    hole: tubeData.form.tube.length,
                    step: item1.id,
                    data: []
                  }
                ]
                for (const s in tubeData.form.tube) {
                  item2.form.tubeDay[0].data.push({
                    file: [],
                    fileFlag: false,
                    disabled: Math.abs(tubeData.form.tube[s]) !== 1
                  })
                }
              } else {
                item2.form.tubeDay[0].hole = tubeData.form.tube.length
                item2.form.tubeDay[0].step = item1.id
                for (const i in item2.form.tubeDay) {
                  const day = item2.form.tubeDay[i]
                  if (!day.data.length) {
                    for (const s in tubeData.form.tube) {
                      item2.form.tubeDay[i].data.push({
                        file: [],
                        fileFlag: false,
                        disabled: Math.abs(tubeData.form.tube[s]) !== 1
                      })
                    }
                  } else {
                    const arr = []
                    for (const s in tubeData.form.tube) {
                      if (!day.data[s]) {
                        day.data[s] = {
                          file: [],
                          fileFlag: false,
                          disabled: Math.abs(tubeData.form.tube[s]) !== 1
                        }
                      } else {
                        day.data[s].disabled = Math.abs(tubeData.form.tube[s]) !== 1
                      }
                      arr.push(day.data[s])
                    }
                    day.data = arr
                  }
                }
              }
              // for (const s in tubeData.form.tube) {
              //   for (const m of item2.form.tubeDay) {
              //     m.data[s].disabled = tubeData.form.tube[s] === 1
              //   }
              // }
            }
            if (item2.form.drugTube !== undefined) {
              if (item2.form.drugTube.length > 0) {
                for (let s = 0; s < tubeData.form.tube.length; s++) {
                  if (tubeData.form.tube[s] !== 1) {
                    if (item2.form.drugTube[s]) {
                      item2.form.drugTube[s].disabled = true
                    }
                  }
                }
              }
              item2.form.$specifications = tubeData.form.tube.length
            }

            if (item2.form.tube !== undefined) {
              if (i !== 1 && i !== tubeIds[0]) {
                item2.form.$specifications = tubeData.form.tube.length
              }
            }
          }
        }
      }
    },
    initTubeDay (subject, target) {
      let value = null
      const dayFormIndex = target[0]
      const index = target[1]
      for (const item of this.formList[dayFormIndex].forms) {
        if (parseInt(item.value) === index) {
          value = item.form.tubeDay
        }
      }

      const tubeData = this.checkOutTubeByType(subject)
      const arr = [{
        day: 0, data: []
      }]
      console.log('--->', tubeData, subject, value)
      if (!value || !value.length || !value[0]?.data?.length) {
        for (const item of tubeData.form.tube) {
          if (item.checked) {
            arr[0].data.push({ img: '' })
          } else {
            arr[0].data.push(null)
          }
        }
        this.formList[dayFormIndex].forms = this.formList[dayFormIndex].forms.map((i) => {
          if (parseInt(i.value) === index) {
            i.form.tubeDay = arr
          }
          return i
        })
      } else {
        this.formList[dayFormIndex].forms = this.formList[dayFormIndex].forms.map((i) => {
          if (parseInt(i.value) === index) {
            if (i.form.tubeDay.length) {
              for (const j in i.form.tubeDay) {
                const _arr = new Array(tubeData.form.tube.length)
                for (const k in tubeData.form.tube) {
                  if (tubeData.form.tube[k]) {
                    _arr[k] = i.form.tubeDay[j].data[k] || { img: '' }
                  } else {
                    _arr[k] = null
                  }
                }
                i.form.tubeDay[j].data = _arr
              }
            } else {
              i.form.tubeDay = arr
            }
          }
          return i
        })
      }
    },
    checkOutTubeByType (subject) {
      const index1 = subject[0]
      const index2 = subject[1]
      let a = null
      for (const item of this.formList[index1].forms) {
        if (parseInt(item.value) === index2) {
          a = item
        }
      }
      return a
    },
    forwardStep () {
      if (this.formList[this.step].ignore) {
        this.step++
        this.forwardStep()
      } else {
        this.step++
      }
      this.stepIsFinal()
    }
  }

}
