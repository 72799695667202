<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item label="鉴定编号">
          <el-input
                  @keyup.enter.native="handleFilter"
                  class="filter-item"
                  placeholder="鉴定编号"
                  v-model="page.identifyCode"></el-input>
        </el-form-item>
        <el-form-item label="样本编号">
          <el-input
                  @keyup.enter.native="handleFilter"
                  class="filter-item"
                  placeholder="样本编号"
                  v-model="page.sampleCode"></el-input>
        </el-form-item>
        <el-form-item label="鉴定对象">
          <el-input
                  @keyup.enter.native="handleFilter"
                  class="filter-item"
                  placeholder="鉴定对象"
                  v-model="page.identifyObjects"></el-input>
        </el-form-item>
        <el-form-item label="鉴定类型">
          <el-select placeholder="请选择" style="width: 180px" v-model="page.identifyType">
            <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-for="item in identifyTypeOptions">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="送样人员">
          <el-input
                  @keyup.enter.native="handleFilter"
                  class="filter-item"
                  placeholder="送样人员"
                  v-model="page.sender"></el-input>
        </el-form-item>
        <el-form-item label="送样时间">
          <el-date-picker
                  end-placeholder="结束日期"
                  range-separator="至"
                  start-placeholder="开始日期"
                  type="datetimerange"
                  v-model="page.querySendTime"
                  value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="反馈时间">
          <el-date-picker
                  end-placeholder="结束日期"
                  range-separator="至"
                  start-placeholder="开始日期"
                  type="datetimerange"
                  v-model="page.queryFeedBackTime"
                  value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="是否成功">
          <el-select placeholder="请选择" style="width: 180px" v-model="page.isSuccess">
            <el-option
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    v-for="item in isSuccessOptions">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="handleFilter" class="filter-item search" icon="el-icon-search" type="primary">搜索</el-button>
          <el-button @click="reset" class="filter-item search" icon="el-icon-search" type="primary">重置</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>    <el-row class="padding-row">
      <el-button @click="handleCreate" icon="el-icon-plus" type="primary">新增</el-button>
    <el-button
                :disabled="this.ids.length === 0"
                @click="batchDelete(ids)"
                icon="el-icon-delete"
                type="danger"
    >
      删除
    </el-button>

  </el-row>
    <el-table
        class="good-table"
        :data="list"
        @selection-change="handleSelectionChange"
        v-loading.body="listLoading"
        element-loading-text="拼命加载中"
        border
        fit
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'text-align':'center'}"
        highlight-current-row>
      <el-table-column
              :reserve-selection="true"
              label="全选"
              type = 'selection'
              width="55">
      </el-table-column>
      <el-table-column label="鉴定编号" prop="identifyCode" width="120px">
        <template slot-scope="scope">
          <span>{{scope.row.identifyCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="样本编号" prop="sampleCode" width="120px">
        <template slot-scope="scope">
          <span>{{scope.row.sampleCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="鉴定对象" prop="identifyObjects">
        <template slot-scope="scope">
          <span>{{scope.row.identifyObjects}}</span>
        </template>
      </el-table-column>
      <el-table-column label="代次" prop="generation">
        <template slot-scope="scope">
          <span>{{scope.row.identifyGeneration}}</span>
        </template>
      </el-table-column>
      <el-table-column label="鉴定类型" prop="freezeOperator">
        <template slot-scope="scope">
          <span>{{ typeFormatter1(scope.row.identifyType) }}</span>
        </template>
      </el-table-column>

      <el-table-column :show-overflow-tooltip="true" label="送样人员" prop="sender">
        <template slot-scope="scope">
          <span>{{scope.row.sender}}</span>
        </template>
      </el-table-column>
      <el-table-column label="送样时间" prop="sendTime" width="140px">
        <template slot-scope="scope">
          <span>{{scope.row.sendTime}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="送样前4X镜状态图片" prop="pic" width="140px">-->
<!--        <template slot-scope="scope">-->
<!--          <el-image-->
<!--                  :preview-src-list="scope.row.picList"-->
<!--                  :src="scope.row.picList[0]"-->
<!--                  style="width: 100px; height: 100px"-->
<!--                  v-if="scope.row.pic">-->
<!--          </el-image>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column :show-overflow-tooltip="true" label="组化marker" prop="marker" width="90px">
        <template slot-scope="scope">
          <span>{{scope.row.marker}}</span>
        </template>
      </el-table-column>
      <el-table-column label="反馈时间" prop="feedBackTime" width="140px">
        <template slot-scope="scope">
          <span>{{scope.row.feedBackTime}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="反馈文档" prop="doc" width="200px">-->
<!--        <template slot-scope="scope">-->
<!--          <div :key="index" v-for="(item, index) in scope.row.docVoList">-->
<!--            <el-link :href=" imgBaseUrl + item" type="primary" v-if="item">-->
<!--              {{item.split('/')[1]}}-->
<!--            </el-link>-->
<!--          </div>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="是否成功" prop="isSuccess">
        <template slot-scope="scope">
          <span>{{ typeFormatter(scope.row.isSuccess) }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="handleUpdate(scope.row.id)" icon="el-icon-edit">编辑</el-button>
          <el-button
            v-if="scope.row.status!='deleted'"
            size="small"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
        :current-page.sync="page.currentPage"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10,20,30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </basic-container>
</template>

<script>
import { getList, deleteOrgan, remove } from '@/api/exp/organidentify'
import { fileSaveUrl } from '@/config/index'

export default {
  data () {
    return {
      total: null,
      ids: [],
      list: null,
      listLoading: true,
      imgBaseUrl: fileSaveUrl,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        identifyCode: '',
        sampleCode: '',
        identifyObjects: '',
        identifyType: '',
        sender: '',
        querySendTime: '',
        queryFeedBackTime: '',
        isSuccess: ''
      },
      identifyTypeOptions: [{
        label: '基因测序',
        value: '0'
      },
      {
        label: '组化鉴定',
        value: '1'
      }],
      isSuccessOptions: [{
        label: '否',
        value: '0'
      },
      {
        label: '是',
        value: '1'
      }]
    }
  },
  created () {
    // this.onLoad(this.page)
    this.onLoad()
  },
  methods: {
    handleSelectionChange (val) {
      const checked = val.map(item => {
        return item.id
      })
      this.ids = checked
    },
    onLoad () {
      getList(this.page.currentPage, this.page.pageSize, this.page).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
        for (let i = 0; i < this.list.length; i++) {
          // 送样前4X镜图片有多张
          if (this.list[i].pic) {
            const picListTemp = this.list[i].pic.split(',')
            const t1 = picListTemp.map(item => {
              return this.imgBaseUrl + item
            })
            this.list[i].picList = t1
          }
        }
      })
    },
    handleChangePolicyType (e) {
    },
    handleChangeArea (e) {
      let selectedAreaName = {}
      selectedAreaName = this.areaOptions.find((item) => { // 这里的chargingWorkNameList就是上面遍历的数据源
        return item.dwdmValue === e
        // 筛选出匹配数据，是对应数据的整个对象
      })
      this.form.areaName = selectedAreaName.label
    },
    // 上传成功
    uploadFileSuccess (response, file, fileList) {
      console.log(response)
      if (response.success) {
        this.fileList.push(file)
        this.form.policyUrl = response.data
      } else {
        this.$message.error(response.msg)// 文件上传错误提示
        this.fileList = []
      }
    },
    beforeUpload (file) {
      console.log(file.type, '222')
      if (file.type !== 'doc' && file.type !== 'docx' && file.type === 'pdf') {
        this.fileList = []
        this.$notify({
          title: '上传失败',
          message: '文件格式必须是pdf/word',
          type: 'error',
          duration: 2000
        })
        return false
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleFile (e) {
    },
    getArea () {
    },
    reset () {
      this.page.pageSize = 10
      this.page.currentPage = 1
      this.page.total = 0
      this.page.identifyCode = ''
      this.page.sampleCode = ''
      this.page.identifyObjects = ''
      this.page.identifyType = ''
      this.page.sender = ''
      this.page.querySendTime = ''
      this.page.queryFeedBackTime = ''
      this.page.isSuccess = ''
      this.handleFilter()
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.$router.push({
        path: '/exp/organ/step',
        query: { templateType: 4 }
      })
    },
    handleUpdate (id) {
      this.$router.push({
        path: '/exp/organ/step',
        query: { experimentId: id, templateType: 4 }
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    batchDelete (ids) {
      remove(ids.join(',')).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.ids = []
          this.handleFilter()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    handleDelete (row) {
      this.$confirm('是否删除该实验?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteOrgan(row.id).then(res => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.handleFilter()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保
    },
    typeFormatter: function (status) {
      switch (status) {
        case '0':
          return '否'
        case '1':
          return '是'
      }
    },
    typeFormatter1: function (status) {
      switch (status) {
        case '0':
          return '基因测序'
        case '1':
          return '组化鉴定'
      }
    }
  }

}
</script>

<style>
.padding-row {
  margin-bottom: 15px;
}
</style>
