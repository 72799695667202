<template>
  <div>
    <avue-form ref="form" :option="option" v-model="modelValue">
      <template slot="barcode">
          <el-input @blur="getDetail" v-model="modelValue.barcode"></el-input>
      </template>
    </avue-form>
  </div>
</template>

<script>
import { getFullDetail, getSampleNo } from '@/api/market/sample'
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: [Array, Object]
    },
    expId: {
      type: String
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    modelValue: {
      get () {
        return this.value || {
          barcode: ''
        }
      },
      set (value) {
        this.$emit('input', value)
        if (this.expId) {
          this.option.column[0].disabled = true
          if (value.isTemporary === 1) {
            this.option.column[3].display = false
            this.option.column[4].display = false
            this.option.column[5].display = false
            this.option.column[6].display = false
            this.option.column[7].label = '临时样本编号'
          }
        } else {
          this.option.column[0].disabled = false
        }
      }
    }
  },
  data () {
    return {
      expIdTemp: this.expId,
      option: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: '是否为临时样本',
            prop: 'isTemporary',
            labelWidth: 120,
            type: 'radio',
            row: true,
            value: 0,
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }]
          },
          {
            label: '取样管条码',
            prop: 'barcode',
            rules: [{
              required: true,
              message: '请输入取样管条码'
            }],
            row: true,
            labelWidth: 110
          },
          {
            label: '样本来源',
            prop: 'source',
            labelWidth: 110,
            type: 'select',
            rules: [{
              required: true,
              message: '请选择样本来源'
            }],
            row: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/source_type'
          },
          {
            label: '医院',
            prop: 'hospitalId',
            labelWidth: 110,
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/office/dict`
          },
          {
            label: '医生',
            prop: 'doctorId',
            labelWidth: 110,
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/doctor/dict`
          },
          {
            label: '患者',
            prop: 'patientId',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/patient/dict?doctorId={{key}}`
          },
          {
            label: '样本编号',
            row: true,
            prop: 'sampleCode',
            labelWidth: 110,
            rules: [{
              required: true,
              message: '请输入样本编号'
            }]
          },
          {
            label: '操作人',
            prop: 'operatorName',
            labelWidth: 110
          },
          {
            label: '操作时间',
            prop: 'operateTime',
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: true,
              message: '请选择操作时间'
            }]
          },
          {
            label: '备注',
            labelWidth: 110,
            type: 'textarea',
            prop: 'remarks'
          }
        ]
      }
    }
  },
  created () {
    this.modelValue.operatorName = this.userInfo.name
    this.modelValue.operatorId = this.userInfo.id
  },
  watch: {
    'modelValue.isTemporary' (val) {
      const hospital = this.option.column[3]
      const office = this.option.column[4]
      const doctor = this.option.column[5]
      const patient = this.option.column[6]
      if (val === 1) {
        this.expIdTemp = undefined
        this.option.column[7].label = '临时样本编号'
        hospital.display = false
        office.display = false
        doctor.display = false
        patient.display = false
        getSampleNo().then(res => {
          if (res.success) {
            const data = res.data
            this.modelValue.barcode = data.barcode
            this.modelValue.sampleCode = data.sampleCode
            this.modelValue.source = ''
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      } else {
        if (!this.expIdTemp) {
          this.modelValue.barcode = ''
          this.modelValue.sampleCode = ''
          this.modelValue.source = ''
          this.modelValue.officeId = ''
          this.modelValue.hospitalId = ''
          this.modelValue.doctorId = ''
          this.modelValue.patientId = ''
        }
        hospital.display = true
        office.display = true
        doctor.display = true
        patient.display = true
        this.option.column[7].label = '样本编号'
      }
    }
  },
  methods: {
    change (e) {
      this.$forceUpdate()
    },
    getDetail () {
      getFullDetail(this.modelValue.barcode).then((res) => {
        if (res.success) {
          this.modelValue.sampleId = res.data.id
          this.$emit('input', { ...this.modelValue, ...res.data })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }

}
</script>
