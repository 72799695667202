import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/dosage/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}
export const getDetail = (data) => {
  return request({
    url: '/exp/dosage/detail',
    method: 'get',
    params: data
  })
}
export const getDrugDict = (type) => {
  return request({
    url: '/exp/drug/dict',
    method: 'get',
    params: {
      drugPlan: type
    }
  })
}
export const saveDosage = (data) => {
  return request({
    url: '/exp/dosage/submit',
    method: 'post',
    data
  })
}
export const remove = (data) => {
  return request({
    url: '/exp/dosage/remove/' + data.deleteType,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'post',
    params: {
      ids: data.ids
    }
  })
}
