<template>
    <basic-container>
      <el-form ref="form" :model="form" label-width="80px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="方案名称">
              <el-row>
                <el-col :span="12">
                  <el-input v-model="dosageData.name"></el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="对照孔">
              <el-row>
                <el-col :span="12">
                  <el-input v-model.number="dosageData.compareCount">
                    <template slot="append">孔</template>
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="空白孔">
              <el-row>
                <el-col :span="12">
                  <el-input v-model.number="dosageData.blankCount">
                    <template slot="append">孔</template>
                  </el-input>
                </el-col>
              </el-row>
            </el-form-item>
            <el-form-item label="用药数量">
              <el-row>
                <el-col :span="12">
                  <el-input disabled :value="dosageData.drugCount">
                    <template slot="append" disabled="">种</template>
                  </el-input>
                </el-col>
                <el-col :span="8" style="margin-left: 20px">
                  <el-button @click="addDose" type="primary" icon="el-icon-plus" round>增加药物</el-button>
                </el-col>
              </el-row>
            </el-form-item>
          </el-col>
          <el-col :span="8"></el-col>
          <el-col :span="8">
            <div style="margin-top: 110px; line-height: 40px">
            <div>配药总孔数: {{dosaHoles}}</div>
            <div>铺板总孔数: {{padHoles}}</div>
            </div>
          </el-col>
        </el-row>
        <div class="dosage-item">
          <div class="dosage-title">
            <el-row>
              <el-col :span="7">药品</el-col>
              <el-col :span="4">用药方案</el-col>
              <el-col :span="4">药品名称</el-col>
              <el-col :span="4">母药浓度<span class="little-t">(umol/L)</span></el-col>
              <el-col :span="4">终浓度<span class="little-t">(umol/L)</span></el-col>
              <el-col :span="1"></el-col>
            </el-row>
          </div>
          <div class="dosage-title">
            <el-row>
              <el-col :span="3">配比浓度<span class="little-t">(umol/L)</span></el-col>
              <el-col :span="3">Cmax</el-col>
              <el-col :span="4">配药比例<span class="little-t">(逐级稀释)</span></el-col>
              <el-col :span="4">配药量<span class="little-t">(每孔ul)</span></el-col>
              <el-col :span="2">加药</el-col>
              <el-col :span="3">复孔数</el-col>
              <el-col :span="4">加药量<span class="little-t">(每孔ul)</span></el-col>

              <el-col :span="1"></el-col>
            </el-row>
          </div>
        </div>
        <div class="dosage-item" v-for="(item, index) in dosageData.catalogueList" :key="index">
          <div class="dosage-li">
            <el-row :gutter="5">
              <el-col :span="7">
                <span style="margin-right: 5px">{{ item.drugAlias }}</span>
                <el-button v-if="index > 0" @click="delDrugest(index)" type="danger" circle size="mini" icon="el-icon-delete" plain></el-button>
              </el-col>
              <el-col :span="13">
                <div v-for="(y,l) in item.drugs" :key="l">
                  <el-col :span="8">
                  <el-select size="" v-model="y.drugPlan" placeholder="请选择">
                    <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                  <el-col :span="8">
                  <el-select size="" v-model="y.drugId" placeholder="请选择">
                    <el-option
                        v-for="item in drugList[y.drugPlan - 1]"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-col>
                  <el-col :span="7"><el-input placeholder="母药浓度" v-model.number="y.motherLiquorConcentration"></el-input></el-col>
                  <el-col :span="1">
                    <i @click="delDrug(index, l)" v-if="item.drugs.length > 1" class="el-icon-close del"></i>
                  </el-col>
                </div>
                <el-button @click="addDrug(item.drugs)" class="add-btn" icon="el-icon-plus" round>添加药物</el-button>
              </el-col>
              <el-col :span="4"><el-input placeholder="终浓度" v-model.number="item.finalConcentration"></el-input></el-col>

            </el-row>

            </div>
            <div class="dosage-li">
            <el-row :gutter="5" v-for="(p, pl) in item.dosages" :key="pl">
              <el-col :span="3"><el-input placeholder="配比浓度" v-model.number="p.compoundingConcentration"></el-input></el-col>
              <el-col :span="3"><el-input placeholder="Cmax" v-model="p.cmaxValue"></el-input></el-col>
              <el-col :span="4"><el-input placeholder="配药比例" v-model="p.dosageRate">
                <template slot="prepend">1:</template>
                </el-input>
              </el-col>
              <el-col :span="4"><el-input-number :controls="false" controls-position="right" placeholder="配药量" :min="50" :max="200" v-model.number="p.dosageCount"></el-input-number></el-col>
              <el-col :span="2"><el-checkbox @change="changeDos(item.dosages, pl, $event,p)" v-model="p.addFlag"></el-checkbox></el-col>
              <el-col :span="3"><el-input @input="changeNumber($event, p, 'repeatHoles',384 - dosageData.compareCount - dosageData.blankCount)" v-if="p.addFlag" controls-position="right" :disabled="!p.addFlag" placeholder="复孔数" :min="1" :max="384 - dosageData.compareCount - dosageData.blankCount" v-model.number="p.repeatHoles"></el-input></el-col>
              <el-col :span="4"><el-input @input="changeNumber($event, p, 'addCount',parseInt(p.dosageCount/p.repeatHoles))" v-if="p.addFlag" :disabled="!p.addFlag" controls-position="right" placeholder="加药量" :max="parseInt(p.dosageCount/p.repeatHoles)" v-model.number="p.addCount"></el-input></el-col>
              <el-col :span="1">
                <i @click="delPb(index, pl)" v-if="item.dosages.length > 1" class="el-icon-close del"></i>
              </el-col>
            </el-row>

              <el-button @click="addPb(item.dosages)" class="add-btn" icon="el-icon-plus" round>添加配比</el-button>
          </div>
        </div>
        <el-empty v-if="!dosageData.catalogueList.length" description="暂无方案，请先增加药物"></el-empty>
      </el-form>
      <div class="bottom-btn">
        <el-button type="primary" @click="onSubmit">保存</el-button>
      </div>
      <el-dialog title="添加药品" :visible.sync="addFormVisible">
        <el-form :model="activeform" label-width="80px">
          <el-row>
            <el-col :span="10">
          <el-form-item label="药品">
            <el-input v-model="activeform.drugAlias" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="用药方案">
            <el-select v-model="activeform.drugs[0].drugPlan" placeholder="请选择用药方案">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="药品名称">

            <el-select v-model="activeform.drugs[0].drugId" placeholder="请选择用药方案">
            <el-option
                v-for="item in drugList[activeform.drugs[0].drugPlan - 1]"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="母药浓度">
            <el-input v-model="activeform.drugs[0].motherLiquorConcentration" autocomplete="off">
              <template slot="append">umol/L</template>
            </el-input>
          </el-form-item>
          <el-form-item label="终浓度">
            <el-input v-model="activeform.finalConcentration" autocomplete="off">
              <template slot="append">umol/L</template>
            </el-input>
          </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addList">确 定</el-button>
        </div>
      </el-dialog>
    </basic-container>
</template>

<script>
import { getDetail, getDrugDict, saveDosage, remove } from '@/api/exp/dosage'

export default {
  data () {
    return {
      activeform: {
        drugAlias: '',
        drugs: [{
          motherLiquorConcentration: '',
          drugPlan: ''
        }],
        finalConcentration: '',
        dosages: []
      },
      dosageData: {
        catalogueList: []
      },
      form: {},
      options: [{
        value: '1',
        label: '化疗'
      }, {
        value: '2',
        label: '靶向'
      }],
      drugList: Array(2),
      addFormVisible: false
    }
  },
  computed: {
    dosaHoles () {
      let num = 0
      for (const i in this.dosageData.catalogueList) {
        // eslint-disable-next-line no-unused-vars
        for (const j in this.dosageData.catalogueList[i].dosages) {
          num++
        }
      }
      return num
    },
    padHoles () {
      let num = 0
      for (const i in this.dosageData.catalogueList) {
        for (const j in this.dosageData.catalogueList[i].dosages) {
          if (this.dosageData.catalogueList[i].dosages[j].repeatHoles && this.dosageData.catalogueList[i].dosages[j].addFlag) {
            num += this.dosageData.catalogueList[i].dosages[j].repeatHoles
          }
        }
      }
      num += this.dosageData.compareCount
      num += this.dosageData.blankCount
      if (num > 384) {
        this.$message({
          type: 'warning',
          message: '铺板总孔数不能超过 96 !'
        })
      }
      return num
    }
  },
  created () {
    if (this.$route.query.id) {
      getDetail({ id: this.$route.query.id }).then(res => {
        console.log(res)
        this.dosageData = res.data
      })
    }

    getDrugDict(1).then(res => {
      this.drugList[0] = res.data
    })
    getDrugDict(2).then(res => {
      this.drugList[1] = res.data
    })
  },
  methods: {
    changeNumber (e, item, key, max) {
      e = parseInt(e)
      if (e > max) {
        item[key] = max
      } else {
        item[key] = e
      }
      if (key === 'repeatHoles') {
        if (item.repeatHoles * item.addCount > item.dosageCount) {
          item.repeatHoles = 0
          this.$message.error('复孔数 * 加药量不能超过配药量！')
        }
      }
    },
    onSubmit () {
      if (this.dosageData.catalogueList.length) {
        this.dosageData.drugHoleCount = this.dosaHoles
        this.dosageData.plateHoleCount = this.padHoles
        for (const item of this.dosageData.catalogueList) {
          for (const j of item.drugs) {
            if (!j.drugId) {
              this.$message.error('请选择药品')
              return
            }
            if (!j.motherLiquorConcentration) {
              this.$message.error('请填写母药浓度')
              return
            }
          }
          for (const j of item.dosages) {
            if (!j.compoundingConcentration) {
              this.$message.error('请填写配比浓度')
              return
            }
            if (!j.cmaxValue) {
              this.$message.error('请填写Cmax')
              return
            }
            if (!j.dosageRate) {
              this.$message.error('请填写配药比例')
              return
            }
          }
        }
        saveDosage(this.dosageData).then((res) => {
          this.dosageData = res.data
          this.$message({
            type: 'success',
            message: '保存成功!'
          })
        })
      } else {
        this.$message({
          type: 'warning',
          message: '请先增加药物!'
        })
      }
    },
    changeDos (items, index, e, item) {
      items[index].addFlag = e
    },
    addList () {
      this.dosageData.catalogueList.push(this.activeform)
      this.dosageData.drugCount = this.dosageData.catalogueList.length
      this.addFormVisible = false
    },
    addDose () {
      this.addFormVisible = true
      this.activeform = {
        drugAlias: '药品' + (this.dosageData.catalogueList.length + 1),
        drugs: [{
          motherLiquorConcentration: '',
          drugPlan: ''
        }],
        finalConcentration: '',
        dosages: []
      }
    },
    addDrug (item) {
      if (item.length < 8) {
        item.push({
          name: ''
        })
      } else {
        this.$message({
          type: 'warning',
          message: '最多可配 8 种药!'
        })
      }
    },
    addPb (item) {
      if (item.length < 6) {
        item.push({
          name: '',
          addFlag: false,
          dosageCount: 0,
          addCount: 0,
          repeatHoles: 0
        })
      } else {
        this.$message({
          type: 'warning',
          message: '最多可配 6 种比例!'
        })
      }
    },
    delDrug (index, cindex) {
      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.dosageData.catalogueList[index].drugs[cindex].id) {
          remove({ deleteType: 3, ids: this.dosageData.catalogueList[index].drugs[cindex].id }).then(res => {
            if (res.success) {
              this.dosageData.catalogueList[index].drugs.splice(cindex, 1)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$notify({
                title: '删除失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        } else {
          this.dosageData.catalogueList[index].drugs.splice(cindex, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    delDrugest (index) {
      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.dosageData.catalogueList[index].id) {
          remove({ deleteType: 2, ids: this.dosageData.catalogueList[index].id }).then(res => {
            if (res.success) {
              this.dosageData.catalogueList.splice(index, 1)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$notify({
                title: '删除失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        } else {
          this.dosageData.catalogueList.splice(index, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    },
    delPb (index, cindex) {
      this.$confirm('此操作将删除该条数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.dosageData.catalogueList[index].dosages[cindex].id) {
          remove({ deleteType: 4, ids: this.dosageData.catalogueList[index].dosages[cindex].id }).then(res => {
            if (res.success) {
              this.dosageData.catalogueList[index].dosages.splice(cindex, 1)
              this.$message({
                type: 'success',
                message: '删除成功!'
              })
            } else {
              this.$notify({
                title: '删除失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        } else {
          this.dosageData.catalogueList[index].dosages.splice(cindex, 1)
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
        }
      })
    }
  }
}
</script>
<style lang="scss">
.dosage-item{
  display: flex;
  justify-content: space-between;
}
.dosage-title{
  background: #F2F6FC;
  text-align: center;
  padding: 10px 0;
  color: #606266;
  margin-bottom: 10px;
  width: calc(50% - 1px);
  .little-t{
    font-size: 12px;
    display: block;
  }
}
.el-input-number--small{
  width: 80px;
  .is-controls-right .el-input__inner{
    padding-right: 15px;
  }
}
.el-input-group__prepend{
  padding: 0 5px;
}
.dosage-li{
  width: calc(50% - 1px);
  margin-bottom: 10px;
  text-align: center;
  font-size: 12px;
  color: #606266;
  line-height: 30px;
  border-bottom: 1px solid #F2F6FC;
  padding-bottom: 20px;
  .del{
    color: #ff4d51;
    cursor: pointer;
    font-weight: bold;
  }
}
.add-btn{
  margin-top: 20px;
}
.bottom-btn{
  text-align: center;
}
.el-select-dropdown.el-popper{
  max-width: 200px;
}
</style>
