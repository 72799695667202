import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/freeze/page',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/exp/consumables/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/exp/freeze/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/freeze/submit',
    method: 'post',
    data: row
  })
}

export const deleteFreeze = (id) => {
  return request({
    url: '/exp/freeze/delete/' + id,
    method: 'post'
  })
}

export const queryByCode = (code) => {
  return request({
    url: '/exp/freeze/queryByCode/',
    method: 'get',
    params: {
      code
    }
  })
}

export const isUsedByPipeCode = (pipeCodes, expId) => {
  return request({
    url: '/exp/freeze/isUsedByPipeCode',
    method: 'get',
    params: {
      pipeCodes,
      expId
    }
  })
}
