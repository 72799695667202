<template>
  <basic-container>
    <div class="test-wrap">
      <step-line :active="realStep" :list="steps" @update="changeStep"></step-line>
      <div>
        <div v-if="realStep > 0">
          <div style="position: sticky; top: 0; right: 0; z-index: 1; background: #fff; padding: 20px; background: #f8f8f8; font-size: 12px">
            {{formList.content}}
          </div>
          <el-card class="box-card" v-for="(label,index) in formList.componentList" :key="index">
            <div slot="header" class="clearfix">
              <span>{{ label.optionName }}</span>
            </div>
            <div>
              <testProject
                  :key="label.stepId"
                  ref="testProject"
                  :type="label.optionType"
                  v-model="label.content" >
                <div slot="tube" v-if="label.optionType == 9">
                  <!--                  用药方案:-->
                  <!--                  <el-select>-->
                  <!--                    <el-option></el-option>-->
                  <!--                  </el-select>-->
                  <test-tube-plus
                      :data="{
                    count: plateHoleCount || parseInt(label.content.$specifications) || 0,
                    experimentId: experimentId,
                    hole: label.content.$specifications,
                    barcode: label.content.barcode,
                  }"
                      v-model="label.plate"></test-tube-plus>
                </div>
                <div slot="tubeDay">
                  <testTubeDayPlus
                      :data="{
type: 1,
experimentId: experimentId,
stepId: stepId
                  }"
                      v-model="label.plate"></testTubeDayPlus>
                </div>
              </testProject>
            </div>
          </el-card>
        </div>
        <div v-else style="margin-top: 20px">
          <step1 :exp-id="experimentId" ref="step1Valid" v-model="form"></step1>
        </div>
        <div class="button-group">
          <el-button @click="nextStep" type="primary" v-if="realStep + 1 !== steps.length">下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          <el-button @click="nextStep" icon="el-icon-check" type="primary" v-else>保存</el-button>
        </div>
      </div>
    </div>
  </basic-container>
</template>

<script>
// import { getDetail as getExperiment } from '@/api/exp/experiment'
import { submitVr, getStepDetail } from '@/api/exp/subculture'
import step1 from '@/views/exp/subculture/step1'
import { getStepByTemplate, getTestStepList } from '@/api/exp/primaryculture'
// import mixin from '@/mixin/step'
import stepLine from '@/components/step-form/step-line'

import testTubePlus from '@/components/form/test-tube-plus'
import testTubeDayPlus from '@/components/view-comp/test-tube-day-plus'
export default {
  components: {
    testTubePlus,
    testTubeDayPlus,
    step1,
    stepLine,
    testProject: () => import('@/components/awd-test-step/test-form')
  },
  // mixins: [mixin],
  data () {
    return {
      steps: [],
      plateHoleCount: null,
      isFinal: false,
      checkAll: true,
      isIndeterminate: false,
      checkedStep: [],
      experiment: {},
      templateType: '',
      step: 0,
      realStep: 0,
      stepId: '',
      formList: [],
      stepSwitch: false,
      stepNo: 0,
      experimentId: '',
      form: {}
    }
  },
  created () {
    const { templateType, experimentId, stepId } = this.$route.query
    this.stepId = stepId
    this.experimentId = experimentId
    this.templateType = templateType
    this.detail()
  },
  methods: {
    handleStepInfo () {
      this.formList = this.formList.map((item) => {
        item.ignore = true
        return item
      })
      for (const index of this.checkedStep) {
        this.formList[index].ignore = false
      }
      this.stepSwitch = false
    },
    handleCheckAllChange (val) {
      this.checkedStep = val
        ? this.formList.map((item, index) => {
          return index
        })
        : [0]
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      const checkedStep = value.length
      this.checkAll = checkedStep === this.formList.length
      this.isIndeterminate = checkedStep > 0 && checkedStep < this.formList.length
    },
    detail () {
      // 若是第一步
      if (!this.stepId) {
        getStepByTemplate(this.templateType).then(res => {
          if (res.success) {
            this.steps = res.data
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
            this.$router.push({
              path: '/exp/subculture/index'
            })
          }
        })
      } else {
        getTestStepList(this.experimentId).then(res => {
          this.steps = res.data
          for (let i = 0; i < this.steps.length; i++) {
            if (this.stepId === this.steps[i].id) {
              this.realStep = i
            }
          }
        })
        getStepDetail(this.stepId).then(res => {
          this.formList = res.data
          if (this.step === 0) {
            // this.$notify({
            //   title: '提示',
            //   message: this.formList.content,
            //   duration: 0
            // })
            this.form = res.data.componentList[0].content
          }
        })
      }
    },
    changeStep (e) {
      this.realStep = e
      this.stepId = this.steps[e].id
      this.$router.push({
        path: '/exp/subculture/step',
        query: { experimentId: this.experimentId, step: e, stepId: this.steps[e].id }
      })
      this.detail()
    },
    nextStep () {
      if (this.realStep < 1) {
        const data = {
          componentList: [
            {
              content: this.form,
              tubeList: this.form.tubeList
            }
          ]
        }
        if (this.experimentId) {
          data.experimentId = this.experimentId
          data.id = this.stepId
        } else {
          data.templateId = this.templateType
        }
        const testForm = this.$refs.step1Valid
        testForm.$refs.form.validate((valid, msg) => {
          if (!valid) {
            this.$message.error(Object.values(msg)[0][0].message)
          } else {
            submitVr(data).then(res => {
              if (res.success) {
                this.$router.push({
                  path: '/exp/subculture/step',
                  query: { experimentId: res.data[1].experimentId, stepId: res.data[1].id }
                })
                this.stepId = res.data[1].id
                this.experimentId = res.data[1].experimentId
                this.detail()
                // this.experiment = res.data
                // this.formList = this.experiment.content
                // this.forwardStep()
                // this.checkoutTube([11, 9])
                // this.initTubeDay([11, 9], [15, 29])
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      } else {
        this.$refs.testProject[0].refreshForm()
        this.validate().then((valid) => {
          if (valid) {
            const loading = this.$loading({
              lock: true,
              text: '正在保存...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.3)'
            })
            submitVr(this.formList).then(res => {
              if (res.success) {
                // this.experiment = res.data
                // this.formList = this.experiment.content
                // if (!this.$route.query.experimentId) {
                //   let url = new URL(window.location.href)
                //   url.searchParams.append('experimentId', res.data.id)
                //   url = url.toString()
                //   window.history.pushState(null, null, url)
                // }
                // this.forwardStep()
                // this.checkoutTube([11, 9])
                // this.initTubeDay([11, 9], [15, 29])
                // this.$router.push({
                //   path: '/exp/primaryculture/step',
                //   query: { experimentId: res.data[1].experimentId, step: res.data[1].seq, stepId: res.data[1].id }
                // })
                if (this.realStep !== res.data.length - 1) {
                  const index = this.realStep + 1
                  this.$router.push({
                    path: '/exp/subculture/step',
                    query: { experimentId: res.data[index].experimentId, stepId: res.data[index].id }
                  })
                  this.stepId = res.data[index].id
                  this.experimentId = res.data[index].experimentId
                  this.detail()
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
              loading.close()
            })
          }
        })
      }
    },
    validate () {
      const that = this
      return new Promise((resolve) => {
        const testForm = this.$refs.testProject
        getValid(0)
        function getValid (i) {
          testForm[i].$refs.form.validate((valid, done, msg) => {
            done()
            if (valid) {
              if (i + 1 >= testForm.length) {
                resolve(valid)
              } else {
                getValid(i + 1)
              }
            } else {
              console.log('=====>', valid)
              that.$message.error(Object.values(msg)[0][0].message)
              resolve(valid)
            }
          })
        }
      })
    }
  }

}
</script>
<style lang="scss">
.step-title{
  white-space: nowrap;
  text-overflow: ellipsis
}
.button-group{
  text-align: center;
}
.test-wrap{
  display: flex;
  align-items: flex-start;
}
</style>
