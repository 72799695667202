<template>
  <basic-container>
    <good-steps :active="step - 1" :canEdit="stepNo" :list="formList" @update="changeStep"></good-steps>
    <div v-if="step > 1" style="margin-top: 20px">
      <el-card class="box-card" v-for="(label,index) in formList[step-1].forms" :key="index">
        <div slot="header" class="clearfix">
          <span>{{ label.label }}</span>
        </div>
        <div>
          <testProject
              :key="step"
              ref="testProject"
              :type="label.value"
              v-model="label.form" />
        </div>
      </el-card>
    </div>
    <div v-else style="margin-top: 20px">
      <step1 v-model="formList[0].form" ref="step1Valid"></step1>
    </div>
    <div class="button-group">
      <el-button icon="el-icon-arrow-left" v-if="step > 1" @click="prevStep">上一步</el-button>
      <el-button @click="nextStep" type="primary" v-if="formList.length > step && !isFinal">下一步<i class="el-icon-arrow-right el-icon--right"></i></el-button>
      <el-button @click="save" icon="el-icon-check" type="primary" v-if="isFinal">保存</el-button>
    </div>
  </basic-container>
</template>

<script>

import { getCurrentByType } from '@/api/setting/experimenttemplate'
import { getDetail as getExperiment } from '@/api/exp/experiment'
import { submit, isUsedByPipeCode } from '@/api/exp/freeze'
import step1 from '@/views/exp/freeze/step1'

import mixin from '@/mixin/step'

export default {
  components: {
    step1
  },
  mixins: [mixin],
  data () {
    return {
      isFinal: false,
      experiment: {},
      templateType: '',
      step: 1,
      stepNo: 0,
      formList: []
    }
  },
  created () {
    this.templateType = this.$route.query.templateType
    this.experiment.id = this.$route.query.experimentId
  },
  mounted () {
    this.detail()
  },
  methods: {
    detail () {
      // 若是第一步
      if (this.step === 1 && this.experiment.id == null) {
        getCurrentByType(this.templateType).then(res => {
          if (res.success) {
            if (res.data.content != null) {
              this.formList = res.data.content
              this.experiment.templateId = res.data.id
              this.formList = this.formList.map(item => {
                for (let i = 0; i < item.forms.length; i++) {
                  item.forms[i].form = item.forms[i].form || {}
                }
                return item
              })
            } else {
              this.$message({
                type: 'error',
                message: '暂无可用实验模板!'
              })
              this.$router.push({
                path: '/exp/freeze/index'
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
            this.$router.push({
              path: '/exp/freeze/index'
            })
          }
        })
      } else {
        getExperiment(this.experiment.id).then(res => {
          console.log(res)
          this.experiment = res.data
          this.formList = this.experiment.content
          this.stepNo = res.data.stepNo
          this.step = res.data.stepNo
          this.stepIsFinal()
        })
      }
    },
    nextStep () {
      this.experiment.stepNo = this.step
      this.stepNo = (this.stepNo < this.step ? this.step : this.stepNo)
      this.experiment.stepName = this.formList[this.step - 1].name
      this.experiment.content = this.formList
      if (this.step === 1) {
        if (this.experiment.content[0].form.tubeList.length === 0) {
          this.$message({
            type: 'error',
            message: '无有效板孔，无法进行下一步！'
          })
          return
        }
        const testForm = this.$refs.step1Valid
        testForm.$refs.form.validate((valid, msg) => {
          if (!valid) {
            this.$message.error(Object.values(msg)[0][0].message)
          } else {
            submit(this.experiment).then(res => {
              if (res.success) {
                this.experiment = res.data
                this.formList = this.experiment.content
                this.forwardStep()
              } else {
                this.$message({
                  type: 'error',
                  message: res.msg
                })
              }
            })
          }
        })
      } else {
        this.$refs.testProject[0].refreshForm()
        this.validate().then((valid) => {
          if (valid) {
            // todo 冻存实验第6步细胞冻存管(value:11)校验是否已属别的实验
            if (this.step === 6) {
              const threeArr = this.formList[5].forms
              let pipeCodes = ''
              for (let i = 0; i < threeArr.length; i++) {
                if (threeArr[i].value === '11') {
                  pipeCodes = threeArr[i].form.freezePipeCodes
                }
              }
              isUsedByPipeCode(pipeCodes, this.experiment.id).then(res => {
                if (!res.success) {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  })
                } else {
                  submit(this.experiment).then(res => {
                    if (res.success) {
                      this.experiment = res.data
                      this.formList = this.experiment.content
                      this.forwardStep()
                    } else {
                      this.$message({
                        type: 'error',
                        message: res.msg
                      })
                    }
                  })
                }
              })
            } else {
              submit(this.experiment).then(res => {
                if (res.success) {
                  this.experiment = res.data
                  this.formList = this.experiment.content
                  this.forwardStep()
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  })
                }
              })
            }
          }
        })
      }
    },
    validate () {
      const that = this
      return new Promise((resolve) => {
        const testForm = this.$refs.testProject
        getValid(0)
        function getValid (i) {
          testForm[i].$refs.form.validate((valid, done, msg) => {
            done()
            if (valid) {
              if (i + 1 >= testForm.length) {
                resolve(valid)
              } else {
                getValid(i + 1)
              }
            } else {
              console.log('=====>', valid)
              that.$message.error(Object.values(msg)[0][0].message)
              resolve(valid)
            }
          })
        }
      })
    },
    save () {
      this.experiment.stepNo = this.step
      this.experiment.stepName = this.formList[this.step - 1].name
      this.experiment.content = this.formList
      this.$refs.testProject[0].refreshForm()
      this.validate().then((valid) => {
        if (valid) {
          submit(this.experiment).then(res => {
            if (res.success) {
              this.experiment = res.data
              this.formList = this.experiment.content
              this.$router.push({
                path: '/exp/freeze/index'
              })
            } else {
              this.$message({
                type: 'error',
                message: res.msg
              })
            }
          })
        }
      })
    }
  }

}
</script>
<style lang="scss">
.button-group{
  margin-top: 20px;
  text-align: center;
}
</style>
