<template>
  <basic-container>
    <el-collapse-transition>
      <el-form :inline="true" class="search-form">
        <el-form-item label="样本编号">
          <el-input
            @keyup.enter.native="handleFilter"
            class="filter-item"
            placeholder="样本编号"
            v-model="page.sampleCode"></el-input>
        </el-form-item>
        <el-form-item label="样本条码">
          <el-input
              v-model="page.barcode"
              class="filter-item"
              placeholder="样本条码"
              @keyup.enter.native="handleFilter"></el-input>
        </el-form-item>
        <el-form-item label="培养人员">
          <el-input
                  @keyup.enter.native="handleFilter"
                  class="filter-item"
                  placeholder="培养人员"
                  v-model="page.operatorName"></el-input>
        </el-form-item>
        <el-form-item label="实验时间">
          <el-date-picker
                  end-placeholder="结束日期"
                  range-separator="至"
                  start-placeholder="开始日期"
                  type="datetimerange"
                  v-model="page.queryOperateTime"
                  value-format="yyyy-MM-dd HH:mm:ss">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="生长结果评价">
          <el-input
                  @keyup.enter.native="handleFilter"
                  class="filter-item"
                  placeholder="生长结果描述"
                  v-model="page.rating"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item search" type="primary" icon="el-icon-search" @click="handleFilter">搜索</el-button>
          <el-button @click="reset" class="filter-item search" icon="el-icon-search" type="primary">重置</el-button>
        </el-form-item>
      </el-form>
    </el-collapse-transition>
    <el-row class="padding-row">
      <el-button @click="openFrameList" icon="el-icon-plus" type="primary">新增</el-button>
      <el-button
              :disabled="this.ids.length === 0"
              @click="batchDelete()"
              icon="el-icon-delete"
              type="danger"
      >
        删除
      </el-button>
    </el-row>
    <el-table
        class="good-table"
        :data="list"
        v-loading.body="listLoading"
        :row-key="getRowKeys"
        @selection-change="handleSelectionChange"
        element-loading-text="拼命加载中"
        border
        fit
        :cell-style="{'text-align':'center'}"
        :header-cell-style="{'text-align':'center'}"
        highlight-current-row>
      <el-table-column
              :reserve-selection="true"
              label="全选"
              type = 'selection'
              width="55">
      </el-table-column>
      <el-table-column label="样本编号" prop="sampleCode" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.sampleCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="样本条码" prop="barcode" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.barcode}}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="培养人员" prop="operator" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.operatorName}}</span>
        </template>
      </el-table-column>
      <el-table-column label="实验时间" prop="operateTime" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.operateTime}}</span>
        </template>
      </el-table-column>
      <el-table-column :show-overflow-tooltip="true" label="处理原代样品感受" prop="feeling">
        <template slot-scope="scope">
          <span>{{scope.row.feeling}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="培养结果描述" prop="desc">-->
<!--        <template slot-scope="scope">-->
<!--          <span>{{scope.row.desc}}</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column :show-overflow-tooltip="true" label="生长结果评价" prop="rating">
        <template slot-scope="scope">
          <span>{{scope.row.rating}}</span>
        </template>
      </el-table-column>
<!--      <el-table-column label="显微镜下照片" prop="pic" width="100px">-->
<!--        <template slot-scope="scope">-->
<!--          <span><img :src="imgBaseUrl + scope.row.pic" alt="" style="width: 70px;height: 70px" v-if="scope.row.pic"></span>-->
<!--        </template>-->
<!--      </el-table-column>-->
      <el-table-column label="培养对象" prop="pic" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.expObjectCode}}</span>
        </template>
      </el-table-column>
      <el-table-column label="当前实验步骤" prop="current" width="150">
        <template slot-scope="scope">
          <span>{{scope.row.current}}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否有效" prop="isValid" width="150">
        <template slot-scope="scope">
          <span>{{ typeFormatter(scope.row.isValid) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="是否培养成功" prop="isSuccess" width="150">
        <template slot-scope="scope">
          <span>{{ typeFormatter(scope.row.isSuccess) }}</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="200">
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="handleUpdate(scope.row)" icon="el-icon-edit">编辑</el-button>
          <el-button
            v-if="scope.row.status!='deleted'"
            size="small"
            type="text"
            icon="el-icon-delete"
            @click="handleDelete(scope.row)">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="选择模板" :visible.sync="dialogFrame">
      <el-form :model="form">
        <el-form-item label="模板">
          <el-select v-model="frameId" placeholder="请选择模版">
            <el-option v-for="(item, index) in frameList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFrame = false">取 消</el-button>
        <el-button type="primary" @click="handleCreate">确 定</el-button>
      </div>
    </el-dialog>
    <div v-show="!listLoading" class="end pagination-container">
      <el-pagination
        :current-page.sync="page.currentPage"
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        :page-sizes="[10,20,30, 50]"
        :page-size="page.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </div>
  </basic-container>
</template>

<script>
import { getList, deletePrimary, remove, frameList } from '@/api/exp/primaryculture'
import { fileSaveUrl } from '@/config/index'

export default {
  data () {
    return {
      total: null,
      list: null,
      ids: [],
      listLoading: true,
      imgBaseUrl: fileSaveUrl,
      dialogFrame: false,
      frameId: null,
      frameList: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
        freezeCode: '',
        sampleCode: '',
        barcode: '',
        operatorName: '',
        queryOperateTime: '',
        rating: ''
      }
    }
  },
  created () {
    // this.onLoad(this.page)
    this.onLoad()
  },
  methods: {
    onLoad () {
      getList(this.page.currentPage, this.page.pageSize, this.page).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleChangePolicyType (e) {
    },
    handleChangeArea (e) {
      let selectedAreaName = {}
      selectedAreaName = this.areaOptions.find((item) => { // 这里的chargingWorkNameList就是上面遍历的数据源
        return item.dwdmValue === e
        // 筛选出匹配数据，是对应数据的整个对象
      })
      this.form.areaName = selectedAreaName.label
    },
    // 上传成功
    uploadFileSuccess (response, file, fileList) {
      console.log(response)
      if (response.success) {
        this.fileList.push(file)
        this.form.policyUrl = response.data
      } else {
        this.$message.error(response.msg)// 文件上传错误提示
        this.fileList = []
      }
    },
    beforeUpload (file) {
      console.log(file.type, '222')
      if (file.type !== 'doc' && file.type !== 'docx' && file.type === 'pdf') {
        this.fileList = []
        this.$notify({
          title: '上传失败',
          message: '文件格式必须是pdf/word',
          type: 'error',
          duration: 2000
        })
        return false
      }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePreview (file) {
      console.log(file)
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handleFile (e) {
    },
    getArea () {
    },
    reset () {
      this.page.pageSize = 10
      this.page.currentPage = 1
      this.page.total = 0
      this.page.sampleCode = ''
      this.page.operatorName = ''
      this.page.queryOperateTime = ''
      this.page.rating = ''
      this.page.barcode = ''
      this.handleFilter()
    },
    handleFilter () {
      console.log(this.page)
      this.onLoad(this.page)
    },
    openFrameList () {
      frameList(1).then((res) => {
        this.frameList = res.data
      })
      this.dialogFrame = true
    },
    handleCreate () {
      if (this.frameId) {
        this.$router.push({
          path: '/exp/primaryculture/step',
          query: { templateType: this.frameId }
        })
      } else {
        this.$message.error('请选择模板')
      }
    },
    handleUpdate (item) {
      this.$router.push({
        path: '/exp/primaryculture/step',
        query: { experimentId: item.id, stepId: item.currentStepId }
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    getRowKeys (row) {
      return row.id
    },
    handleSelectionChange (val) {
      const checked = val.map(item => {
        return item.id
      })
      this.ids = checked
    },
    batchDelete () {
      remove(this.ids.join(',')).then(res => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.ids = []
          this.handleFilter()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    handleDelete (row) {
      this.$confirm('是否删除该原代培养?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePrimary(row.id).then(res => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.handleFilter()
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保
    },
    typeFormatter: function (status) {
      switch (status) {
        case '0':
          return '否'
        case '1':
          return '是'
      }
    }
  }

}
</script>

<style>
.padding-row {
  margin-bottom: 15px;
}
</style>
