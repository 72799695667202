<template>
  <div>

    <div style="margin-bottom: 20px; width: 300px">
      <el-select v-model="plateValue" @change="changePlate" placeholder="请选择铺板">
        <el-option
            v-for="(item, index) in plateList"
            :key="item.id"
            :label="item.specificationHoles"
            :value="index">
          <span style="float: left">{{ item.specificationHoles }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.barcode }}</span>
        </el-option>
      </el-select>
    </div>
    <div class="day-list" v-if="tubeList.length">
      <el-tag
          size="medium"
          :key="index"
          v-for="(tag, index) in daysContent"
          closable
          :disable-transitions="false"
          :effect="activeDayIndex == index?'dark':'light'"
          @click="checkDay(index)"
          @close="handleClose(index)">
        <i v-if="tag.observeFlag" class="el-icon-view"></i> Day {{tag.day}}
      </el-tag>
      <span v-if="inputVisible" style="margin-left: 20px">Day </span>
      <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增天</el-button>
    </div>
    <div v-if="tubeList.length">当前观察孔: <span v-for="h in viewHoles" style="margin-right: 10px" :key="h.name">{{h.name}}</span></div>
    <div v-if="tubeList.length" style="margin-top: 20px" >
      <div :class="'tubeStand s' + col">
        <div class="tubehead">
          <span v-for="(item, idx) in getName(col)" :key="item">{{ idx + 1 }}</span>
        </div>
        <div style="display: flex">
          <div class="tuberow">
            <span v-for="item in getName(row,'letter')" :key="item">{{item}}</span>
          </div>
          <div class="tubeList">
            <div :class="!item.disabled?'tubeHole':'tubeHole disabled'" v-for="(item, index) in tubeList" :key="index" @click="handleTube(item,index)">
              <i v-if="item.observeFlag" class="el-icon-view"></i>
              <i v-else-if="item.file.length || item.fileDays.split(',').includes(activeDayIndex.toString())" class="el-icon-picture-outline"></i>
              <i v-else :class="!item.disabled?'':'el-icon-close'"></i>
            </div>
          </div>
        </div>
      </div>
      <div style="margin-top: 20px" v-if="plate.observeCheckFlag"> <el-checkbox v-model="daysContent[activeDayIndex].observeFlag" border label="观察天" @change="changeDay"></el-checkbox></div>
    </div>
    <div class="tubeStand" style="margin-top: 20px" v-else >
      <el-empty description="暂无铺板数据"></el-empty>
    </div>
    <el-dialog
        :title="`Day${activeDay}- ${dialogView.name}`"
        :visible.sync="dialogVisible"
        @close="closeDialog"
        width="50%"
        append-to-body
        center>
      <div style="text-align: right; margin-bottom: 10px">
        <div style="float: left" v-if="plate.observeCheckFlag">
          <el-checkbox @change="setViewHole" :disabled="!dialogView.observeFlag && viewHoles.length > 1" label="观察孔" v-model="dialogView.observeFlag" border></el-checkbox>
        </div>
        <el-upload
            action=""
            class="upload-demo"
            ref="upload"
            :show-file-list="false"
            :limit="1"
            :on-success="handleSuccess"
            :file-list="fileList"
            :http-request="upLoadImg">
          <el-button size="small" type="primary">添加</el-button>
        </el-upload>
      </div>
      <el-table
          :data="dialogView.file"
          border
          style="width: 100%">
        <el-table-column
            prop="uploadName"
            label="原文件名">
        </el-table-column>
        <el-table-column
            label="文件类型">
          <template slot-scope="scope">
            <span v-if="scope.row.fileType === '1'">
              原始图像
            </span>
            <span v-if="scope.row.fileType === '2'">
              算法图像
            </span>
            <span v-if="scope.row.fileType === '3'">
              算法结果(表格)
            </span>
            <span v-if="scope.row.fileType === '4'">
              算法结果(文档)
            </span>
            <span v-if="scope.row.fileType === '5'">
              融合图像
            </span>
          </template>
        </el-table-column>
        <el-table-column
            label="图像">
          <template slot-scope="scope">
            <el-image
                v-if="scope.row.fileType === '1' || scope.row.fileType === '2' || scope.row.fileType === '5'"
                style="width: 100px; height: 100px; margin: 0 auto; display: block;background: #F2F6FC;"
                fit="scale-down"
                :key="imgList[scope.$index]"
                :preview-src-list="[imgList[scope.$index]]"
                :src="imgList[scope.$index]"
                lazy>
              <div style="line-height: 100px;text-align: center; color: #76838f" slot="error">
                正在加载…
              </div>
            </el-image>
            <div v-else>
              -
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="uploadTime"
            label="创建时间">
        </el-table-column>
        <el-table-column
            width="100"
            label="操作">
          <template slot-scope="scope">
            <!--            <el-tooltip class="item" effect="dark" content="查看" placement="top">-->
            <!--              <el-button type="text" size="small" icon="el-icon-view" @click="checkfile(scope.row)"></el-button>-->
            <!--            </el-tooltip>-->
            <el-tooltip class="item" effect="dark" content="下载" placement="top">
              <el-button type="text" size="small" icon="el-icon-download" @click="downloadfile(scope.row)"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="top">
              <el-button type="text" size="small" icon="el-icon-delete" @click="delfile(scope)"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column
            width="80"
            label="报告用图"
v-if="plate.observeCheckFlag">
          <template slot-scope="scope">
            <el-checkbox @change="isUseFlag(scope.row)" :disabled="false" label="" v-model="scope.row.checkFlag"></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <!--      <el-image-->
      <!--          style="width: 200px; height: 200px; margin: 0 auto; display: block;background: #F2F6FC;"-->
      <!--          fit="scale-down"-->
      <!--          :preview-src-list="[dialogView]"-->
      <!--          :src="dialogView">-->
      <!--      </el-image>-->
    </el-dialog>
  </div>
</template>

<script>
import Tiff from 'tiff.js'
import { expDeviceresult, uploadTubeFile, delDeviceresult, downloadImg, getPlates, checkDay, checkHole, userImgFlag } from '@/api/exp/experiment'
import { fileSaveUrl, baseUrl } from '@/config/index'
import { ossUploadFile } from '@/util/oss'
function getExtension (filename) {
  const dotIndex = filename.lastIndexOf('.')
  if (dotIndex >= 0) {
    return filename.substring(dotIndex + 1)
  } else {
    return ''
  }
}
const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const grid = {
  g6: [1, 6],
  g12: [2, 6],
  g24: [4, 6],
  g36: [6, 6],
  g48: [6, 8],
  g96: [8, 12],
  g384: [16, 24]
}
export default {
  name: 'TestTubeDay',
  props: {
    data: Object,
    value: {
      type: [Array, Object],
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {}
    }
  },
  data () {
    return {
      plateValue: null,
      fileSaveUrl,
      fileList: [],
      uploadUrl: baseUrl + '/common/fileUpload',
      dialogView: {},
      activeDay: '0',
      activeHole: 0,
      row: 0,
      col: 0,
      activeName: 0,
      dialogVisible: false,
      tubeList: [],
      editIndex: null,
      imgList: [],
      plateList: null,
      normalValue: [],
      viewHoles: [],
      days: new Array(7),
      daysContent: [{
        day: 0,
        observeFlag: false
      }],
      activeDayIndex: 0,
      inputVisible: false,
      inputValue: '',
      plate: ''
    }
  },
  watch: {
    value (val) {
      if (val.holeList) {
        this.refresh()
        this.viewHoles = []
        for (const item of this.value.holeList) {
          if (this.value.observeHoles.split(',').includes(item.observeFlag)) {
            this.viewHoles.push(item)
          }
        }
        if (this.value.daysContent) {
          this.daysContent = this.value.daysContent
        }
      }
    }
  },
  mounted () {
    this.refresh()
  },
  methods: {
    changeDay () {
      checkDay({ plateId: this.plate.id, day: this.daysContent[this.activeDayIndex].day, checkFlag: this.daysContent[this.activeDayIndex].observeFlag }).then(res => {
        if (res.success) {
          this.updataValue()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
          this.daysContent[this.activeDayIndex].observeFlag = false
        }
      })
    },
    updataValue () {
      const t = this
      if (t.daysContent) {
        this.$emit('input', {
          ...t.plate,
          observeHoles: t.viewHoles.length ? t.viewHoles.map(i => i.id).join(',') : '',
          observeDays: t.daysContent.length
            ? t.daysContent.map((i) => {
              if (i.observeFlag) {
                return i.day
              }
              return false
            }).filter(i => i).join(',')
            : '',
          daysContent: t.daysContent,
          holeList: t.tubeList
        })
      }
    },
    checkDay (index) {
      this.activeDayIndex = index
      getPlates({ experimentId: this.data.experimentId, type: this.data.type, stepId: this.data.stepId, observeDays: this.daysContent[index].day }).then(res => {
        this.plateList = res.data
        console.log(this.plateList)
      })
    },
    handleClose (index) {
      this.daysContent.splice(index, 1)
      this.updataValue()
    },

    showInput () {
      this.inputVisible = true
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },
    isUseFlag (item) {
      userImgFlag({ checkFlag: item.checkFlag, id: item.id, plateId: this.plate.id, firstImgFlag: item.firstImgFlag }).then(res => {
        this.$message({
          message: res.msg,
          type: 'warning'
        })
        if (!res.success) {
          item.checkFlag = false
        }
      })
    },
    handleInputConfirm () {
      const inputValue = this.inputValue
      if (inputValue) {
        this.daysContent.push({
          day: inputValue,
          observeFlag: false
        })

        this.inputVisible = false
        this.inputValue = ''
        const t = this
        this.$emit('input', {
          ...t.plate,
          observeHoles: t.viewHoles.length ? t.viewHoles.map(i => i.id).join(',') : '',
          observeDays: t.daysContent.length
            ? t.daysContent.map((i) => {
              if (i.observeFlag) {
                return i.day
              }
              return false
            }).filter(i => i).join(',')
            : '',
          daysContent: t.daysContent,
          holeList: t.tubeList
        })
      }
    },
    setViewHole () {
      checkHole({ plateId: this.plate.id, holeId: this.dialogView.holeId, checkFlag: this.dialogView.observeFlag }).then(res => {
        if (res.success) {
          this.viewHoles = []
          for (const item of this.tubeList) {
            if (item.observeFlag) {
              this.viewHoles.push(item)
            }
          }
          this.updataValue()
        } else {
          this.$message({
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    checkfile (row) {
      window.open(row.filePath)
    },
    delfile (scope) {
      delDeviceresult(scope.row.id, this.dialogView.file.length === 1).then(res => {
        if (res.success) {
          this.dialogView.file.splice(scope.$index, 1)
          this.imgList.splice(scope.$index, 1)
          this.$message({
            message: res.msg,
            type: 'success'
          })
        }
      })
    },
    downloadfile (row) {
      window.open(row.filePath)
      // downloadDeviceresult(row.filePath).then(res => {
      //   const url = URL.createObjectURL(res)
      //   const a = document.createElement('a')
      //   a.href = url
      //   a.download = this.activeName + this.activeHole.name + '.' + getExtension(row.filePath)
      //   document.body.appendChild(a)
      //   a.click()
      //   document.body.removeChild(a)
      // })
    },
    closeDialog () {
      this.dialogView = {}
      this.$refs.upload.clearFiles()
    },
    finishEdit () {
      this.editIndex = null
    },
    editDay (index) {
      console.log(index)
      this.editIndex = index
    },
    removeTab (targetIndex) {
      const tabs = this.normalValue
      let activeName = this.activeName
      if (activeName === targetIndex) {
        tabs.forEach((tab, index) => {
          if (index === targetIndex) {
            const nextIndex = (tabs[index + 1] ? index + 1 : undefined) || (tabs[index - 1] ? index - 1 : undefined)
            if (nextIndex) {
              activeName = nextIndex
            }
          }
        })
      }
      this.activeName = activeName
      this.activeDay = this.normalValue[activeName].day
      // eslint-disable-next-line vue/no-mutating-props
      this.normalValue = tabs.filter((tab, index) => { return index !== targetIndex })
    },
    refresh () {
      getPlates({ experimentId: this.data.experimentId, type: this.data.type, stepId: this.data.stepId, observeDays: 0 }).then(res => {
        this.plateList = res.data
        if (res.data.length) {
          this.plateValue = 0
          this.changePlate()
        }
      })
    },
    changePlate () {
      let row, col
      // eslint-disable-next-line vue/no-mutating-props
      const plate = this.plateList[this.plateValue]
      this.plate = plate
      this.hole = plate.specificationHoles
      this.daysContent = this.daysContent
        ? this.daysContent
        : plate.daysContent
      const hole = this.hole
      if (grid[`g${hole}`]) {
        row = grid[`g${hole}`][0]
        col = grid[`g${hole}`][1]
      } else {
        row = 4
        col = 6
      }
      this.row = row
      this.col = col
      const arr = []
      const count = col * row
      for (let i = 0; i < count; i++) {
        arr.push({
          ...plate,
          name: `${letters[Math.ceil((i + 1) / col) - 1]}${i % col + 1}`,
          index: i,
          disabled: !plate.holeList[i].checkFlag,
          file: [],
          fileDays: plate.holeList[i].fileDays ? plate.holeList[i].fileDays : ''
        })
      }
      this.tubeList = arr
      this.viewHoles = []
      for (const item of this.tubeList) {
        if (item.observeFlag) {
          this.viewHoles.push(item)
        }
      }
    },
    getName (number, key) {
      if (key === 'letter') {
        const arr = []
        for (let i = 0; i < number; i++) {
          arr.push(letters[i])
        }
        return arr
      }
      return new Array(number)
    },

    upLoadImg (response) {
      const loading = this.$loading({
        lock: true,
        text: '正在上传图片…',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      ossUploadFile(response).then(res => {
        const formData = new FormData()
        formData.append('day', this.activeDay)
        formData.append('firstImgFlag', this.dialogView.firstImgFlag)
        formData.append('boardHole', this.activeHole)
        formData.append('expId', this.$route.query.experimentId)
        formData.append('stepId', this.$route.query.stepId)
        formData.append('holeId', this.dialogView.holeId)
        // formData.append('stepNo', this.value[0].step)
        formData.append('filePath', 'https://smp-oss.oss-cn-shanghai.aliyuncs.com/' + res.fileUrl)
        formData.append('uploadName', res.fileName)
        let fileType = 1
        const type = getExtension(response.file.name)
        if (type === 'jpg' || type === 'jpeg') {
          fileType = 1
        } else if (type === 'csv') {
          fileType = 2
        } else if (type === 'doc') {
          fileType = 2
        } else if (type === 'docx') {
          fileType = 2
        }
        formData.append('fileType', fileType)
        uploadTubeFile(formData).then(res => {
          if (res.success) {
            res.data.checkFlag = false
            const t = this
            setTimeout(() => {
              if (res.data.filePath.indexOf('.tif') !== -1) {
                downloadImg(res.data.filePath).then((re) => {
                  const arrayBuffer = re
                  const url = new Tiff({ buffer: arrayBuffer })
                  const image = url.toDataURL()
                  t.imgList.unshift(image)
                  t.dialogView.file.unshift(res.data)
                  t.updataValue()
                })
              } else {
                t.imgList.unshift(res.data.filePath)
                t.dialogView.file.unshift(res.data)
                t.updataValue()
              }
            }, 1000)
          } else {
            this.$message({
              message: res.msg,
              type: 'warning'
            })
          }
        }).finally(res => {
          loading.close()
        })
      })
    },
    handleError (res, file) {
      console.log(res, file)
    },
    handleSuccess (res, file, fileList) {
      this.fileList = []
    },
    handleTube (item, index) {
      if (item) {
        if (!item.disabled) {
          expDeviceresult(item.holeList[index].id, this.daysContent[this.activeDayIndex].day, item.holeList[index].firstImgFlag).then(res => {
            this.$nextTick(async () => {
              this.dialogView = item
              this.activeHole = index
              this.dialogView.firstImgFlag = item.holeList[index].firstImgFlag
              this.dialogView.holeId = item.holeList[index].id
              this.dialogView.observeFlag = this.dialogView.observeFlag ? this.dialogView.observeFlag : false
              this.activeDay = this.daysContent[this.activeDayIndex].day
              this.dialogVisible = true
              this.imgList = new Array(res.data.length)
              for (const i in res.data) {
                if (res.data[i].filePath.indexOf('.tif') !== -1) {
                  const re = await downloadImg(res.data[i].filePath)
                  const arrayBuffer = re
                  const url = new Tiff({ buffer: arrayBuffer })
                  const image = url.toDataURL()
                  this.imgList[i] = image
                } else {
                  this.imgList[i] = res.data[i].filePath
                }
              }
              this.dialogView.file = res.data
            })
          })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.tubeHole-tip{
  width: 100%;
  height: 100%;
}
.tubeStand{
  padding: 20px 20px 10px 20px;
  width: 552px;
  border-radius: 30px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
}
.tubeList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
$width-1x: 80px;
$margin-hole: $width-1x/10;
$width-real: $width-1x - $margin-hole;
.tubeHole{
  border-radius: 50%;
  border: 1px solid #DCDFE6;
  transition: all .2s;
  box-sizing: border-box;
  color: #409eff;
  text-align: center;
}
.s6 .tubeHole{
  line-height:$width-real ;
  font-size:$width-real / 2 ;
  width: $width-real;
  height: $width-real;
  margin: 0 $margin-hole/2 $margin-hole $margin-hole/2;
}
.s8 .tubeHole{
  width: $width-real * 0.75;
  height: $width-real * 0.75;
  line-height:$width-real * 0.75;
  font-size:$width-real / 2* 0.75 ;
  margin: 0 $margin-hole/2 * 0.75 $margin-hole * 0.75 $margin-hole/2 * 0.75;
}
.s12 .tubeHole{
  width: $width-real * 0.5;
  height: $width-real * 0.5;
  line-height:$width-real * 0.5;
  font-size:$width-real / 2* 0.5 ;
  margin: 0 $margin-hole/2 * 0.5 $margin-hole * 0.5 $margin-hole/2 * 0.5;
}
.s24 .tubeHole{
  width: $width-real * 0.25;
  height: $width-real * 0.25;
  line-height: $width-real * 0.25;
  font-size: 12px;
  margin: 0 $margin-hole/2 * 0.25 $margin-hole * 0.25 $margin-hole/2 * 0.25;
}
.tuberow{
  flex-shrink:0;
  width: 30px;
  span{
    box-sizing: border-box;
    color: #C0C4CC;
    display: block;
    text-align: center;
  }
}
.s6 .tuberow{

  span{
    height: $width-1x;
    line-height: $width-1x;
  }
}
.s8 .tuberow{

  span{
    height: $width-1x * 0.75;
    line-height: $width-1x * 0.75;
  }
}
.s12 .tuberow{

  span{
    height: $width-1x * 0.5;
    line-height: $width-1x * 0.5;
  }
}
.s24 .tuberow{

  span{
    height: $width-1x * 0.25;
    line-height: $width-1x * 0.25;
  }
}
.tubehead{
  display: flex;
  height: 30px;
  line-height: 30px;
  color: #C0C4CC;
  justify-content: space-between;
  padding-left: 30px;
  span{
    display: block;
    width: 60px;
    text-align: center;
  }
}
.s24 .tubehead{
  span{
    width: $width-1x * 0.25;
  }
}
.s12 .tubehead{
  span{
    width: $width-1x * 0.5;
  }
}
.s6 .tubehead{
  span{
    width: $width-1x;
  }
}
.s8 .tubehead{
  span{
    width: $width-1x * 0.75;
  }
}
.tubeHole.disabled{
  color: #ff5659;
  text-align: center;
  i{
    display: inline-block;
  }
}
.tubeHole.active{
  background: #409EFF;
  border: 1px solid #409EFF;
  color: #fff;
  text-align: center;
  cursor: pointer;
  i{
    display: inline-block;
  }
}
.day-list{
  .el-tag{
    line-height: 32px;
    height: 34px;
    cursor: pointer;
  }
  .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }
}
</style>
