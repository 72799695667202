<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot-scope="{row}" slot="menu">
        <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                v-if="permission.setting_experimenttemplate_edit"
                @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
                @click="handleEnd(row)"
                icon="el-icon-warning"
                size="small"
                type="text"
                v-if="permission.setting_experimenttemplate_edit && row.status === '1'">冻 结
        </el-button>
        <el-button
                @click="handleStart(row)"
                icon="el-icon-view"
                size="small"
                type="text"
                v-if="permission.setting_experimenttemplate_edit && row.status === '0'">启 用
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { submit, freeze, start } from '@/api/setting/experimenttemplate'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'setting/experimenttemplate',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtn: false,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }]
          },
          {
            label: '实验类型',
            prop: 'type',
            type: 'select',
            search: true,
            rules: [{
              required: true,
              message: '请输入实验类型-代码'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/template_type'
          },
          {
            label: '实验模板版本',
            prop: 'edition',
            rules: [{
              required: true,
              message: '请输入实验模板版本'
            }],
            addDisplay: false,
            hide: true
          },
          {
            label: '实验模板状态',
            prop: 'status',
            rules: [{
              required: true,
              message: '请输入实验模板状态-代码'
            }],
            type: 'radio',
            dicData: [{
              label: '启用',
              value: '1'
            }, {
              label: '冻结',
              value: '0'
            }]
          },
          {
            label: '实验模板内容',
            prop: 'content',
            rules: [{
              required: false,
              message: '请输入实验模板内容'
            }],
            addDisplay: false,
            overHidden: true
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_experimenttemplate_add,
        viewBtn: this.permission.exp_experimenttemplate_view,
        delBtn: this.permission.exp_experimenttemplate_delete,
        editBtn: this.permission.exp_experimenttemplate_edit
      }
    }
  },
  methods: {
    toEdit (row) {
      this.$router.push({
        path: '/exp/steptest',
        query: { templateId: row.id }
      })
    },
    rowSave (row, done, loading) {
      submit(row).then((res) => {
        done()
        this.onLoad(this.page, this.search)
        if (!res.success) {
          this.$message({
            type: 'error',
            message: res.msg
          })
        } else {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }
      }).catch(() => {
        loading()
      })
    },
    handleEnd (row) {
      this.$confirm('确定冻结该条模板吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        freeze(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
      })
    },
    handleStart (row) {
      this.$confirm('确定启用该条模板吗?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        start(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      }).catch(() => {
      })
    }

  }
}
</script>
