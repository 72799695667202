import { dateFormat, randomString } from './util'
import OSS from 'ali-oss'
export function ossUploadFile (option) {
  const file = option.file
  return new Promise((resolve, reject) => {
    const date = dateFormat(new Date(), 'yyyyMMdd') // 当前时间
    const dateTime = dateFormat(new Date(), 'yyyyMMddhhmmss') // 当前时间
    const randomStr = randomString(4)//  4位随机字符串
    const extensionName = file.name.substr(file.name.indexOf('.')) // 文件扩展名
    const fileName = 'img/' + date + '/' + dateTime + '_' + randomStr + extensionName // 文件名字（相对于根目录的路径 + 文件名）
    // 执行上传
    createOssClient().then(client => {
      // 上传处理
      // 分片上传文件
      client.multipartUpload(fileName, file, {
        progress: function (p) {
          const e = {}
          e.percent = Math.floor(p * 100)
          option.onProgress(e)
        }
      }).then((val) => {
        if (val.res.statusCode === 200) {
          resolve({
            fileName: file.name,
            fileUrl: fileName
          })
          option.onSuccess(val)
          return val
        } else {
          option.onError('上传失败')
        }
      }, err => {
        console.log(err)
        option.onError('上传失败')
        reject(err)
      })
    })
  })
}

/**
  * 创建oss客户端对象
  * @returns {*}
  */
function createOssClient () {
  return new Promise((resolve, reject) => {
    const client = new OSS({
      region: 'oss-cn-shanghai',
      accessKeyId: 'LTAI5tP6uz49gJ3fvBQiTLgN',
      accessKeySecret: 'pgHiI02bW7Rx7b20hOGvAOFY3jdHs6',
      bucket: 'smp-oss'
    })
    resolve(client)
  })
}
