<template>
  <basic-container>
    <avue-crud
        :option="option"
        :defaults.sync="defaults"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot-scope="scope" slot="menu">
        <el-button @click="rowClick(scope.row)" icon="el-icon-share" :size="scope.size" :type="scope.type">生命周期</el-button>
      </template>
      <template slot="menuLeft">
        <div class="start">
          <el-button
              size="small"
              type="primary"
              icon="el-icon-plus"
              v-if="permission.market_sample_delete"
              @click="addRowData">新 增
          </el-button>
          <el-button
              type="danger"
              size="small"
              icon="el-icon-delete"
              v-if="permission.market_sample_delete"
              @click="handleDelete">删 除
          </el-button>
          <!--          <el-button id="files" type="primary"  @click.stop="downModule()">下载模版</el-button>-->
          <!--          <el-upload-->
          <!--              :show-file-list="false"-->
          <!--              :action="handleImport()"-->
          <!--              :headers="uploadHeaders()"-->
          <!--              accept=".zip"-->
          <!--              :on-success="uploadSuc">-->
          <!--            <el-button type="primary">导入</el-button>-->
          <!--          </el-upload>-->
        </div>
      </template>
    </avue-crud>
    <el-dialog
        title="生命周期图"
        :visible.sync="dialogVisible"
        @closed="closedDialog"
        width="30%">
      <div>
        <el-empty v-if="!lifeCycle.length" description="暂无数据"></el-empty>
        <el-timeline v-if="lifeCycle.length">
          <el-timeline-item v-for="(item, index) in lifeCycle" color="#2db2f9" placement="top" :key="index" hide-timestamp>
            <div>{{item.generation}}</div>
            <el-card :body-style="{ padding: '20px',background:'#ecf8fa',border:'#ecf8fa'}" :key="idx" @click.native="goExp(li.id, li.typeKey)" shadow="hover" style="margin-top: 10px;border:none " v-for="(li,idx) in item.cycle">
              <b style="color: #2db2f9">{{li.expObjectCode}}</b>
              <p style="color: #335b8b">{{li.type}} - {{li.value}}</p>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import { moduleExcel, getList, remove, submit, getLifeCycle } from '@/api/market/sample'
import FileSaver from 'file-saver'
import { fileSaveUrl } from '@/config/index'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market/sample',
      form: {},
      search: {},
      lifeCycle: [],
      dialogVisible: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      defaults: {},
      selectionList: [],
      option: {
        tip: false,
        menuWidth: 300,
        border: true,
        index: true,
        addBtn: false,
        viewBtn: true,
        selection: true,
        labelWidth: 130,
        column: [
          {
            label: '主键',
            prop: 'id',
            hide: true,
            viewDisplay: false,
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '样本编号',
            prop: 'code',
            search: true,
            rules: [{
              required: false,
              message: '样本编号自动生成,无需填写,可修改'
            }],
            disabled: false,
            tip: '样本编号自动生成,无需填写,可修改',
            placeholder: '请输入样本编号'
          },
          {
            label: '样本条码',
            prop: 'barcode',
            search: true,
            rules: [{
              required: false,
              message: '请输入样本条码'
            }],
            disabled: true
          },
          {
            label: '条码图片',
            prop: 'barcodeUrl',
            hide: true,
            disabled: true,
            type: 'upload',
            listType: 'picture-img',
            addDisplay: false
          },
          {
            label: '样本来源',
            prop: 'source',
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请输入样本来源'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/source_type'
          },
          {
            label: '医院',
            prop: 'hospitalId',
            type: 'select',
            search: true,
            filterable: true,
            width: 105,
            addDisplay: true,
            overHidden: true,
            rules: [{
              required: false,
              message: '请选择医院'
            }],
            cascader: ['officeId'],
            cascaderIndex: 1,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            cascader: ['doctorId'],
            search: true,
            filterable: true,
            rules: [{
              required: false,
              message: '请选择科室'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/office/dict?hospitalId={{key}}'
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            cascader: ['patientId'],
            search: true,
            rules: [{
              required: false,
              message: '请选择医生'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/doctor/dict?officeId={{key}}'
          },
          {
            label: '患者',
            prop: 'patientId',
            type: 'select',
            search: true,
            rules: [{
              required: false,
              message: '请选择患者'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/patient/dict?doctorId={{key}}'
          },
          {
            label: '医院内样本编号',
            prop: 'inHospitalNo'
          }
        ],
        group: [
          {
            label: '基本信息',
            arrow: false,
            column: [
              {
                label: '癌种',
                prop: 'cancerType',
                type: 'select',
                search: true,
                filterable: true,
                rules: [{
                  required: true,
                  message: '请选择癌种'
                }],
                props: {
                  value: 'value',
                  label: 'name'
                },
                dicUrl: '/setting/set/code/cancer_type'
              },
              {
                label: '肿瘤类型',
                prop: 'tumorType',
                type: 'select',
                dicData: [{
                  label: '原发',
                  value: '1'
                }, {
                  label: '复发',
                  value: '2'
                }, {
                  label: '转移',
                  value: '3'
                }, {
                  label: '腹水',
                  value: '4'
                }],
                hide: true
              },
              {
                label: '转移部位',
                prop: 'transferLocation',
                addDisplay: false,
                editDisplay: false,
                hide: true
              },
              {
                label: '样本类型',
                prop: 'type',
                type: 'select',
                search: true,
                filterable: true,
                rules: [{
                  required: true,
                  message: '请选择样本类型'
                }],
                props: {
                  value: 'value',
                  label: 'name'
                },
                dicUrl: '/setting/set/code/sample_type'
              },
              {
                label: '是否复发',
                prop: 'isRecurrence',
                type: 'radio',
                dicData: [{
                  label: '否',
                  value: 0
                }, {
                  label: '是',
                  value: 1
                }],
                mock: {
                  type: 'dic'
                },
                rules: [{
                  required: false,
                  message: '请选择是否复发'
                }]
              },
              {
                label: '离体时间',
                type: 'datetime',
                prop: 'inVitroTime',
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: false,
                  message: '请输入离体时间'
                }]
              },
              {
                label: '取样时间',
                type: 'datetime',
                prop: 'takeTime',
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: true,
                  message: '请输入取样时间'
                }],
                hide: true
              },
              {
                label: '取样部位',
                prop: 'specimenLocation',
                hide: true
              },
              {
                label: '送样时间',
                type: 'datetime',
                prop: 'deliveryTime',
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: false,
                  message: '请输入送样时间'
                }]
              },
              {
                label: '接收时间',
                prop: 'acceptTime',
                type: 'datetime',
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: false,
                  message: '请输入接受时间'
                }],
                hide: true
              },
              {
                label: '处理时间',
                prop: 'processingTime',
                type: 'datetime',
                format: 'yyyy-MM-dd HH:mm:ss',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: true,
                  message: '请输入处理时间'
                }],
                hide: true
              },
              {
                label: '样本称重图片',
                type: 'upload',
                prop: 'photo',
                loadText: '图片上传中，请稍等',
                rules: [{
                  required: false,
                  message: '请输入样本图片'
                }],
                propsHttp: {
                  res: 'data',
                  name: 'name',
                  url: 'url'
                },
                action: '/common/oss/fileUpload',
                tip: '只能上传jpg/png文件',
                listType: 'picture-img',
                labelWidth: 120
              },
              {
                label: '样本特写图片',
                type: 'upload',
                prop: 'featurePhoto',
                loadText: '图片上传中，请稍等',
                tip: '只能上传jpg/png文件',
                propsHttp: {
                  res: 'data',
                  name: 'name',
                  url: 'url'
                },
                action: '/common/oss/fileUpload',
                listType: 'picture-img',
                labelWidth: 120
              },

              {
                label: '重量',
                prop: 'weight',
                hide: true
              },
              {
                label: '体积',
                prop: 'volume',
                hide: true
              },
              {
                label: '预处理情况',
                prop: 'pretreatmentSituation',
                type: 'textarea',
                overHidden: true,
                hide: true
              },
              {
                label: '情况总结',
                prop: 'situationSummary',
                type: 'textarea',
                overHidden: true,
                hide: true
              },
              {
                label: '样本描述',
                maxlength: 200,
                prop: 'description',
                overHidden: true,
                rules: [{
                  required: false,
                  message: '请输入样本描述'
                }],
                width: 105,
                addDisplay: true
              }
            ]
          },
          {
            label: '取样方式',
            arrow: false,
            column: [
              {
                label: '组织样本',
                prop: 'tissueSample',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/tissue_sample',
                width: 90,
                hide: true
              },
              {
                label: '恶性积液',
                prop: 'malignantEffusion',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/malignant_effusion',
                width: 90,
                hide: true
              },
              {
                label: '补充描述',
                prop: 'malignantEffusionDesc',
                hide: true,
                addDisplay: false,
                editDisplay: false,
                viewDisplay: false
              }
            ]
          },
          {
            label: '收样情况质控',
            arrow: false,
            column: [
              {
                label: '完整性质控',
                prop: 'integrityControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/integrity_quality_control',
                width: 90,
                hide: true
              },
              {
                label: '运输温度质控',
                prop: 'transportTemperatureControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/quality_control',
                width: 100,
                hide: true
              },
              {
                label: '运输包装质控',
                prop: 'transportPackageControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/quality_control',
                width: 100,
                hide: true
              },
              {
                label: '运输时间质控',
                prop: 'transportTimeControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/quality_control',
                width: 100,
                hide: true
              },
              {
                label: '大小质控',
                prop: 'sizeControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/quality_control',
                width: 80,
                hide: true
              },
              {
                label: '微生物污染质控',
                prop: 'microbialPollutionControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/quality_control',
                width: 100,
                hide: true
              },
              {
                label: '细胞丰度质控',
                prop: 'cellAbundanceControl',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/quality_control',
                width: 100,
                hide: true
              }
            ]
          },
          {
            label: '质量控制分类（评分）',
            arrow: false,
            column: [
              {
                label: '离体时间',
                prop: 'leaveTimeLevel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/leave_time_level',
                width: 90,
                hide: true
              },
              {
                label: '样本重量',
                prop: 'weightLevel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/weight_level',
                width: 100,
                hide: true
              },
              {
                label: '样本中细胞占比',
                prop: 'cellProportionLevel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/cell_proportion_level',
                width: 100,
                hide: true
              },
              {
                label: '污染情况',
                prop: 'pollutionLevel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/pollution_level',
                width: 100,
                hide: true
              },
              {
                label: '样本到达温度',
                prop: 'temperatureLevel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/temperature_level',
                width: 80,
                hide: true
              },
              {
                label: '保存液有效期',
                prop: 'fluidExpirationLevel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/sys/dict/code/fluid_expiration_level',
                width: 100,
                hide: true
              },
              {
                label: '创建人',
                prop: 'createName',
                addDisplay: false,
                rules: [{
                  required: false,
                  message: '请输入备注'
                }],
                disabled: true
              },
              {
                label: '创建人',
                prop: 'creator',
                viewDisplay: false,
                addDisplay: false,
                editBtn: false,
                rules: [{
                  required: false,
                  message: '请输入备注'
                }],
                disabled: true,
                hide: true
              },
              {
                label: '创建时间',
                prop: 'createTime',
                type: 'datetime',
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: false,
                  message: '请输入备注'
                }],
                disabled: true,
                hide: true,
                value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
              }
            ]
          }]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.market_sample_add,
        viewBtn: this.permission.market_sample_view,
        delBtn: this.permission.market_sample_delete,
        editBtn: this.permission.market_sample_edit
      }
    }
  },
  watch: {
    'form.tumorType' (tumorType) {
      const location = this.findObject(this.option.group, 'transferLocation')
      if (tumorType === '3') {
        location.addDisplay = true
        location.editDisplay = true
        location.viewDisplay = true
      } else {
        location.addDisplay = false
        location.editDisplay = false
        location.viewDisplay = false
      }
    },
    // eslint-disable-next-line no-dupe-keys
    'form.malignantEffusion' (malignantEffusion) {
      const effusionDesc = this.findObject(this.option.group, 'malignantEffusionDesc')
      // 恶性积液选择其他时，展示补充描述
      if (malignantEffusion === '3') {
        effusionDesc.addDisplay = true
        effusionDesc.editDisplay = true
        effusionDesc.viewDisplay = true
      } else {
        effusionDesc.addDisplay = false
        effusionDesc.editDisplay = false
        effusionDesc.viewDisplay = false
      }
    },
    'form.id' (id) {
      const creator = this.findObject(this.option.group, 'creator')
      if (!id) {
        creator.addDisplay = true
        creator.editDisplay = true
        creator.viewDisplay = true
      } else {
        creator.addDisplay = false
        creator.editDisplay = false
        creator.viewDisplay = false
      }
    }
  },
  methods: {
    addRowData () {
      this.$refs.crud.rowAdd()
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      // 延时执行级联加载
      const myThis = this
      setTimeout(function () {
        myThis.$refs.crud.dicInit('cascader')
      }, 500)
    },
    downModule () {
      moduleExcel().then((response) => {
        FileSaver.saveAs(response, '样本管理模板' + '.xls')
      })
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          } else if (res.msg !== '操作成功') {
            this.$message({
              type: 'success',
              message: res.msg
            })
          }
        })
      })
    },
    closedDialog () {
      this.lifeCycle = []
    },
    rowClick (row) {
      this.dialogVisible = true
      getLifeCycle(row.id).then(res => {
        if (res.success) {
          this.lifeCycle = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    goExp (expId, type) {
      let path = ''
      if (type === 1) {
        path = '/exp/primaryculture/step'
      } else if (type === 2) {
        path = '/exp/subculture/step'
      } else if (type === 3) {
        path = '/exp/freeze/step'
      } else if (type === 4) {
        path = '/exp/organ/step'
      } else {
        path = '/exp/drugtest/step'
      }
      this.$router.push({
        path: path,
        query: { experimentId: expId, templateType: type }
      })
    }
  }
}
</script>
