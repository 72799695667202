<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot-scope="{row}" slot="menu">
        <el-button
                type="text"
                icon="el-icon-edit"
                size="small"
                v-if="permission.setting_experimenttemplate_edit"
                @click.stop="toEdit(row)">编辑
        </el-button>
        <el-button
            v-if="permission.setting_experimenttemplate_add"
            icon="el-icon-document-copy"
            size="small"
            type="text"
            @click="handleCopy(row)">复制
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
        :visible.sync="dialogVisible"
        title="复制模板"
        width="30%"
        @closed="closedDialog">
      <div>
        <el-form label-position="top" >
          <el-form-item label="原模板名称:" prop="timer">
            <el-input v-model="sourceName" disabled></el-input>
          </el-form-item>
          <el-form-item label="新模板名称:" prop="timer">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="备注:" prop="content">
            <el-input v-model="form.remark"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" @click="submitCopy">确定</el-button>
        <el-button @click="closedDialog">取消</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { submitVr, copyTemplate } from '@/api/setting/experimenttemplate'

export default {
  mixins: [mixin],
  data () {
    return {
      module: '/setting/experimenttemplate',
      form: {},
      dialogVisible: false,
      sourceName: '',
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: false,
        editBtnText: '修改',
        menuWidth: 260,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入名称'
            }]
          },
          {
            label: '实验类型',
            prop: 'type',
            type: 'select',
            search: true,
            rules: [{
              required: true,
              message: '请输入实验类型-代码'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/template_type'
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_experimenttemplate_add,
        viewBtn: this.permission.exp_experimenttemplate_view,
        delBtn: this.permission.exp_experimenttemplate_delete,
        editBtn: this.permission.exp_experimenttemplate_edit
      }
    }
  },
  methods: {
    handleCopy (row) {
      this.dialogVisible = true
      this.sourceName = row.name
      this.form.id = row.id
    },
    submitCopy () {
      copyTemplate(this.form).then((res) => {
        if (!res.success) {
          this.$message({
            type: 'error',
            message: res.msg
          })
        } else {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.onLoad(this.page, this.search)
          this.closedDialog()
        }
      }).catch(() => {
      })
    },
    closedDialog () {
      this.dialogVisible = false
      this.sourceName = ''
      this.form = {}
    },
    toEdit (row) {
      this.$router.push({
        path: '/exp/steptest',
        query: { templateId: row.id }
      })
    },
    rowSave (row, done, loading) {
      submitVr(row).then((res) => {
        done()
        this.onLoad(this.page, this.search)
        if (!res.success) {
          this.$message({
            type: 'error',
            message: res.msg
          })
        } else {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        }
      }).catch(() => {
        loading()
      })
    }
  }
}
</script>
