<template>
  <div>
    <el-tabs @tab-click="handleClick" type="border-card" v-if="!isEdit" v-model="activeName">
      <el-tab-pane label="冻存前" name="first" >
        <el-form :model="form" :rules="rules" label-width="100px" ref="form" v-if="form.isFreeze == 0">
          <el-col :span="6">
            <el-form-item label="来源类型" prop="source">
              <el-select placeholder="请选择" v-model="listQuery.source">
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in sourceOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="医院">
              <el-select @change="getOfficeList(listQuery.hospitalId)" placeholder="请选择" v-model="listQuery.hospitalId">
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in hospitalOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="科室">
              <el-select @change="getDoctorList($event)" placeholder="请选择" v-model="listQuery.officeId">
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in officeOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="医生">
              <el-select @change="getPatientList($event)" placeholder="请选择" v-model="listQuery.doctorId">
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in doctorOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="样本患者">
              <el-select @change="getSampleList($event)" placeholder="请选择" v-model="listQuery.patientId">
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in patientOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="样本编号">
              <el-select @change="getGenerationList($event)" placeholder="请选择" v-model="listQuery.sampleId">
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in sampleOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="类器官代次">
              <el-select @change="getExpObjectCodeList($event)" placeholder="请选择" v-model="listQuery.generation">
                <el-option
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                        v-for="item in generationOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="药敏对象" prop="expObjectCode">
              <el-select @change="setFormExpObjectCode($event)" placeholder="请选择" v-model="listQuery.expObjectCode" >
                <el-option
                        :value="item.value"
                        :key="item.value"
                        :label="item.label"
                        v-for="item in expObjectCodeOptions">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <div style="text-align: center;margin-top: 20px">
              <el-button @click="handleFilter" icon="search" type="primary">查询</el-button>
              <el-button @click="handleReset" icon="search" type="primary">重置</el-button>
            </div>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="24">
            <el-form-item label="选择孔板" v-if="tubeList.length">
              <div>
                <el-checkbox-group @change="getTubeData" disabled v-model="checkedTubes">
                  <div class="tube-ul">
                    <div :key="index" class="tube-list" v-for="(tube,index) in tubeList">
                      <el-card class="box-card" shadow="hover">
                        <div slot="header">
                          <el-checkbox :key="index" :label="index">孔板编号: {{tube.form.code}}</el-checkbox>
                        </div>
                        <div style="height: 50%; position: relative">
                          <testTube :hole="tube.form.specifications" :showtip="false" :value="tube.form.tube" @input="changeTubeList($event,index)" freeze style="transform-origin: 0 0;zoom:0.5"></testTube>
                        </div>
                      </el-card>
                    </div>
                  </div>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" >
              <el-input disabled v-model="form.operatorName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作时间" prop="operatorTime">
              <el-date-picker
                      placeholder="选择日期"
                      v-model="form.operatorTime"
                      format="yyyy-MM-dd HH:mm:ss"
                      type="datetime"
                      value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
          </el-col>
        </el-form>

      </el-tab-pane>
      <el-tab-pane label="冻存后复苏" name="second">
        <el-form :model="form" :rules="rules" label-width="100px" ref="form" v-if="form.isFreeze == 1">
          <el-col :span="24">
            <el-form-item label="冻存管编号" prop="freezePipeCodes">
              <el-input style="width: 300px" v-model="form.freezePipeCodes"></el-input>
              <el-button @click="handleQuery" icon="search" style="margin-left: 20px" type="primary">查询</el-button>
              <el-button @click="reset" icon="search" style="margin-left: 20px" type="primary">重置</el-button>
            </el-form-item>
            <el-divider></el-divider>
          </el-col>
          <el-col :span="12">
            <el-form-item label="样本编号" prop="sampleCode">
              <el-input v-model="form.sampleCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="冻存编号" prop="experimentCode">
              <el-input v-model="form.experimentCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="冻存代次" prop="generation">
              <el-input v-model="form.generation"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="冻存对象" prop="expObjectCode">
              <el-input v-model="form.expObjectCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作人" >
              <el-input disabled v-model="form.operatorName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="操作时间" prop="operatorTime">
              <el-date-picker
                      format="yyyy-MM-dd HH:mm:ss"
                      placeholder="选择日期"
                      type="datetime"
                      v-model="form.operatorTime"
                      value-format="yyyy-MM-dd HH:mm:ss">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="备注">
              <el-input type="textarea" v-model="form.desc"></el-input>
            </el-form-item>
          </el-col>
        </el-form>

      </el-tab-pane>
    </el-tabs>
    <div v-if="isEdit">
      <el-form :model="form" label-width="100px" ref="form" style="display: block;overflow: hidden;">
        <el-col :span="24">
          <el-form-item label="选择孔板" v-if="tubeList.length">
            <div>
              <el-checkbox-group @change="getTubeData" disabled v-model="checkedTubes">
                <div class="tube-ul">
                  <div :key="index" class="tube-list" v-for="(tube,index) in tubeList">
                    <el-card class="box-card" shadow="hover">
                      <div slot="header">
                        <el-checkbox :key="index" :label="index">孔板编号: {{tube.form.code}}</el-checkbox>
                      </div>
                      <div style="height: 50%; overflow: hidden;position: relative">
                        <testTube :hole="tube.form.specifications" :showtip="false" :value="tube.form.tube" @input="changeTubeList($event,index)" freeze style="transform-origin: 0 0;zoom:0.5"></testTube>
                      </div>
                    </el-card>
                  </div>
                </div>
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="操作人" >
            <el-input disabled v-model="form.operatorName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="操作时间">
            <el-date-picker
                    v-model="form.operatorTime"
                    placeholder="选择日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="16">
          <el-form-item label="备注">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
        </el-col>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getFullDetail, getSampleDict } from '@/api/market/sample'
import { mapGetters } from 'vuex'
import testTube from '@/components/form/test-tube'
import { tubeList, generationDic, expObjectCodeDic } from '@/api/exp/experiment'
import { getHospitalDict } from '@/api/market/hospital'
import { getOfficeDict } from '@/api/market/office'
import { getDoctorDict } from '@/api/market/doctor'
import { getPatientDict } from '@/api/market/patient'
import { queryByCode } from '@/api/exp/freeze'

export default {
  props: {
    value: {
      type: [Array, Object]
    }
  },
  components: {
    testTube
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  data () {
    return {
      isEdit: false,
      checkedTubes: [],
      activeName: 'first',
      listQuery: {
        source: '',
        hospitalId: undefined,
        officeId: undefined,
        doctorId: undefined,
        patientId: undefined,
        sampleId: undefined,
        sampleCode: '',
        generation: '',
        expObjectCode: undefined
      },
      tubeList: [],
      form: {
        desc: '',
        sampleId: undefined,
        sampleCode: '',
        generation: '',
        operatorName: this.$store.getters.userInfo.name,
        operatorId: this.$store.getters.userInfo.id,
        operatorTime: '',
        source: '',
        hospitalId: '',
        officeId: '',
        officeName: '',
        doctorId: '',
        doctorName: '',
        patientId: '',
        patientName: '',
        freezePipeCodes: '',
        expObjectCode: '',
        isFreeze: 0,
        experimentCode: ''
      },
      sourceOptions: [{
        label: '患者',
        value: '1'
      },
      {
        label: '动物',
        value: '2'
      }],
      hospitalOptions: [],
      officeOptions: [],
      doctorOptions: [],
      patientOptions: [],
      sampleOptions: [],
      generationOptions: [],
      expObjectCodeOptions: [],
      rules: {
        source: [
          { required: true, message: '请选择来源类型', trigger: 'blur' }
        ],
        sampleCode: [
          { required: true, message: '请输入样本编号', trigger: 'blur' }
        ],
        freezePipeCodes: [
          { required: true, message: '请输入冻存管编号', trigger: 'blur' }
        ],
        experimentCode: [
          { required: true, message: '请输入冻存编号', trigger: 'blur' }
        ],
        generation: [
          { required: true, message: '请输入代次', trigger: 'blur' }
        ],
        expObjectCode: [
          { required: true, message: '请选择药敏对象', trigger: 'blur' }
        ],
        operatorTime: [
          { required: true, message: '请填写操作时间', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    // this.modelValue.operator = this.userInfo.name

    if (this.value) {
      this.form = {
        ...this.form,
        ...this.value
      }
      this.setQueryParam()
      if (this.form.isFreeze === 0 || this.form.isFreeze === '0') {
        this.activeName = 'first'
      } else {
        this.activeName = 'second'
      }
      this.tubeList = this.form.tubeList
      this.isEdit = false
      if (this.tubeList) {
        for (let i = 0; i < this.tubeList.length; i++) {
          this.checkedTubes.push(i)
        }
      }
    } else {
      this.getTubeList()
    }
    this.getHospitalList()
  },
  watch: {
    // 'modelValue.operateTime' (operateTime) {
    //   if (operateTime == null) {
    //     this.modelValue.operateTime = new Date()
    //   }
    // },
    // 'modelValue.createTime' (createTime) {
    //   if (createTime == null) {
    //     this.modelValue.createTime = new Date()
    //   }
    // }
    form: {
      handler () {
        this.getTubeData()
      },
      deep: true
    }
  },
  methods: {
    handleQuery () {
      if (this.form.freezePipeCodes === '') {
        this.$message({
          type: 'error',
          message: '请输入冻存管编号'
        })
        return
      }
      this.form.sampleCode = ''
      this.form.sampleId = ''
      this.form.generation = ''
      this.form.experimentCode = ''
      this.form.expObjectCode = ''
      queryByCode(this.form.freezePipeCodes).then(res => {
        if (res.success) {
          this.form.sampleCode = res.data.sampleCode
          this.form.sampleId = res.data.sampleId
          this.form.generation = res.data.generation
          this.form.experimentCode = res.data.experimentCode
          this.form.expObjectCode = res.data.expObjectCode
          this.form.freezeExpId = res.data.id
          this.form.isFreeze = 1
          const form = {
            ...this.form
          }
          this.$emit('input', form)
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    reset () {
      this.form.freezePipeCodes = ''
      this.form.sampleCode = ''
      this.form.experimentCode = ''
      this.form.generation = ''
      this.form.expObjectCode = ''
    },
    handleReset () {
      this.listQuery.source = ''
      this.listQuery.hospitalId = ''
      this.listQuery.officeId = ''
      this.listQuery.doctorId = ''
      this.listQuery.patientId = ''
      this.listQuery.sampleId = ''
      this.listQuery.sampleCode = ''
      this.listQuery.generation = ''
      this.listQuery.expObjectCode = ''
    },
    setQueryParam () {
      this.listQuery.source = this.form.source
      this.listQuery.hospitalId = this.form.hospitalId
      this.listQuery.officeId = this.form.officeId
      const office = { value: this.form.officeId, label: this.form.officeName }
      this.officeOptions.push(office)
      this.listQuery.doctorId = this.form.doctorId
      const doctor = { value: this.form.doctorId, label: this.form.doctorName }
      this.doctorOptions.push(doctor)
      this.listQuery.patientId = this.form.patientId
      const patient = { value: this.form.patientId, label: this.form.patientName }
      this.patientOptions.push(patient)
      this.listQuery.sampleId = this.form.sampleId
      const sample = { value: this.form.sampleId, label: this.form.sampleCode }
      this.sampleOptions.push(sample)
      this.listQuery.generation = this.form.generation
      const generation = { value: this.form.generation, label: this.form.generation }
      this.generationOptions.push(generation)

      this.listQuery.expObjectCode = this.form.expObjectCode
      const expObject = { value: this.form.expObjectCode, label: this.form.expObjectCode }
      this.expObjectCodeOptions.push(expObject)
    },
    getHospitalList () {
      getHospitalDict().then(res => {
        this.hospitalOptions = res.data
      })
    },
    getOfficeList (hospitalId) {
      this.listQuery.officeId = undefined
      this.officeOptions = []
      this.listQuery.doctorId = undefined
      this.doctorOptions = []
      this.listQuery.patientId = undefined
      this.patientOptions = []
      this.listQuery.sampleId = undefined
      this.sampleOptions = []
      this.listQuery.generation = undefined
      this.generationOptions = []
      this.listQuery.expObjectCode = undefined
      this.expObjectCodeOptions = []
      getOfficeDict(hospitalId).then(res => {
        this.officeOptions = res.data
      })
    },
    getDoctorList (e) {
      let obj = {}
      obj = this.officeOptions.find((item) => {
        return item.value === e
      })
      this.form.officeId = e
      this.form.officeName = obj.label
      this.listQuery.doctorId = undefined
      this.doctorOptions = []
      this.listQuery.patientId = undefined
      this.patientOptions = []
      this.listQuery.sampleId = undefined
      this.sampleOptions = []
      this.listQuery.generation = undefined
      this.generationOptions = []
      this.listQuery.expObjectCode = undefined
      this.expObjectCodeOptions = []
      getDoctorDict(this.listQuery).then(res => {
        this.doctorOptions = res.data
      })
    },
    getPatientList (e) {
      let obj = {}
      obj = this.doctorOptions.find((item) => {
        return item.value === e
      })
      this.form.doctorId = e
      this.form.doctorName = obj.label
      this.listQuery.patientId = undefined
      this.patientOptions = []
      this.listQuery.sampleId = undefined
      this.sampleOptions = []
      this.listQuery.generation = undefined
      this.generationOptions = []
      this.listQuery.expObjectCode = undefined
      this.expObjectCodeOptions = []
      getPatientDict(this.listQuery).then(res => {
        this.patientOptions = res.data
      })
    },
    getSampleList (e) {
      let obj = {}
      obj = this.patientOptions.find((item) => {
        return item.value === e
      })
      this.form.patientId = e
      this.form.patientName = obj.label
      this.listQuery.sampleId = undefined
      this.sampleOptions = []
      this.listQuery.generation = undefined
      this.generationOptions = []
      this.listQuery.expObjectCode = undefined
      this.expObjectCodeOptions = []
      getSampleDict({ source: this.listQuery.source, patientId: this.listQuery.patientId }).then(res => {
        this.sampleOptions = res.data
      })
    },
    getGenerationList (e) {
      let obj = {}
      obj = this.sampleOptions.find((item) => {
        return item.value === e
      })
      this.form.sampleId = e
      this.form.sampleCode = obj.label
      this.form.source = this.listQuery.source
      this.form.hospitalId = this.listQuery.hospitalId
      this.form.officeId = this.listQuery.officeId
      this.form.doctorId = this.listQuery.doctorId
      this.form.patientId = this.listQuery.patientId
      this.listQuery.generation = undefined
      this.generationOptions = []
      this.listQuery.expObjectCode = undefined
      this.expObjectCodeOptions = []
      generationDic(this.listQuery).then(res => {
        this.generationOptions = res.data
      })
    },
    getExpObjectCodeList (e) {
      let obj = {}
      obj = this.generationOptions.find((item) => {
        return item.value === e
      })
      this.form.generation = e
      this.form.generation = obj.label
      this.form.source = this.listQuery.source
      this.form.hospitalId = this.listQuery.hospitalId
      this.form.officeId = this.listQuery.officeId
      this.form.doctorId = this.listQuery.doctorId
      this.form.patientId = this.listQuery.patientId
      this.form.generation = this.listQuery.generation
      this.listQuery.expObjectCode = undefined
      this.expObjectCodeOptions = []
      expObjectCodeDic(this.listQuery).then(res => {
        this.expObjectCodeOptions = res.data
      })
    },
    setFormExpObjectCode (e) {
      this.form.expObjectCode = e
      this.listQuery.expObjectCode = e
      this.handleFilter()
    },
    setFormGeneration (e) {
      this.form.generation = e
      this.handleFilter()
    },
    handleFilter () {
      this.getTubeList()
    },
    getTubeList () {
      if (typeof (this.listQuery.patientId) !== 'undefined') {
        tubeList(this.listQuery).then(res => {
          this.tubeList = res.data
        })
      } else {
        this.tubeList = []
      }
    },

    handleClick (tab, event) {
      console.log(tab, event)
      this.form.isFreeze = tab.index
    },
    change (e) {
      this.$forceUpdate()
    },
    getTubeData () {
      const arr = []
      for (let i = 0; i < this.checkedTubes.length; i++) {
        arr.push(this.tubeList[this.checkedTubes[i]])
      }
      const form = {
        ...this.form,
        tubeList: arr
      }
      this.$emit('input', form)
    },
    checkFreeze () {
      // 初始化选项
      this.checkedTubes = []
      for (let i = 0; i < this.tubeList.length; i++) {
        for (const j of this.tubeList[i].form.tube) {
          if (j === 2) {
            this.checkedTubes.push(i)
            break
          }
        }
      }
      this.getTubeData()
    },
    changeTubeList (data, index) {
      this.tubeList[index].form.tube = data
      this.checkFreeze()
    },
    getDetail () {
      getFullDetail(this.modelValue.barcode).then((res) => {
        if (res.success) {
          this.modelValue = res.data
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    }
  }

}
</script>
<style lang="scss">
  .tube-ul{
    display: flex;
    flex-wrap: wrap;
  }
  .tube-list{
    font-size: 12px;
    margin-right: 20px;
    margin-bottom: 20px;
    display: block;
  }
</style>
