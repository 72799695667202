<template>
  <div>
    <avue-form ref="form" :option="option" v-model="form" :upload-preview="viewFile">
      <template slot="tube">
        <slot name="tube"></slot>
      </template>
      <template slot="tubeDay">
        <slot name="tubeDay"></slot>
      </template>
      <template slot="drugTube">
        <slot name="drugTube"></slot>
      </template>
      <template slot="ATPTest">
        <slot name="ATPTest"></slot>
      </template>
    </avue-form>
  </div>
</template>

<script>
import option from './index'
import { getCode } from '@/api/exp/experimentassets'
import { getByBarcode, getAssetsCode } from '@/api/setting/barcode'

export default {
  name: 'Test1',
  props: {
    value: [Array, Object],
    type: [Number, String],
    keyType: [String]
  },
  data () {
    return {
      load: false,
      option: {
        emptyBtn: false,
        submitBtn: false
      }
    }
  },
  created () {
    function deepCopy (obj) {
      if (obj === null || typeof obj !== 'object') {
        return obj
      }

      if (obj instanceof Date) {
        return new Date(obj.getTime())
      }

      if (obj instanceof Array) {
        return obj.reduce((arr, item, i) => {
          arr[i] = deepCopy(item)
          return arr
        }, [])
      }

      if (obj instanceof Object) {
        return Object.keys(obj).reduce((newObj, key) => {
          newObj[key] = deepCopy(obj[key])
          return newObj
        }, {})
      }
    }
    this.option = deepCopy(option[this.type])
  },
  computed: {
    // option () {
    //
    //   return option[this.type]
    // },
    form: {
      get () {
        return this.value
      },
      set (value) {
        if (value.barcode !== '' && value.barcode !== undefined) {
          document.onkeypress = (e) => {
            // 如果触发
            // 了回车事件(扫码结束时间)
            // console.log(e)
            if (e.key === 'Enter') {
              console.log(value)
              if (value.barcode !== undefined && value.barcode !== '') {
                // console.log(value.barcode)
                // if (value.barcode.length !== 37) {
                //   this.$message.error('请核对条码位数！')
                // } else {
                getByBarcode({ barcode: value.barcode, objectType: this.type }).then(res => {
                  const data = res.data
                  if (res.success) {
                    value.name = data.name
                    value.model = data.model
                    value.specifications = data.specifications
                    if (this.type === '9' || this.type === '24' || this.type === '43') {
                      value.$specifications = data.specificationsLabel
                    }
                    value.brand = data.brand
                    value.usePeriod = data.usePeriod
                    value.productionDate = data.productionDate
                    value.code = data.objectCode
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.msg
                    })
                  }
                })
              }
            }
          }
        } else {
          if (value.name !== '' && value.specifications !== '' && value.specifications !== undefined && value.brand !== '' && value.model !== undefined && value.model !== '') {
            if (value.usePeriod !== '' && value.usePeriod !== undefined) {
              getAssetsCode({ objectType: this.type, name: value.name, brand: value.brand, model: value.model, specifications: value.specifications, usePeriod: value.usePeriod, productionDate: value.productionDate }).then(res => {
                if (res.success) {
                  value.code = res.data
                } else {
                  this.$message({
                    type: 'error',
                    message: res.msg
                  })
                }
              })
            } else {
              if (value.code === '' || value.code === undefined) {
                getCode({ objectType: this.type, name: value.name, brand: value.brand, model: value.model, specifications: value.specifications }).then(res => {
                  if (res.success) {
                    value.code = res.data.code
                  } else {
                    this.$message({
                      type: 'error',
                      message: res.msg
                    })
                  }
                })
              }
            }
          }
          if (value.brand === '' || value.brand === undefined) {
            value.code = ''
          }
        }
        this.$emit('input', value)
      },
      configurable: true,
      enumerable: true
    }
  },
  methods: {
    viewFile (file, column, done) {
      if (file.type === 'img') {
        done()
      } else {
        // window.location.href = 'http://localhost:6626/admin/viewImg/' + file.url
        window.open(file.url)
      }
    },
    refreshForm (value) {
      value = value || this.type
      this.option = option[value]
      // this.form = this.value
      this.$emit('input', this.value)
      this.$refs.form.init()
      this.$refs.form.updateDic()
      this.$refs.form.dicInit()
    }
  }
}
</script>

<style scoped>

</style>
