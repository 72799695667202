<template>
  <div>
    <div :key="type">
      <el-button v-for="item in option.column" :key="item.value">{{item.label}}</el-button>
    </div>
  </div>
</template>

<script>
import _option from './index'
export default {
  name: 'Test2',
  computed: {
  },
  props: {
    type: String
  },
  data () {
    return {
      option: _option[this.type]
    }
  },
  methods: {
    refreshForm (value) {
      value = value || this.type
      this.option = _option[value]
      this.form = this.value
      this.$refs.form.init()
      this.$refs.form.updateDic()
      this.$refs.form.dicInit()
    }
  },
  watch: {
    type (value) {
      this.refreshForm(value)
    },
    'form.specifications' (specifications) {
      if (specifications != null) {
        this.form.code = this.form.specifications
      }
    }
  }
}
</script>

<style scoped>
.el-button{
  margin: 0 10px 10px 0;
}
</style>
