<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.market_hospital_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import { remove, submit } from '@/api/market/hospital'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market/hospital',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        column: [
          // {
          //   label: '医院名称',
          //   prop: 'id',
          //   search: true,
          //   hide: true,
          //   addDisplay: false,
          //   editDisplay: false,
          //   type: 'select',
          //   filterable: true,
          //   searchSpan: 50,
          //   props: {
          //     value: 'value',
          //     label: 'label'
          //   },
          //   dicUrl: '/market/hospital/dict'
          // },
          {
            label: '医院名称',
            maxlength: 50,
            search: true,
            prop: 'name',
            addDisplay: true,
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入名称'
            }]
          },
          {
            label: '医院简称',
            maxlength: 20,
            overHidden: true,
            prop: 'shortName',
            rules: [{
              required: true,
              message: '请输入简称'
            }]
          },
          {
            label: '医院等级',
            maxlength: 20,
            prop: 'level',
            rules: [{
              required: true,
              message: '请输入医院等级'
            }]
          },
          {
            label: '医院代码',
            maxlength: 20,
            prop: 'code',
            rules: [{
              required: false,
              message: '请输入医院代码'
            }]
          },
          {
            label: '医院地址',
            maxlength: 20,
            prop: 'address',
            rules: [{
              required: true,
              message: '请输入地址'
            }],
            width: 105,
            addDisplay: true,
            overHidden: true
          },
          {
            label: '医院电话',
            maxlength: 20,
            prop: 'phone',
            rules: [{
              required: true,
              message: '请输入联系电话'
            }]
          },
          {
            label: '备注',
            maxlength: 200,
            prop: 'remark',
            // display: false,
            type: 'textarea',
            isShow: false,
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            hide: true
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.market_hospital_add,
        viewBtn: this.permission.market_hospital_view,
        delBtn: this.permission.market_hospital_delete,
        editBtn: this.permission.market_hospital_edit
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowSave (row, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          } else if (res.msg !== '操作成功') {
            this.$message({
              type: 'success',
              message: res.msg
            })
          }
        })
      })
    }
  }
}
</script>

<style>
.el-table__header,.el-table__body,.el-table__footer{
  width:100% !important;
  table-layout: fixed !important;
}
</style>
<style>
.el-table__header,.el-table__body,.el-table__footer{
  width:100% !important;
  table-layout: fixed !important;
}
</style>
