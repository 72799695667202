<template>
 <div>11</div>
</template>

<script>

export default {
  data () {
    return {
      module: 'setting/drugplan',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.setting_drugplan_add,
        viewBtn: this.permission.setting_drugplan_view,
        delBtn: this.permission.setting_drugplan_delete,
        editBtn: this.permission.setting_drugplan_edit
      }
    }
  },
  methods: {
  }
}
</script>
