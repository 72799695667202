<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.market_patient_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { remove, submit, getList } from '@/api/market/patient'
import { mapGetters } from 'vuex'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market/patient',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        labelWidth: 200,
        border: true,
        span: 24,
        index: true,
        viewBtn: true,
        selection: true,
        align: 'center',
        column: [
          {
            label: '医院',
            prop: 'hospitalId',
            width: 105,
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请选择医院'
            }],
            cascader: ['officeId'],
            cascaderIndex: 1,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict',
            addDisplay: true,
            overHidden: true
          },
          {
            label: '科室',
            width: 105,
            addDisplay: true,
            overHidden: true,
            prop: 'officeId',
            type: 'select',
            cascader: ['doctorId'],
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请选择科室'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/office/dict?hospitalId={{key}}`
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            search: true,
            rules: [{
              required: true,
              message: '请选择医生'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/doctor/dict?officeId={{key}}`
          },
          {
            label: '患者',
            maxlength: 10,
            prop: 'name',
            search: true,
            rules: [{
              required: true,
              message: '请输入患者姓名'
            }]
          },
          {
            label: '性别',
            prop: 'sex',
            type: 'radio',
            dicData: [{
              label: '男',
              value: 0
            }, {
              label: '女',
              value: 1
            }],
            rules: [{
              required: true,
              message: '请选择性别'
            }]
          },
          {
            label: '年龄',
            maxlength: 3,
            prop: 'age',
            type: 'number',
            rules: [{
              required: false,
              message: '请输入年龄'
            }],
            max: 999,
            min: 0
          },
          {
            label: '联系电话',
            maxlength: 20,
            prop: 'phone',
            rules: [{
              required: false,
              message: '请输入联系电话'
            }]
          },
          {
            label: '邮箱地址',
            prop: 'email'
          },
          {
            label: '身份证号',
            maxlength: 20,
            prop: 'idCardNo',
            rules: [{
              required: false,
              message: '请输入联系电话'
            }]
          },
          {
            label: '住院号',
            maxlength: 20,
            prop: 'hospitalizedNo',
            rules: [{
              required: false,
              message: '请输入住院号'
            }]
          },
          {
            label: '第一次确诊时间',
            prop: 'diagnoseTime',
            width: 110,
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
              type: 'datetime',
              format: 'yyyy-MM-dd'
            },
            rules: [{
              required: false,
              message: '请输入第一次确诊时间'
            }]
          },
          {
            label: '第一次入院时间',
            prop: 'hospitalizedTime',
            width: 110,
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
              type: 'datetime',
              format: 'yyyy-MM-dd'
            },
            rules: [{
              required: false,
              message: '请输入第一次入院时间'
            }]
          },
          {
            label: '备注',
            maxlength: 200,
            prop: 'remark',
            type: 'textarea',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            hide: true
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.market_patient_add,
        viewBtn: this.permission.market_patient_view,
        delBtn: this.permission.market_patient_delete,
        editBtn: this.permission.market_patient_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      // 延时执行级联加载
      const myThis = this
      setTimeout(function () {
        myThis.$refs.crud.dicInit('cascader')
      }, 500)
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    rowSave (row, done, loading) {
      // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (row.idCardNo && reg.test(row.idCardNo) === false) {
        this.$message({
          message: '身份证输入不合法',
          type: 'warning'
        })
        done()
        return
      }
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowUpdate (row, index, done, loading) {
      const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (row.idCardNo && reg.test(row.idCardNo) === false) {
        this.$message({
          message: '身份证输入不合法',
          type: 'warning'
        })
        done()
        return
      }
      submit(row).then((res) => {
        if (res.success) {
          this.onLoad(this.page, this.search)
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
        done()
      }).catch(() => {
        loading()
      })
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          } else if (res.msg !== '操作成功') {
            this.$message({
              type: 'success',
              message: res.msg
            })
          }
        })
      })
    }
  }
}
</script>
