<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :defaults.sync="defaults"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.exp_drug_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button
                @click.stop="showBarcodeDialog(scope.row)"
                icon="el-icon-upload"
                size="small"
                type="text"
        >生成条码</el-button>
      </template>
    </avue-crud>
    <el-dialog :visible.sync="dialogVisible" title="生成条码">
      <el-form :model="barcodeForm" :rules="addRules" ref="barcodeForm">
        <el-row>
          <el-form-item label="批号:" label-width="100px" prop="batchNo">
            <el-input placeholder="请输入批号，8位数字例如22100003" v-model="barcodeForm.batchNo"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="生产日期:" label-width="100px" prop="productionDate">
            <el-date-picker
                    format="yyMMdd"
                    placeholder="选择生产日期"
                    type="date"
                    v-model="barcodeForm.productionDate"
                    value-format="yyMMdd">
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="使用期限:" label-width="100px" prop="usePeriod">
            <el-date-picker
                    format="yyMMdd"
                    placeholder="选择使用期限"
                    type="date"
                    v-model="barcodeForm.usePeriod"
                    value-format="yyMMdd">
            </el-date-picker>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="流水号:" label-width="100px" prop="serialNo">
            <el-input placeholder="请输入流水号，5位数字例如00100" v-model="barcodeForm.serialNo"></el-input>
          </el-form-item>
        </el-row>
        <el-row align="middle" justify="center" type="flex">
          <el-button-group style="margin-right: 5px">
            <el-button @click="createBarcode(1)" size="mini" style="margin-right: 24px" type="primary">生成条形码</el-button>
            <el-button @click="createBarcode(2)" size="mini" style="margin-right: 24px" type="primary">生成二维码</el-button>
            <el-button @click="handleCanceled()" size="mini">取消</el-button>
          </el-button-group>
        </el-row>
      </el-form>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'
import { fileSaveUrl } from '@/config/index'
import { deepClone } from '@/util/index'
import { submit as createBarcode, isValidserialNo } from '@/api/setting/barcode'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/drug',
      barcodeForm: {},
      dialogVisible: false,
      addRules: {
        batchNo: [{ required: true, message: '请输入批号的后八位数字，例如22100003', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const passwordreg = /^[0-9]{8}$/
              if (!passwordreg.test(value)) {
                callback(new Error('由8位数字组成'))
              } else {
                callback()
              }
            }
          }],
        productionDate: [{ required: true, message: '请输入生产日期，6位数字例如221015', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const passwordreg = /^[0-9]{6}$/
              if (!passwordreg.test(value)) {
                callback(new Error('生产日期由6位数字组成'))
              } else {
                callback()
              }
            }
          }],
        usePeriod: [{ required: true, message: '请输入使用期限，6位数字例如231015', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const passwordreg = /^[0-9]{6}$/
              if (!passwordreg.test(value)) {
                callback(new Error('使用期限由6位数字组成'))
              } else {
                callback()
              }
            }
          }],
        serialNo: [{ required: true, message: '请输入流水号，5位数字例如00100', trigger: 'blur' },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              const passwordreg = /^[0-9]{5}$/
              if (!passwordreg.test(value)) {
                callback(new Error('流水号由5位数字组成'))
              } else {
                callback()
              }
            }
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              // 验证流水号 type 1试剂 2耗材 3设备 4药品
              const param = {
                type: 4,
                objectId: this.barcodeForm.objectId,
                batchNo: this.barcodeForm.batchNo,
                productionDate: this.barcodeForm.productionDate,
                usePeriod: this.barcodeForm.usePeriod,
                serialNo: value
              }
              isValidserialNo(param).then(res => {
                const data = res.data
                if (!data) {
                  callback(new Error('同批次流水号不能重复！'))
                } else {
                  callback()
                }
              })
            }
          }]
      },
      form: {},
      search: {},
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        labelWidth: 120,
        column: [
          {
            label: '主键',
            prop: 'id',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: false,
              message: '请输入主键'
            }]
          },
          {
            label: '商品名',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入商品名'
            }]
          },
          {
            label: '药品简介',
            prop: 'brief',
            type: 'textarea',
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入药品简介'
            }]
          },
          {
            label: '通用名/化学名',
            prop: 'commonName',
            width: 100,
            searchLabelWidth: 150,
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入通用名/化学名'
            }],
            search: true
          },
          {
            label: '药物类型',
            prop: 'drugType',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/drug_type',
            width: 100,
            rules: [{
              required: true,
              message: '请选择药物类型'
            }]
          },
          {
            label: '用药方案',
            prop: 'drugPlan',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/drug_plan',
            width: 100,
            rules: [{
              required: true,
              message: '请选择用药方案'
            }]
          },
          {
            label: '化学结构式',
            prop: 'formula',
            width: 100,
            rules: [{
              required: false,
              message: '请输入化学结构式'
            }]
          },
          {
            label: '适应症',
            prop: 'indication',
            type: 'textarea',
            overHidden: true,
            rules: [{
              required: false,
              message: '请输入适应症'
            }]
          },
          {
            label: '用药方法',
            prop: 'drugMethod',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/drug_method',
            width: 100,
            rules: [{
              required: true,
              message: '请输入用药方法-字典'
            }]
          },
          {
            label: '代谢途径',
            prop: 'metabolic',
            overHidden: true,
            rules: [{
              required: false,
              message: '请输入代谢途径'
            }]
          },
          {
            label: '药代动力学',
            prop: 'pharmacokinetics'
          },
          {
            label: '化学机构图片',
            prop: 'drugPic',
            width: 100,
            type: 'upload',
            loadText: '图片上传中，请稍等',
            rules: [{
              required: false,
              message: '请输入样本图片'
            }],
            propsHttp: {
              home: `${fileSaveUrl}`,
              res: 'data'
            },
            accept: 'image/jpeg,image/jpg,image/png',
            fileSize: 500,
            tip: '只能上传jpg/jpeg/png文件，且不超过500kb',
            action: '/common/fileUpload',
            listType: 'picture-img',
            uploadBefore: (file, done, loading, column) => {
              const suffixName = file.name.substring(file.name.lastIndexOf('.') + 1)
              const isFlag = suffixName === 'jpg' || suffixName === 'jpeg' || suffixName === 'png'
              if (!isFlag) {
                this.$message({
                  message: '格式不支持! 只能上传jpg/jpeg/png文件',
                  type: 'error'
                })
                loading() // 阻断上传
              } else {
                done() // 继续上传
              }
            },
            uploadError: (error, column) => {
              if (error === '文件太大不符合') {
                this.$message.error('图片大小超过限制')
              } else {
                this.$message.error(error)
              }
            },
            uploadAfter: (res, done, loading, column) => {
              if (!res.url) {
                this.$message.error('格式不支持! 只能上传jpg/jpeg/png文件')
              }
              done()
            }
          },
          {
            label: '药理机制',
            prop: 'mechanism',
            type: 'textarea',
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入药理机制'
            }]
          },
          {
            label: '临床研究',
            prop: 'clinical',
            type: 'textarea',
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入临床研究'
            }]
          },
          {
            label: '参考文献',
            prop: 'document',
            type: 'textarea',
            overHidden: true,
            rules: [{
              required: false,
              message: '请输入参考文献'
            }]
          }
        ],
        group: [
          {
            label: '编码规则因子',
            arrow: false,
            column: [
              {
                label: '大类',
                prop: 'barcodeLabel',
                type: 'select',
                cascader: ['barcodeCate'],
                rules: [{
                  required: true,
                  message: '请选择编码规则大类'
                }],
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: '/setting/basebarcode/dict?level=1&singleItem=0'
              },
              {
                label: '分类',
                prop: 'barcodeCate',
                type: 'select',
                cascader: ['barcodeName'],
                rules: [{
                  required: true,
                  message: '请选择编码规则分类'
                }],
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: `/setting/basebarcode/dict?parentId={{key}}&singleItem=0`
              },
              {
                label: '产品名称(对应一级代码)',
                prop: 'barcodeName',
                type: 'select',
                cascader: ['barcodeModel'],
                rules: [{
                  required: true,
                  message: '请选择耗材类型'
                }],
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: `/setting/basebarcode/dict?parentId={{key}}&singleItem=0`
              },
              {
                label: '型号类别(对应型号代码)',
                prop: 'barcodeModel',
                type: 'select',
                cascader: ['barcodeSpecifications'],
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: `/setting/basebarcode/dictSingleItem?parentId={{key}}&props=2`
              },
              {
                label: '产品规格(对应规格代码)',
                prop: 'barcodeSpecifications',
                type: 'select',
                cascader: ['barcodeSubmodel'],
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: `/setting/basebarcode/dictSingleItem?parentId={{key}}&props=1`
              },
              {
                label: '二级类别(对应二级代码)',
                prop: 'barcodeSubmodel',
                type: 'select',
                props: {
                  value: 'value',
                  label: 'label'
                },
                dicUrl: `/setting/basebarcode/dictSingleItem?parentId={{key}}&props=3`
              },
              {
                label: '创建人',
                prop: 'createName',
                addDisplay: false,
                rules: [{
                  required: false,
                  message: '请输入备注'
                }],
                disabled: true
              },
              {
                label: '创建人',
                prop: 'creator',
                viewDisplay: false,
                addDisplay: false,
                editBtn: false,
                rules: [{
                  required: false,
                  message: '请输入备注'
                }],
                disabled: true,
                hide: true
              },
              {
                label: '创建时间',
                prop: 'createTime',
                type: 'datetime',
                format: 'yyyy-MM-dd',
                valueFormat: 'yyyy-MM-dd HH:mm:ss',
                rules: [{
                  required: false,
                  message: '请输入备注'
                }],
                disabled: true,
                hide: true,
                value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
              }
            ]
          }]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.exp_drug_add,
        viewBtn: this.permission.exp_drug_view,
        delBtn: this.permission.exp_drug_delete,
        editBtn: this.permission.exp_drug_edit
      }
    }
  },
  watch: {
    'form.id' (id) {
      const creator = this.findObject(this.option.group, 'creator')
      if (!id) {
        creator.addDisplay = true
        creator.editDisplay = true
        creator.viewDisplay = true
      } else {
        creator.addDisplay = false
        creator.editDisplay = false
        creator.viewDisplay = false
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    },
    showBarcodeDialog (row) {
      const t = this
      t.dialogVisible = true
      t.barcodeForm = deepClone(row)
      t.barcodeForm.name = row.barcodeName
      t.barcodeForm.id = null
      t.barcodeForm.objectId = row.id
      t.barcodeForm.objectCode = row.code
      // 1 试剂， 2 耗材，3 仪器  4 药品
      t.barcodeForm.type = '4'
    },
    createBarcode (codeType) {
      this.$refs.barcodeForm.validate((valid, msg) => {
        if (!valid) {
          this.$message.error(Object.values(msg)[0][0].message)
        } else {
          // codeType 为1时生成条形码， 2生成二维码
          this.barcodeForm.codeType = codeType
          createBarcode(this.barcodeForm).then(res => {
            if (!res.success) {
              this.$message({
                type: 'error',
                message: res.msg
              })
            } else {
              this.$message.success(res.msg || '操作成功')
              this.dialogVisible = false
              this.barcodeForm = {}
            }
          })
        }
      })
    },
    handleCanceled () {
      this.dialogVisible = false
      this.barcodeForm = {}
    }
  }
}
</script>
