<template>
  <div>
    <div style="margin-bottom: 20px; width: 300px">
      <el-select v-model="plateValue" @change="changePlate" placeholder="请选择铺板">
        <el-option
            v-for="(item, index) in plateList"
            :key="item.id"
            :label="item.specificationHoles"
            :value="index">
          <span style="float: left">{{ item.specificationHoles }}</span>
          <span style="float: right; color: #8492a6; font-size: 13px">{{ item.barcode }}</span>
        </el-option>
      </el-select>
    </div>

    <div style="display: flex;width: 100%; margin-top: 20px">
      <div :class="'tubeStand s' + col" :style="disabled?'filter: grayscale(50%);':''">
        <div class="tubehead">
          <span v-for="(item, index) in getName(col)" :key="item">{{index + 1}}</span>
        </div>
        <div style="display: flex">
          <div class="tuberow">
            <span v-for="item in getName(row,'letter')" :key="item">{{item}}</span>
          </div>
          <div class="tubeList freeze">
            <div @click="handleTube(index, item)" :class="item.checkFlag?'tubeHole active':(item.disabled?'tubeHole disabled':'tubeHole')" v-for="(item, index) in tubeList" :key="index">
              <el-tooltip class="tubeHole-tip" effect="dark" :disabled="!showtip || item.disabled" :content="item.holeName" placement="top">
                <!--              <div class="tubeHole-freeze" v-if="!item.value" style="color: #fff"><i class="el-icon-warning-outline"></i></div>-->
                <div class="tubeHole-disabled" v-if="item.disabled"><i class="el-icon-close"></i></div>
                <div class="tubeHole-able" v-if="item.checkFlag"><i class="iconfont icon-dropper"></i></div>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
      <el-table
          style="margin-left: 20px; max-width: 500px"
          :data="tubeList.filter(v => v.checkFlag)"
          height="382"
          border>
        <el-table-column
            label="孔位"
            width="80">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions>{{scope.row.holeName}}</el-tag>
          </template>
        </el-table-column>

        <el-table-column
            label="药品">
          <template slot-scope="scope">
            {{scope.row.drugName}}
          </template>
        </el-table-column>
        <el-table-column
            label="配比浓度(umol/L)"
            width="130">
          <template slot-scope="scope">
            <div>{{scope.row.compoundingConcentration}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="终浓度(umol/L)"
            width="120">
          <template slot-scope="scope">
            <div>{{scope.row.finalConcentration}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="存活率(%)"
            width="80">
          <template slot-scope="scope">
            <div>{{scope.row.aliveAbility}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="抑制率(%)"
            width="80">
          <template slot-scope="scope">
            <div>{{scope.row.killAbility}}</div>
          </template>
        </el-table-column>
        <el-table-column
            label="空白孔"
            width="80">
          <template slot-scope="scope">
            {{scope.row.blankFlag?'是':''}}
          </template>
        </el-table-column>
        <el-table-column
            label="对照孔"
            width="80">
          <template slot-scope="scope">
            {{scope.row.compareFlag?'是':''}}
          </template>
        </el-table-column>
      </el-table>
<!--      <el-dialog-->
<!--              title="药敏铺板"-->
<!--              :visible.sync="dialogVisible">-->
<!--            <div style="overflow-y: auto; max-height: 500px">-->
<!--              <el-form :model="formInline" label-width="80px">-->
<!--                <el-form-item label="孔位">-->
<!--                  <el-row :gutter="20">-->
<!--                    <el-col :span="4">-->
<!--                      <el-tag>{{formInline.holeName}}</el-tag>-->
<!--                    </el-col>-->
<!--                    <el-col :span="4">-->
<!--                      对照孔-->
<!--                      <el-checkbox @change="noExp" v-model="formInline.value.isReferenceHole"></el-checkbox>-->
<!--                    </el-col>-->
<!--                    <el-col :span="10" v-if="!formInline.value.isReferenceHole">-->
<!--                      抑制率-->
<!--                      <el-input-number style="margin-left: 10px" v-model="formInline.value.killAbility" controls-position="right" :min="-20" :max="120" placeholder="请填写抑制率">-->
<!--                      </el-input-number>-->
<!--                      %-->
<!--                    </el-col>-->
<!--                  </el-row>-->
<!--                </el-form-item>-->
<!--                <template v-if="!formInline.value.isReferenceHole">-->
<!--                <el-card style="margin-top: 10px" shadow="never" v-for="(it, idx) in formInline.value.value" :key="idx" :body-style="{ padding: '20px 10px', position: 'relative' }">-->
<!--                  <el-form-item label="Cmax">-->
<!--                    <el-row :gutter="20">-->
<!--                      <el-col :span="8">-->
<!--                        <el-checkbox @change="changeCmax" v-model="formInline.value.value[idx].isCmax" ></el-checkbox>-->
<!--                      </el-col>-->
<!--                    </el-row>-->
<!--                  </el-form-item>-->
<!--                <el-form-item label="药物">-->
<!--                  <el-row :gutter="20">-->
<!--                    <el-col :span="8">-->
<!--                  <el-select v-model="formInline.value.value[idx].drugId" placeholder="选择药物">-->
<!--                    <el-option v-for="(item,index) in druglist" :key="index" :label="item.label" :value="item.value">-->
<!--                      <div :title="item.label" style="padding: 0 20px; max-width: 300px; overflow: hidden; text-overflow: ellipsis; white-space: nowrap">{{item.label}}</div>-->
<!--                    </el-option>-->
<!--                  </el-select>-->
<!--                    </el-col>-->
<!--                  </el-row>-->
<!--                </el-form-item>-->
<!--                <el-form-item label="浓度" style="margin-bottom: 0">-->
<!--                  <el-row :gutter="20">-->
<!--                    <el-col style="width: 140px">-->
<!--                      <el-input-number v-model="formInline.value.value[idx].concentration" controls-position="right" :max="1000" :precision="2" placeholder="请填写浓度">-->
<!--                      </el-input-number>-->
<!--                    </el-col>-->
<!--                    <el-col :span="6">-->
<!--                      μM-->
<!--                    </el-col>-->
<!--                  </el-row>-->
<!--                </el-form-item>-->
<!--                  <div style="position: absolute; right: 10px;top: 10px" v-if="idx !== 0">-->
<!--                    <el-button @click="delDosing(idx)" size="mini" round icon="el-icon-close"></el-button>-->
<!--                  </div>-->
<!--                </el-card>-->
<!--                </template>-->
<!--      &lt;!&ndash;          <el-form-item label="杀伤力">&ndash;&gt;-->
<!--      &lt;!&ndash;            <el-row :gutter="20">&ndash;&gt;-->
<!--      &lt;!&ndash;              <el-col style="width: 140px">&ndash;&gt;-->
<!--      &lt;!&ndash;            <el-input-number v-model="formInline.value.killAbility" controls-position="right" :min="0" :max="125" placeholder="请填写细胞活力">&ndash;&gt;-->
<!--      &lt;!&ndash;            </el-input-number>&ndash;&gt;-->
<!--      &lt;!&ndash;              </el-col>&ndash;&gt;-->
<!--      &lt;!&ndash;              <el-col :span="6">&ndash;&gt;-->
<!--      &lt;!&ndash;                %&ndash;&gt;-->
<!--      &lt;!&ndash;              </el-col>&ndash;&gt;-->
<!--      &lt;!&ndash;            </el-row>&ndash;&gt;-->
<!--      &lt;!&ndash;          </el-form-item>&ndash;&gt;-->
<!--              </el-form>-->
<!--              <el-button v-if="!formInline.value.isReferenceHole" @click="addDosing" style="margin-top: 20px" type="primary" icon="el-icon-plus"></el-button>-->
<!--            </div>-->
<!--            <span slot="footer" class="dialog-footer">-->
<!--              <el-button @click="dialogVisible = false">取 消</el-button>-->
<!--              <el-button type="primary" @click="submitDrug">确 定</el-button>-->
<!--            </span>-->
<!--          </el-dialog>-->
    </div>
  </div>

</template>

<script>
import { baseUrl } from '@/config/index'
import { getPlates } from '@/api/exp/experiment'
import { getDrugDict } from '@/api/exp/drugtest'
const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const grid = {
  g6: [1, 6],
  g12: [2, 6],
  g24: [4, 6],
  g36: [6, 6],
  g48: [6, 8],
  g96: [8, 12],
  g384: [16, 24]
}
export default {
  name: 'TestTube',
  props: {
    showtip: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Array,
      default: () => []
    },
    freeze: {
      type: Boolean,
      default: false
    },
    data: Object
  },
  // computed: {
  //   value: {
  //     get () {
  //       return this.value
  //     },
  //     set (value) {
  //       this.$emit('input', this.getValue(this.value))
  //     }
  //   }
  // },
  watch: {
    hole (value) {
      console.log('孔数变化', value)
      if (value) {
        this.refresh()
      }
    },
    value (value) {
      this.getValue(value)
    }
  },
  filters: {
    drug (value, option) {
      for (const item of option) {
        if (item.value === value) {
          return item.label
        }
      }
    }
  },
  created () {
    getPlates({ experimentId: this.data.experimentId, type: 1, stepId: this.data.stepId }).then(res => {
      this.plateList = res.data
      if (res.data.length) {
        this.plateValue = 0
        this.changePlate()
      }
    })
    this.refresh()
    this.getDrug()
  },
  mounted () {
    this.getValue(this.value)
  },
  data () {
    return {
      row: 0,
      col: 0,
      tubeList: [],
      checkAll: [],
      myChart: {},
      druglist: [],
      plateList: [],
      plateValue: null,
      hole: 0,
      formInline: {
        holeName: '',
        value: {
          isReferenceHole: false,
          value: [{
            drugId: null,
            concentration: '',
            isCmax: false
          }]
        }
      },
      index: 0,
      isIndeterminate: true,
      dialogVisible: false,
      baseUrl: baseUrl,
      fileList: [],
      plateId: ''
    }
  },
  methods: {
    noExp (val) {
      if (this.formInline.value) {
        this.formInline.value.isReferenceHole = val
      }
    },
    changeCmax (val) {
      const list = this.formInline.value.value
      for (let i = 0; i < list.length; i++) {
        list[i].isCmax = val
      }
      this.formInline.value.isCmax = val
    },
    delDosing (index) {
      this.formInline.value.value.splice(index, 1)
    },
    addDosing () {
      if (this.formInline.value.value.length < 10) {
        this.formInline.value.value.push({
          drugId: null,
          concentration: '',
          isCmax: this.formInline.value.value[this.formInline.value.value.length - 1].isCmax
        })
      } else {
        this.$message({
          type: 'error',
          message: '最多只可加入十种药物'
        })
      }
    },
    submitDrug () {
      const index = this.index
      this.dialogVisible = false
      if (this.formInline.value.value.length > 1) {
        // 联合用药
        this.formInline.value.type = 2
      }
      this.tubeList[index].value = Object.assign(this.formInline.value, {})
      this.tubeList[index].checked = true
      this.$emit('input', this.tubeList)
    },
    getDrug () {
      getDrugDict().then(res => {
        this.druglist = res.data
      })
    },
    changePlate (e) {
      let row, col
      // eslint-disable-next-line vue/no-mutating-props
      const plate = this.plateList[this.plateValue]
      this.hole = plate.specificationHoles
      this.plateId = plate.id
      const hole = this.hole
      if (grid[`g${hole}`]) {
        row = grid[`g${hole}`][0]
        col = grid[`g${hole}`][1]
      } else {
        row = 4
        col = 6
      }

      // const arr = []
      // const count = col * row
      // for (let i = 0; i < count; i++) {
      //   arr.push({
      //     name: plate[i].holeName,
      //     index: plate[i].holeIndex,
      //     checked: plate[i].checkFlag,
      //     freeze: false,
      //     disabled: false,
      //     value: null
      //   })
      // }

      this.$nextTick(() => {
        this.row = row
        this.col = col
        this.tubeList = plate.holeList.map((item) => {
          const drug = plate.dosageDrugList.find(r => r.holeName === item.holeName)
          return {
            ...drug,
            ...item
          }
        })
      })
      console.log(this.tubeList)
    },
    refresh () {
      if (this.value) {
        let row, col
        const hole = this.value.length
        if (grid[`g${hole}`]) {
          row = grid[`g${hole}`][0]
          col = grid[`g${hole}`][1]
        } else {
          row = 4
          col = 6
        }
        this.row = row
        this.col = col
        this.tubeList = this.value
      } else {
        this.changePlate()
      }

      // this.tubeList = arr
    },
    getValue (value) {
      for (let i = 0; i < value.length; i++) {
        this.tubeList[i].disabled = value[i].disabled
        this.tubeList[i].checked = value[i].checked
        this.tubeList[i].freeze = value[i].freeze
        this.tubeList[i].value = value[i].value
      }
    },
    getName (number, key) {
      if (key === 'letter') {
        const arr = []
        for (let i = 0; i < number; i++) {
          arr.push(letters[i])
        }
        return arr
      }
      return new Array(number)
    },
    handleTube (index, item) {
      if (this.tubeList[index].disabled) {
        return
      }
      if (!this.tubeList[index].checked) {
        this.formInline.holeName = this.tubeList[index].holeName
        this.formInline.value = {
          isReferenceHole: false,
          value: [{
            drugId: null,
            concentration: '',
            isCmax: false
          }]
        }
      } else {
        this.formInline = Object.assign({}, this.tubeList[index])
      }
      this.index = index
      this.$nextTick(() => {
        this.dialogVisible = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.table-list{
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  border-bottom: 1px solid #E4E7ED;
  padding: 10px 0;
}
.table-list:last-of-type{
  border-bottom: none;
}
.tubeHole-tip{
  width: 100%;
  height: 100%;
}
.tubeStand{
  padding: 20px 20px 10px 20px;
  width: 552px;
  border-radius: 30px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
}
.tubeList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
}
$width-1x: 80px;
$margin-hole: $width-1x/10;
$width-real: $width-1x - $margin-hole;
.tubeHole{
  border-radius: 50%;
  border: 1px solid #DCDFE6;
  transition: all .2s;
  box-sizing: border-box;
  .tubeHole-freeze{
    color:#409EFF;
    text-align: center;
  }
  .tubeHole-disabled{
    color: #ff5659;
    text-align: center;
  }
  .tubeHole-able{
    color:#409EFF;
    text-align: center;
  }
}

.s6 .tubeHole{
  width: $width-real;
  height: $width-real;
  font-size:$width-real / 2 ;
  line-height:$width-real;
  flex-basis: 15%;
}
.s8 .tubeHole{
  width: $width-real * 0.75;
  height: $width-real * 0.75;
  font-size:$width-real * 0.75 / 2 ;
  line-height: $width-real * 0.75;
  flex-basis: 11%;
  margin: 0 $margin-hole/2 * 0.75 $margin-hole * 0.75 $margin-hole/2 * 0.75;
}
.s12 .tubeHole{
  width: $width-real * 0.5;
  height: $width-real * 0.5;
  font-size:$width-real * 0.5 / 2 ;
  line-height: $width-real * 0.5;
  flex-basis: 7.4%;
  margin: 0 $margin-hole/2 * 0.5 $margin-hole * 0.5 $margin-hole/2 * 0.5;
}
.s24 .tubeHole{
  width: $width-real * 0.25;
  line-height: $width-real * 0.25;
  font-size:$width-real * 0.25 / 2 ;
  height: $width-real * 0.25;
  margin: 0 $margin-hole/2 * 0.25 $margin-hole * 0.25 $margin-hole/2 * 0.25;
}
.tuberow{
  flex-shrink:0;
  width: 30px;
  span{
    box-sizing: border-box;
    color: #C0C4CC;
    display: block;
    text-align: center;
  }
}
.s6 .tuberow{

  span{
    height: $width-1x;
    line-height: $width-1x;
  }
}
.s8 .tuberow{

  span{
    height: $width-1x * 0.75;
    line-height: $width-1x * 0.75;
  }
}
.s12 .tuberow{

  span{
    height: $width-1x * 0.5;
    line-height: $width-1x * 0.5;
  }
}
.s24 .tuberow{

  span{
    height: $width-1x * 0.25;
    line-height: $width-1x * 0.25;
  }
}
.tubehead{
  display: flex;
  height: 30px;
  line-height: 30px;
  color: #C0C4CC;
  justify-content: space-between;
  padding-left: 30px;
  span{
    display: block;
    width: 60px;
    text-align: center;
  }
}
.s24 .tubehead{
  span{
    width: $width-1x * 0.25;
  }
}
.s12 .tubehead{
  span{
    width: $width-1x * 0.5;
  }
}
.s6 .tubehead{
  span{
    width: $width-1x;
  }
}
.s8 .tubehead{
  span{
    width: $width-1x * 0.75;
  }
}
.tubeHole.disabled{
  border: 1px solid #ff5659;
  background: #ffe2e2;
}
.normal{
  cursor: pointer;
  .tubeHole:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #409EFF;
    background: rgb(217, 236, 255);
    cursor: pointer;
  }
  .tubeHole.active{
    background: #409EFF;
    border: 1px solid #409EFF;
  }
}
  .tubeHole.disabled:hover{
    border: 1px solid #ff5659;
    background: #ffe2e2;
    box-shadow: none;
    cursor: not-allowed;
  }

.freeze{
  .tubeHole{
    cursor: not-allowed;
  }
  .tubeHole.active:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .tubeHole.active{
    background: rgb(217, 236, 255);
    border: 1px solid #409EFF;
  }
}
.table-dosing{
  border: 1px solid #DCDFE6;
  display: flex;
  .table-dosing-colunm{
    text-align: center;
    border-left: 1px solid #DCDFE6;
    width: 16.66%;
    &:first-of-type{
      border-left: 0;
    }
    .table-dosing-tr{
      border-bottom: 1px solid #DCDFE6;
      font-weight: bold;
    }
    .table-dosing-td{
      padding: 10px;
    }
  }
}
.chart{
  width: 500px;
  height: 300px;
  margin:0 auto;
  canvas{
    width: 100%;
  }
}
</style>
