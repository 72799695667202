<template>
  <basic-container>
    <avue-crud
            :before-open="beforeOpen"
            :data="data"
            :option="option"
            :page.sync="page"
            :permission="permissionList"
            :search.sync="search"
            @on-load="onLoad"
            @refresh-change="refreshChange"
            @row-del="rowDel"
            @row-save="rowSave"
            @row-update="rowUpdate"
            @search-change="searchChange"
            @search-reset="searchReset"
            @selection-change="selectionChange"
            ref="crud"
            v-model="form">
      <template slot="menuLeft">
        <el-button
                @click.stop="openFrameList"
                icon="el-icon-plus"
                size="small"
                type="primary"
        >新增
        </el-button>
      </template>
      <template slot="menuLeft">
        <el-button
                :disabled="this.ids.length === 0"
                @click="handleDelete"
                icon="el-icon-delete"
                size="small"
                type="danger"
                v-if="permission.exp_experiment_delete">删 除
        </el-button>
      </template>
      <template slot="menu" slot-scope="{row}">
        <el-button
            @click.stop="handleUpdate(row)"
            icon="el-icon-edit"
            size="small"
            type="text"
            v-if="permission.exp_experiment_edit">编辑
        </el-button>
        <el-button
            @click.stop="rowDel(row)"
            icon="el-icon-delete"
            size="small"
            type="text"
            v-if="permission.exp_experiment_delete">删除
        </el-button>
        <el-button
                @click.stop="passage(row)"
                icon="el-icon-share"
                type="text"
                v-if="row.stepNo == 16"
          >传代
        </el-button>
      </template>
      <template slot="search">
        <el-form :inline="true" class="search-form">
          <el-form-item label="传代日期">
            <el-date-picker
                    align="right"
                    end-placeholder="结束日期"
                    format="yyyy-MM-dd HH:mm:ss"
                    range-separator="至"
                    start-placeholder="开始日期"
                    style="width: 400px !important;"
                    type="daterange"
                    unlink-panels
                    v-model="search.queryOperateTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form>
      </template>
    </avue-crud>
    <el-dialog title="选择模板" :visible.sync="dialogFrame">
      <el-form :model="form">
        <el-form-item label="模板">
          <el-select v-model="frameId" placeholder="请选择模版">
            <el-option v-for="(item, index) in frameList" :key="index" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFrame = false">取 消</el-button>
        <el-button type="primary" @click="handleCreate">确 定</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { remove } from '@/api/exp/subculture'
import { frameList } from '@/api/exp/primaryculture'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/subCulture',
      form: {},
      dialogFrame: false,
      frameList: [],
      frameId: '',
      search: {
        queryOperateTime: ''
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        addBtn: false,
        viewBtn: false,
        editBtn: false,
        delBtn: false,
        selection: true,
        searchMenuSpan: 6,
        column: [
          {
            label: '传代编号',
            prop: 'experimentCode',
            search: true,
            width: 120,
            align: 'center',
            searchOrder: 1
          },
          {
            label: '样本编号',
            prop: 'sampleCode',
            search: true,
            width: 120,
            align: 'center',
            searchOrder: 2
          },
          {
            label: '培养编号',
            prop: 'expObjectCode',
            align: 'center'
          },
          {
            label: '代次',
            prop: 'generation',
            align: 'center'
          },
          {
            label: '培养人员',
            prop: 'operatorName',
            width: 80,
            align: 'center',
            overHidden: true,
            search: true
          },
          {
            label: '传代时间',
            width: 130,
            align: 'center',
            prop: 'operatorTime'
          },
          {
            label: '培养箱编号',
            width: 120,
            align: 'center',
            prop: 'incubatorCode',
            rules: [{
              required: true,
              message: '请输入试剂型号'
            }]
          },
          {
            label: '培养箱层位',
            prop: 'location',
            width: 120,
            align: 'center',
            dicUrl: '/sys/dict/code/pyx_location',
            rules: [{
              required: false,
              message: '请输入试剂型号'
            }]
          },
          {
            label: '孔板编号',
            prop: 'tubeCode',
            width: 120,
            align: 'center'
          },
          {
            label: '孔位编号',
            prop: 'tubeCheckedCode',
            overHidden: true
          },
          {
            label: '父代编号',
            prop: 'parentCode',
            width: 120,
            align: 'center',
            search: true
          },
          {
            label: '父代是否冻存',
            prop: 'parentIsFreeze',
            type: 'select',
            width: 120,
            align: 'center',
            search: true,
            searchLabelWidth: 100,
            dicData: [{
              label: '否',
              value: '0'
            }, {
              label: '是',
              value: '1'
            }]
          },
          {
            label: '样品扩增速度感受',
            prop: 'feeling',
            width: 120,
            overHidden: true,
            align: 'center',
            search: true,
            searchSpan: 8,
            searchLabelWidth: 130
          },
          {
            label: '是否培养成功',
            prop: 'isSuccess',
            width: 120,
            align: 'center',
            dicData: [{
              label: '失败',
              value: 0
            }, {
              label: '成功',
              value: 1
            }]
          },
          {
            label: '是否有效样本',
            prop: 'isValid',
            width: 120,
            align: 'center',
            dicData: [{
              label: '否',
              value: '0'
            }, {
              label: '是',
              value: '1'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_experiment_add,
        viewBtn: this.permission.exp_experiment_view,
        delBtn: this.permission.exp_experiment_delete,
        editBtn: this.permission.exp_experiment_edit
      }
    }
  },
  methods: {
    openFrameList () {
      frameList(2).then((res) => {
        this.frameList = res.data
      })
      this.dialogFrame = true
    },
    handleCreate () {
      if (this.frameId) {
        this.$router.push({
          path: '/exp/subculture/step',
          query: { templateType: this.frameId }
        })
      } else {
        this.$message.error('请选择模板')
      }
    },
    handleUpdate (item) {
      this.$router.push({
        path: '/exp/subculture/step',
        query: { experimentId: item.id, stepId: item.currentStepId }
      })
    },
    passage (row) {
      this.$router.push({
        path: '/exp/subculture/step',
        query: { experimentId: row.id, templateType: 2, isPassage: 1 }
      })
    },
    searchChange (params, done) {
      this.onLoad(this.page, params)
      done()
    },
    searchReset () {
      this.onLoad(this.page)
    },
    rowDel (row) {
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(row.id).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    },
    handleDelete () {
      if (this.selectionList.length === 0) {
        this.$message.warning('请选择至少一条数据')
        return
      }
      this.$confirm('确定将选择数据删除?', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        remove(this.ids).then(res => {
          this.onLoad(this.page, this.search)
          if (!res.success) {
            this.$message({
              type: 'error',
              message: res.msg
            })
          }
        })
      })
    }
  }
}
</script>
