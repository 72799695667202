<template>
  <div class="timer">
    <el-input-number @input="handleblur" v-model="hour" style="width: 50px;margin-right: 5px" :min="0" step-strictly controls-position="right" :controls="false"></el-input-number>小时
    <el-input-number @input="handleblur" v-model="minute" style="width: 50px;margin-right: 5px" :value="0" :max="60" :min="0" step-strictly controls-position="right" :controls="false"></el-input-number>分钟
    <el-input-number @input="handleblur" v-model="second" style="width: 50px;margin-right: 5px" :value="0" :max="60" :min="0" step-strictly controls-position="right" :controls="false"></el-input-number>秒
  </div>
</template>

<script>
export default {
  name: 'TestTime',
  data () {
    return {
      hour: 0,
      minute: 0,
      second: 0
    }
  },
  props: {
    value: {
      type: String,
      default: '0:0:0'
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        const t = val.split(':')
        this.hour = t[0]
        this.minute = t[1]
        this.second = t[2]
      }
    }
  },
  methods: {
    handleblur () {
      this.$emit('input', `${this.hour}:${this.minute}:${this.second}`)
    }
  }
}
</script>

<style scoped>
.timer{
  clear: both;
  color: #76838f;
  font-size: 12px;
}
</style>
