<template>
  <basic-container>
    <avue-crud
            :before-open="beforeOpen"
            :data="data"
            :defaults.sync="defaults"
            :option="option"
            :page.sync="page"
            :permission="permissionList"
            :search.sync="search"
            @on-load="onLoad"
            @refresh-change="refreshChange"
            @row-del="rowDel"
            @row-save="rowSave"
            @row-update="rowUpdate"
            @search-change="searchChange"
            @search-reset="searchReset"
            @selection-change="selectionChange"
            ref="crud"
            v-model="form">
      <template slot="menuLeft">
        <el-button
                @click="handleDelete"
                icon="el-icon-delete"
                plain
                size="small"
                type="danger"
                v-if="permission.setting_basebarcode_delete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { mapGetters } from 'vuex'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'setting/basebarcode',
      form: {},
      search: {},
      defaults: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        searchMenuSpan: 6,
        labelWidth: 120,
        column: [
          {
            label: '主键',
            prop: 'id',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: false,
              message: '请输入主键'
            }]
          },
          {
            label: '应用类型',
            prop: 'type',
            row: true,
            type: 'select',
            hide: true,
            dicUrl: '/sys/dict/code/operator_type',
            props: {
              value: 'value',
              label: 'label'
            },
            rules: [{
              required: true,
              message: '选择应用类型'
            }]
          },
          {
            label: '上级分类',
            prop: 'parentId',
            dicUrl: '/setting/basebarcode/tree',
            type: 'tree',
            hide: true,
            props: {
              value: 'id',
              label: 'name'
            },
            disabled: false,
            rules: [{
              required: false,
              message: '请选择上级',
              trigger: 'click'
            }]
          },

          {
            label: '规则因子名',
            prop: 'name',
            search: true,
            searchLabelWidth: 100,
            rules: [{
              required: true,
              message: '请输入规则因子名'
            }]
          },
          {
            label: '规则代码',
            prop: 'code',
            rules: [{
              required: false,
              message: '请输入规则代码'
            }]
          },
          {
            label: '非级联选项',
            prop: 'singleItem',
            rules: [{
              required: false,
              message: '非级联选项'
            }],
            type: 'switch',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            value: 0
          },
          {
            label: '选项属性',
            prop: 'props',
            rules: [{
              required: false,
              message: '选项属性'
            }],
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/barcode_setting_props'
          },
          {
            label: '排序',
            prop: 'sort',
            rules: [{
              required: false,
              message: '请输入排序'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            rules: [{
              required: false,
              message: '请输入备注'
            }]
          },
          {
            label: '创建人',
            prop: 'createName',
            addDisplay: false,
            disabled: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            addDisplay: false,
            disabled: true
          },
          {
            label: '创建人',
            prop: 'creator',
            viewDisplay: false,
            addDisplay: false,
            editBtn: false,
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime1',
            viewDisplay: false,
            addDisplay: false,
            editBtn: false,
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0')
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.setting_basebarcode_add,
        viewBtn: this.permission.setting_basebarcode_view,
        delBtn: this.permission.setting_basebarcode_delete,
        editBtn: this.permission.setting_basebarcode_edit
      }
    }
  },
  watch: {
    'form.id' (id) {
      if (!id) {
        this.defaults.creator.addDisplay = true
        this.defaults.creator.editDisplay = true
        this.defaults.creator.viewDisplay = true
        this.defaults.createTime1.addDisplay = true
        this.defaults.createTime1.editDisplay = true
        this.defaults.createTime1.viewDisplay = true
      } else {
        this.defaults.creator.addDisplay = false
        this.defaults.creator.editDisplay = false
        this.defaults.creator.viewDisplay = false
        this.defaults.createTime1.addDisplay = false
        this.defaults.createTime1.editDisplay = false
        this.defaults.createTime1.viewDisplay = false
      }
    }
  },
  methods: {
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      if (this.$refs.crud) {
        this.$refs.crud.updateDic('parentId')
      }
      done()
    }
  }
}
</script>
