<template>
  <basic-container>
    <avue-crud
        :option="option"
        :data="data"
        :page.sync="page"
        :search.sync="search"
        ref="crud"
        @row-del="rowDel"
        v-model="form"
        :permission="permissionList"
        @row-update="rowUpdate"
        @row-save="rowSave"
        :before-open="beforeOpen"
        @search-change="searchChange"
        @search-reset="searchReset"
        @refresh-change="refreshChange"
        @selection-change="selectionChange"
        @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            plain
            v-if="permission.exp_device_delete"
            @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button
            @click.stop="showDataTagList(scope.row)"
            icon="el-icon-view"
            size="small"
            type="text"
            v-if="permission.exp_deviceresult_view">查看上传列表</el-button>
      </template>
    </avue-crud>
    <el-dialog :visible.sync="dialogTableVisible" @close="handleClose" title="上传结构列表" width="70%">
      <el-dialog
          width="60%"
          title=""
          :visible.sync="innerVisible"
          append-to-body
      >
        <img width="100%" :src="innerImageUrl" alt="" style="width:80%;height:80%">
      </el-dialog>
      <div class="el-dialog-div">
        <el-form style="height: 400px;text-align: center">
          <el-col :span="8" style="padding-right: 5px;text-align: center">
            <div style="margin-bottom: 20px;text-align: left;font-weight: bolder">目录</div>
            <el-tree
                :data="treeData"
                :expand-on-click-node="false"
                :props="defaultProps"
                @node-click="handleNodeClick"
                class="filter-tree"
                highlight-current
                accordion
                :key="deviceId"
                :render-after-expand="false"
                :load="loadNode"
                lazy
                ref="tree">
            </el-tree>
          </el-col>
          <el-col :span="16" style="padding-left: 5px;text-align: center">
            <div style="margin-bottom: 20px;text-align: left;font-weight: bolder">文件</div>
            <el-table
                :data="dataTag"
                border
                max-height="550"
                style="width: 100%">
              <el-table-column align="center" label="序号" width="50px">
                <template slot-scope="scope">
                  {{ (pageData.currentPage - 1) * pageData.pageSize + scope.$index + 1 }}
                </template>
              </el-table-column>
              <el-table-column
                  label="是否有样本"
                  prop="hasSample">
              </el-table-column>
              <el-table-column
                  label="文件类型">
                <template slot-scope="scope">
            <span v-if="scope.row.fileType === '1'">
              原始图像
            </span>
                  <span v-if="scope.row.fileType === '2'">
              算法图像
            </span>
                  <span v-if="scope.row.fileType === '3'">
              算法结果(表格)
            </span>
                  <span v-if="scope.row.fileType === '4'">
              算法结果(文档)
            </span>
                  <span v-if="scope.row.fileType === '5'">
              融合图像
            </span>
                </template>
              </el-table-column>
              <el-table-column
                  label="文件名"
                  prop="uploadName">
              </el-table-column>
              <el-table-column
                  label="培养板条码"
                  prop="boardNo">
              </el-table-column>
              <el-table-column
                  label="图片"
                  prop="filePath">
                <template v-slot="scope">
                  <el-image style="width: 90px; height: 90px" v-if="scope.row.fileType === '1' || scope.row.fileType === '2' || scope.row.fileType === '5'" :src="scope.row.filePath" @click="showImg(scope.row.filePath)">
                    <div slot="error" class="el-image__error" @click="showImg(scope.row.filePath)">
                      点击查看
                    </div>
                  </el-image>
                  <div v-else>
                    -
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                  label="高内涵结构"
                  prop="dataTag">
              </el-table-column>
              <el-table-column
                  label="操作"
                  width="50">
                <template slot-scope="scope">
                  <el-tooltip class="item" content="下载" effect="dark" placement="top">
                    <el-button @click="checkfile(scope.row)" icon="el-icon-download" size="small" type="text"></el-button>
                  </el-tooltip>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-form>
      </div>
      <div class="end pagination-container">
        <el-pagination
            :current-page.sync="pageData.currentPage"
            :page-size="pageData.pageSize"
            :page-sizes="[10,20,30, 50]"
            :total="pageData.total"
            @current-change="tableCurrentChange"
            @size-change="tableSizeChange"
            background
            layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      </div>
    </el-dialog>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getDeviceResultList, getTreeTest } from '@/api/exp/device'
import { fileSaveUrl } from '@/config/index'
import Tiff from 'tiff.js'
export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/device',
      form: {},
      search: {},
      dialogTableVisible: false,
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      page: {
        dataTag: '',
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      innerVisible: false,
      innerImageUrl: '',
      option: {
        tip: false,
        border: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '设备条码',
            prop: 'barcode',
            search: true,
            rules: [{
              required: true,
              message: '请输入设备条码'
            }]
          },
          {
            label: '设备类型',
            prop: 'type',
            rules: [{
              required: true,
              message: '请输入设备类型'
            }]
          },
          {
            label: '型号',
            prop: 'model',
            rules: [{
              required: true,
              message: '请输入型号'
            }]
          },
          {
            label: '生产日期',
            prop: 'productDate',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd'
          },
          {
            label: '实验中心',
            prop: 'centerId',
            type: 'select',
            dataType: 'String',
            dicUrl: '/sys/center/dict',
            addDisplay: false,
            editDisplay: false
          },
          {
            label: '备注',
            prop: 'remarks'
          },
          {
            label: '当前状态',
            prop: 'status',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入当前状态'
            }]
          },
          {
            label: '上次心跳时间',
            prop: 'heartTime',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入上次心跳时间'
            }]
          },
          {
            label: '创建时间',
            prop: 'createTime',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入创建时间'
            }]
          },
          {
            label: '修改时间',
            prop: 'updateTime',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入修改时间'
            }]
          },
          {
            label: '创建人',
            prop: 'createBy',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入创建人'
            }]
          },
          {
            label: '更新人',
            prop: 'updateBy',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入更新人'
            }]
          },
          {
            label: '乐观锁',
            prop: 'revision',
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [{
              required: true,
              message: '请输入乐观锁'
            }]
          }
        ]
      },
      data: [],
      pageData: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      dataTag: [],
      treeData: [],
      deviceId: ''
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_device_add,
        viewBtn: this.permission.exp_device_view,
        delBtn: this.permission.exp_device_delete,
        editBtn: this.permission.exp_device_edit
      }
    }
  },
  methods: {
    handleNodeClick (e) {
      this.page.dataTag = e.path
      getDeviceResultList(1, this.pageData.pageSize, { deviceId: this.deviceId, dataTag: e.path }).then(res => {
        this.listLoading = false
        const data = res.data
        this.pageData.total = data.total
        this.dataTag = data.records
      })
    },
    showImg (img) {
      if (img.indexOf('.tiff') !== -1) {
        urlToBlobBase64(img).then(res => {
          const arrayBuffer = base64ToArrayBuffer(res.base64)
          const url = new Tiff({ buffer: arrayBuffer })
          const image = url.toDataURL()
          this.innerImageUrl = image
          this.innerVisible = true
        })
      } else {
        this.innerImageUrl = img
        this.innerVisible = true
      }

      function urlToBlobBase64 (imgUrl) {
        return new Promise((resolve, reject) => {
          window.URL = window.URL || window.webkitURL
          const xhr = new XMLHttpRequest()
          xhr.open('get', imgUrl, true)
          xhr.responseType = 'blob'
          xhr.onload = function () {
            if (this.status === 200) {
              const blob = this.response
              const oFileReader = new FileReader()
              oFileReader.onloadend = function (e) {
                resolve({ blob, base64: e.target.result })
              }
              oFileReader.readAsDataURL(blob)
            } else {
              reject(new Error('异常'))
            }
          }
          xhr.send()
          xhr.onerror = () => {
            reject(new Error('异常'))
          }
        })
      }
      function base64ToArrayBuffer (base64) {
        base64 = base64.replace(/^data\:([^\;]+)\;base64,/gmi, '')
        const binary = atob(base64)
        const len = binary.length
        const buffer = new ArrayBuffer(len)
        const view = new Uint8Array(buffer)
        for (let i = 0; i < len; i++) {
          view[i] = binary.charCodeAt(i)
        }
        return buffer
      }
    },
    showDataTagList (row) {
      this.dialogTableVisible = true
      this.deviceId = row.id
      this.getDeviceResult(row.id)
    },
    loadNode (node, resolve) {
      const t = this
      console.log(node)
      setTimeout((res) => {
        if (node.level === 0) {
          getTreeTest({ deviceId: t.deviceId, parentId: 0 }).then(res => {
            const data = res.data
            resolve(data)
          })
        }
        if (node.level > 0) {
          getTreeTest({ deviceId: t.deviceId, parentId: node.data.id }).then(res => {
            const data = res.data
            resolve(data)
          })
        }
      }, 500)
    },
    getDeviceResult (deviceId) {
      getDeviceResultList(this.pageData.currentPage, this.pageData.pageSize, { deviceId: deviceId, dataTag: this.page.dataTag }).then(res => {
        this.listLoading = false
        const data = res.data
        this.pageData.total = data.total
        this.dataTag = data.records
      })
      // getTreeTest(deviceId).then(res => {
      //   const data = res.data
      //   this.treeData = data
      // })
    },
    handleClose () {
      this.dialogTableVisible = false
      this.dataTag = []
      this.treeData = []
      this.deviceId = ''
      this.pageData = {
        pageSize: 10,
        currentPage: 1,
        total: 0
      }
    },
    tableCurrentChange (val) {
      this.pageData.currentPage = val
      this.getDeviceResult(this.deviceId)
    },
    tableSizeChange (val) {
      this.pageData.pageSize = val
      this.getDeviceResult(this.deviceId)
    },
    checkfile (row) {
      if (row.filePath.includes('http')) {
        window.open(row.filePath)
      } else {
        window.open(fileSaveUrl + row.filePath)
      }
    }
  }
}
</script>
<style lang="scss">
.el-dialog-div {
  height: 60vh;
  overflow-x: hidden;
}
</style>
