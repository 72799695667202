<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.setting_barcode_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
      <template slot="nameSearch">
        <el-input v-model="search.queryName"/>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'setting/barcode',
      form: {},
      search: {
        queryName: ''
      },
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        addBtn: false,
        editBtn: false,
        selection: true,
        searchMenuSpan: 6,
        labelWidth: 120,
        column: [
          {
            label: '名称',
            prop: 'name',
            search: true,
            searchslot: true,
            maxlength: 20,
            overHidden: true,
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/setting/basebarcode/dict`
          },
          {
            label: '条码',
            prop: 'barcode',
            width: 150,
            rules: [{
              required: true,
              message: '请输入条码'
            }]
          },
          {
            label: '图片',
            prop: 'barcodeUrl',
            listType: 'picture-img',
            type: 'upload'
          },
          {
            label: '批号，8位数字',
            prop: 'batchNo',
            width: 110,
            rules: [{
              required: true,
              message: '请输入批号，8位数字'
            }]
          },
          {
            label: '生产日期，6位数字如221015',
            width: 110,
            prop: 'productionDate',
            rules: [{
              required: true,
              message: '请输入生产日期，6位数字如221015'
            }]
          },
          {
            label: '使用期限，6位数字如231015',
            prop: 'usePeriod',
            width: 110,
            rules: [{
              required: true,
              message: '请输入使用期限，6位数字如231015'
            }]
          },
          {
            label: '流水号，5位数字如00100',
            prop: 'serialNo',
            width: 110,
            rules: [{
              required: true,
              message: '请输入流水号，5位数字如00100'
            }]
          },
          {
            label: '目标编码',
            prop: 'objectCode',
            rules: [{
              required: true,
              message: '请输入目标编码'
            }]
          },
          {
            label: '品牌',
            prop: 'brand',
            rules: [{
              required: true,
              message: '请输入品牌'
            }]
          },
          {
            label: '型号',
            prop: 'model',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/setting/basebarcode/dict`,
            rules: [{
              required: true,
              message: '请输入型号'
            }]
          },
          {
            label: '规格',
            prop: 'specifications',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/setting/basebarcode/dict`,
            rules: [{
              required: true,
              message: '请输入规格'
            }]
          },
          {
            label: '类型',
            prop: 'type',
            type: 'select',
            search: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicData: [{
              label: '试剂',
              value: '1'
            }, {
              label: '耗材',
              value: '2'
            }, {
              label: '设备',
              value: '3'
            }, {
              label: '药品',
              value: '4'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.setting_barcode_add,
        viewBtn: this.permission.setting_barcode_view,
        delBtn: this.permission.setting_barcode_delete,
        editBtn: this.permission.setting_barcode_edit
      }
    }
  },
  methods: {
  }
}
</script>
