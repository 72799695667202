<template>
  <div style="overflow: auto">
    <div style="text-align: right; width: 552px; margin-bottom: 10px" v-if="!disabled">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    </div>
    <div :class="'tubeStand s' + value.holeColumns" :style="disabled?'filter: grayscale(50%);':''" id="ul">
      <div class="tubehead">
        <span v-for="(item, index) in getName(value.holeColumns)" :key="item">{{index + 1}}</span>
      </div>
      <div style="display: flex">
        <div class="tuberow">
          <span v-for="item in getName(value.holeRows,'letter')" :key="item">{{item}}</span>
        </div>
        <div class="tubeList freeze">
          <div v-for="(item, index) in tubeList" :class="(item.useExperimentId?(data.experimentId == item.useExperimentId ) && item.checkFlag :item.checkFlag)?'tubeHole active tubeHoleDrag':(disabled?'tubeHole tubeHoleDrag disabled':'tubeHole tubeHoleDrag')" :key="index" :id="'t' + index">
            <el-tooltip class="tubeHole-tip" effect="dark" :disabled="disabled" :content="item.holeIndex.toString()" placement="top">
              <template v-if="item.experimentId.length">

                <div class="tubeHole-freeze" @click="handleTube(item,index)" v-if="item.useExperimentId?(data.experimentId == item.useExperimentId ) && item.checkFlag :item.checkFlag">
                <i v-if="item.usedFlag" class="el-icon-lock"></i>
              </div>
              <div class="tubeHole-disabled" v-else>
                <i class="el-icon-close"></i>
              </div>
              </template>
<!--              <template>-->
<!--                <div class="tubeHole-freeze" @click="handleTube(item,index)" v-if="item.checkFlag ">-->
<!--                  <i v-if="item.usedFlag" class="el-icon-lock"></i></div>-->
<!--                <div class="tubeHole-disabled" v-else><i class="el-icon-close"></i></div>-->
<!--              </template>-->
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Selecto from 'selecto'

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const grid = {
  g6: [1, 6],
  g12: [2, 6],
  g24: [4, 6],
  g36: [6, 6],
  g48: [6, 8],
  g96: [8, 12],
  g384: [16, 24]
}
export default {
  name: 'TestTube',
  props: {
    value: {
      type: Object,
      default: () => ({
        barcode: '',
        barcodeId: '',
        componentId: 0,
        holeColumns: 6,
        holeRows: 4,
        sort: 0,
        specificationHoles: 24,
        stepId: 0,
        templateId: 0,
        holeList: []
      })
    },
    data: Object,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  // computed: {
  //   value: {
  //     get () {
  //       return this.value
  //     },
  //     set (value) {
  //       this.$emit('input', this.getValue(this.value))
  //     }
  //   }
  // },
  watch: {
    'data.hole': function () {
      this.init()
    }
  },
  created () {
    if (!this.value.holeList.length) {
      this.init()
    } else {
      this.tubeList = this.value.holeList
    }
  },
  mounted () {
    // const selecto = new Selecto({
    //   container: document.getElementById('ul'),
    //   rootContainer: document.getElementById('ul'),
    //   dragContainer: document.getElementById('ul'),
    //   selectableTargets: ['.tubeHoleDrag'],
    //   continueSelect: true,
    //   hitRate: 5
    // })
    // selecto.on('select', e => {
    //   if (this.disabled) {
    //     return
    //   }
    //   this.isIndeterminate = true
    //   if (this.freeze) {
    //     // let index = 0
    //     // if (e.added.length) {
    //     //   index = parseInt(e.added[0].id.slice(1))
    //     // } else {
    //     //   index = parseInt(e.removed[0].id.slice(1))
    //     // }
    //     // this.handleTube(index, this.tubeList[index])
    //     e.added.forEach(el => {
    //       const index = el.id.slice(1)
    //       if (el.className.indexOf('disabled') === -1) {
    //         this.handleTube(index, this.tubeList[index])
    //       }
    //     })
    //     e.removed.forEach(el => {
    //       const index = el.id.slice(1)
    //       this.handleTube(index, this.tubeList[index])
    //     })
    //   } else {
    //     e.added.forEach(el => {
    //       if (el.className.indexOf('disabled') === -1) {
    //         el.classList.add('active')
    //       }
    //     })
    //     e.removed.forEach(el => {
    //       el.classList.remove('active')
    //     })
    //   }
    //   this.$emit('input', this.getElements())
    // })
    // this.getValue(this.value)
  },
  data () {
    return {
      row: 0,
      col: 0,
      hole: 24,
      tubeList: [],
      checkAll: [],
      isIndeterminate: true
    }
  },
  methods: {
    init () {
      let row, col
      const hole = parseInt(this.data.hole)
      this.tubeList = []
      this.hole = hole
      if (grid[`g${hole}`]) {
        row = grid[`g${hole}`][0]
        col = grid[`g${hole}`][1]
        this.$emit('input', {
          ...this.value,
          barcode: this.data.barcode,
          holeColumns: col,
          holeRows: row
        })
      }
      const conut = row * col
      for (let i = 0; i < conut; i++) {
        const index = i + 1
        this.tubeList.push({
          blankFlag: 0,
          checkFlag: 0,
          columnOrder: Math.ceil(index / col),
          compareFlag: 0,
          holeIndex: index,
          holeName: `${letters[Math.ceil((i + 1) / col) - 1]}${i % col + 1}`,
          plateId: 0,
          rowOrder: Math.ceil(index / row),
          templateId: 0,
          usedFlag: 0
        })
      }
    },
    // getElements () {
    //   const elements = document.getElementsByClassName('tubeList')[0].childNodes
    //   const _arr = []
    //   elements.forEach((item, index) => {
    //     if (item.classList.value.indexOf('active') !== -1) {
    //       _arr.push(index)
    //     }
    //   })
    //   function findIndex (index, l) {
    //     for (let j = 0; j < l.length; j++) {
    //       if (l[j] === index) {
    //         return true
    //       }
    //     }
    //     return false
    //   }
    //   const arr = []
    //   if (this.freeze) {
    //     for (let i = 0; i < this.tubeList.length; i++) {
    //       if (this.tubeList[i].freeze) {
    //         arr.push(2)
    //       } else if (findIndex(i, _arr)) {
    //         arr.push(1)
    //       } else if (this.tubeList[i].disabled) {
    //         arr.push(-1)
    //       } else {
    //         arr.push(0)
    //       }
    //     }
    //   } else {
    //     for (let i = 0; i < this.tubeList.length; i++) {
    //       if (this.tubeList[i].freeze) {
    //         arr.push(2)
    //       } else if (this.tubeList[i].disabled) {
    //         arr.push(-1)
    //       } else if (findIndex(i, _arr)) {
    //         arr.push(1)
    //       } else {
    //         arr.push(0)
    //       }
    //     }
    //   }
    //   return arr
    // },
    handleCheckAllChange (value) {
      this.isIndeterminate = false
      if (this.freeze) {
        this.tubeList = this.tubeList.map((item) => {
          item.freeze = item.checked ? value : false
          return item
        })
      } else {
        this.tubeList = this.tubeList.map((item) => {
          item.checked = value
          return item
        })
      }
      const arr = []
      for (let i = 0; i < this.tubeList.length; i++) {
        if (this.tubeList[i].freeze) {
          arr.push(2)
        } else if (this.tubeList[i].disabled) {
          arr.push(-1)
        } else if (this.tubeList[i].checked) {
          arr.push(1)
        } else {
          arr.push(0)
        }
      }
      // const elements = document.getElementsByClassName('tubeList')[0].childNodes
      // elements.forEach((item, index) => {
      //   if (value) {
      //     item.classList.add('active')
      //   } else {
      //     item.classList.remove('active')
      //   }
      // })
      this.$emit('input', arr)
    },

    // getValue (value) {
    //   this.tubeList = []
    //   const hole = parseInt(this.value.specificationHoles)
    //   for (let i = 0; i < hole; i++) {
    //     this.tubeList.push({
    //       name: `${letters[Math.ceil((i + 1) / this.col) - 1]}${i % this.col + 1}`,
    //       index: i,
    //       disabled: value[i] === -1,
    //       checked: (value[i] === 1 || value[i] === 2),
    //       freeze: value[i] === 2
    //     })
    //   }
    //   // this.refresh(true, this.tubeList.length)
    // },
    getName (number, key) {
      if (key === 'letter') {
        const arr = []
        for (let i = 0; i < number; i++) {
          arr.push(letters[i])
        }
        return arr
      }
      return new Array(number)
    },
    handleTube (item, index) {
      if (this.disabled) {
        return
      }
      // this.tubeList[index].checkFlag = item.checkFlag ? 0 : 1
      item.usedFlag = item.usedFlag ? 0 : 1
      // this.isIndeterminate = true
      // if (this.freeze) {
      //   if (!this.tubeList[index].checked || this.tubeList[index].disabled) {
      //     return
      //   }
      //   this.tubeList[index].freeze = !item.freeze
      //   const arr = []
      //   for (let i = 0; i < this.tubeList.length; i++) {
      //     if (this.tubeList[i].freeze) {
      //       arr.push(2)
      //     } else if (this.tubeList[i].checked) {
      //       arr.push(1)
      //     } else if (this.tubeList[i].disabled) {
      //       arr.push(-1)
      //     } else {
      //       arr.push(0)
      //     }
      //   }
      //   // this.$emit('input', this.value)
      // } else {
      //   if (this.tubeList[index].disabled) {
      //     return
      //   }
      //   this.tubeList[index].checked = !item.checked
      //   const arr = []
      //   for (let i = 0; i < this.tubeList.length; i++) {
      //     if (this.tubeList[i].checked) {
      //       arr.push(1)
      //     } else if (this.tubeList[i].disabled) {
      //       arr.push(-1)
      //     } else {
      //       arr.push(0)
      //     }
      //   }
      this.$emit('input', {
        ...this.value,
        specificationHoles: parseInt(this.data.hole),
        holeList: this.tubeList
      })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.tubeHole-tip{
  width: 100%;
  height: 100%;
}
.tubeStand{
  padding: 20px 20px 10px 20px;
  width: 552px;
  border-radius: 30px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  user-select: none;
}
.tubeList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
}
$width-1x: 80px;
$margin-hole: $width-1x/10;
$width-real: $width-1x - $margin-hole;
.tubeHole{
  border-radius: 50%;
  border: 1px solid #DCDFE6;
  transition: all .2s;
  box-sizing: border-box;
  .tubeHole-freeze{
    color:#409EFF;
    text-align: center;
  }
  .tubeHole-disabled{
    color: #ff5659;
    text-align: center;
  }
}

.s6 .tubeHole{
  width: $width-real;
  height: $width-real;
  font-size:$width-real / 2 ;
  line-height:$width-real;
  flex-basis: 15%;
}
.s8 .tubeHole{
  width: $width-real * 0.75;
  height: $width-real * 0.75;
  font-size:$width-real * 0.75 / 2 ;
  line-height: $width-real * 0.75;
  flex-basis: 11%;
  margin: 0 $margin-hole/2 * 0.75 $margin-hole * 0.75 $margin-hole/2 * 0.75;
}
.s12 .tubeHole{
  width: $width-real * 0.5;
  height: $width-real * 0.5;
  font-size:$width-real * 0.5 / 2 ;
  line-height: $width-real * 0.5;
  flex-basis: 7.4%;
  margin: 0 $margin-hole/2 * 0.5 $margin-hole * 0.5 $margin-hole/2 * 0.5;
}
.s24 .tubeHole{
  width: $width-real * 0.25;
  line-height: $width-real * 0.25;
  font-size:$width-real * 0.25 / 2 ;
  height: $width-real * 0.25;
  margin: 0 $margin-hole/2 * 0.25 $margin-hole * 0.25 $margin-hole/2 * 0.25;
}
.tuberow{
  flex-shrink:0;
  width: 30px;
  span{
    box-sizing: border-box;
    color: #C0C4CC;
    display: block;
    text-align: center;
  }
}
.s6 .tuberow{

  span{
    height: $width-1x;
    line-height: $width-1x;
  }
}
.s8 .tuberow{

  span{
    height: $width-1x * 0.75;
    line-height: $width-1x * 0.75;
  }
}
.s12 .tuberow{

  span{
    height: $width-1x * 0.5;
    line-height: $width-1x * 0.5;
  }
}
.s24 .tuberow{

  span{
    height: $width-1x * 0.25;
    line-height: $width-1x * 0.25;
  }
}
.tubehead{
  display: flex;
  height: 30px;
  line-height: 30px;
  color: #C0C4CC;
  justify-content: space-between;
  padding-left: 30px;
  span{
    display: block;
    width: 60px;
    text-align: center;
  }
}
.s24 .tubehead{
  span{
    width: $width-1x * 0.25;
  }
}
.s12 .tubehead{
  span{
    width: $width-1x * 0.5;
  }
}
.s6 .tubehead{
  span{
    width: $width-1x;
  }
}
.s8 .tubehead{
  span{
    width: $width-1x * 0.75;
  }
}
.tubeHole.disabled{
  border: 1px solid #ff5659;
  background: #ffe2e2;
}
.normal{
  .tubeHole:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #409EFF;
    background: rgb(217, 236, 255);
    cursor: pointer;
  }
  .tubeHole.active{
    background: #409EFF;
    border: 1px solid #409EFF;
  }
}
  .tubeHole.disabled:hover{
    border: 1px solid #ff5659;
    background: #ffe2e2;
    box-shadow: none;
    cursor: not-allowed;
  }

.freeze{
  .tubeHole{
    cursor: not-allowed;
  }
  .tubeHole.active:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .tubeHole.active{
    background: rgb(217, 236, 255);
    border: 1px solid #409EFF;
  }
}
</style>
