<template>
  <basic-container>
    <div class="side-container">
      <div class="side-box item">
        <div class="side-box-title">实验步骤</div>
        <div class="side-box-content">

          <draggable
              tag="transition-group"
              :list="list"
              class="list-group"
              handle=".handle"
              @end="dragEnd"
          >

            <div
                :class="element.checked ? 'list-group-item active':'list-group-item'"
                v-for="(element, index) in list"
                :key="element.name"
                @click="changeStep(index)"
                style="display: flex;"
            >
              <i class="icon-font icon-menu handle" style="margin-right: 5px; vertical-align: -2px;"></i>
              <div class="list-group-item-input">
                <div class="pane">{{ element.name }}</div>
                <i class="iconfont icon-icon-test1" v-if="element.ignore"></i>
                <i class="iconfont icon-icon-test" v-if="!element.ignore"></i>
              </div>

              <div class="pane-del">
                <el-button circle size="mini" icon="el-icon-edit-outline" @click.stop="handleEdit(index)"></el-button>
              </div>
            </div>

            <div
                slot="footer"
                class="btn-group"
                role="group"
                aria-label="Basic example"
                key="footer"
            >
              <el-button type="primary" class="" icon="el-icon-plus" @click="add(list[list.length-1].id)"></el-button>
            </div>
          </draggable>

        </div>
      </div>
      <div class="side-box">
        <div class="side-box-title">{{ list[step].name }}</div>
        <div class="side-box-content">
          <div style="background: #EBEEF5; padding: 20px;border-radius: 4px" v-if="step !== 0">
          <el-form label-width="120px" >
            <el-form-item label="步骤时间:" prop="timer">
              <test-time v-model="list[step].timer"></test-time>
            </el-form-item>
            <el-form-item label="此步骤内容:" prop="content">
              <el-input type="textarea" v-model="list[step].content"></el-input>
            </el-form-item>
            <el-form-item label="此步骤到时提醒:" prop="remindMsg">
              <el-input type="textarea" v-model="list[step].remindMsg"></el-input>
            </el-form-item>
          </el-form>
          </div>
          <draggable
              class="dragArea list-group"
              :sort="false"
              :list="list[step].forms"
              group="people"
          >
            <div
                class="list-group-input"
                v-for="(element, index) in list[step].forms"
                :key="element.id"
            >
              <el-card class="box-card">
                <div slot="header">
                  <span>{{ element.label }}</span>
                  <el-button @click="del1(list[step].forms,index)" style="float: right; padding: 3px 0" type="text"><i class="el-icon-delete"></i></el-button>
                </div>
                <div class="box-card-top-bar">
                  <div>
                  <span>终端：</span>
                  <el-radio-group v-model="element.usageType" @input="changeTemp(index,$event)">
                  <el-radio-button v-for="item in getUsageList" :key="item.value" :label="item.value">{{item.label}}</el-radio-button>
                  </el-radio-group>
                  </div>
                  <div>
                  <span>是否可跳过：</span>
                  <el-switch
                      v-model="element.skip">
                  </el-switch>
                  </div>
                </div>
                <test-project v-if="renderFlag" :key="element.id" :type="element.value" v-model="element.form"></test-project>
              </el-card>
            </div>
          </draggable>
        </div>
      </div>
      <div class="side-box item">
        <div class="side-box-title">操作项目</div>
        <div class="side-box-content">

          <el-collapse v-model="activeNames">
            <el-collapse-item v-for="(element,index) in operatorList" :title="element.groupName" :name="index" :key="element.label">
              <draggable
                  class="dragArea list-group"
                  :list="element.children"
                  :sort="false"
                  :group="{ name: 'people', pull: 'clone', put: false }"
                  :move="log"
                  :clone="clone"
              >
                <div
                    class="list-group-item"
                    v-for="element2 in element.children"
                    :key="element2.value"
                >
                  <i class="el-icon-rank" style="margin-right: 5px;"></i>
                  {{ element2.label }}
                </div>
              </draggable>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
    <div style="text-align: center; margin-top:20px">
      <el-button size="medium" type="primary" @click="save">保存</el-button>
      <el-button @click="goBack" size="medium">返回</el-button>
    </div>
    <el-dialog
        title="编辑步骤"
        :visible.sync="isEdit"
        width="30%"
        center>
              <span>
                <el-form ref="form" label-width="80px">
                  <el-form-item label="名称">
                    <el-input class="pane-edit" v-model="activeElement.name"></el-input>
                  </el-form-item>
                  <el-form-item label="跳过">
                    <el-switch
                        v-model="activeElement.ignore">
                    </el-switch>
                  </el-form-item>
                </el-form>
              </span>
      <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="isEdit = false">确 定</el-button>
                <el-button type="danger" @click="del">删 除</el-button>
              </span>
    </el-dialog>
  </basic-container>
</template>

<script>
import { operators } from '@/api/setting/experimentdatatemplate'
import { submit, getDetail } from '@/api/setting/experimenttemplate'
import { getUsageType } from '@/api/setting/setting'
import draggable from 'vuedraggable'
// import testProject from '@/components/awd-test-step/test-form'
import { getRandomString } from '@/util/index'
import TestTime from '@/components/form/test-time'

export default {
  order: 14,
  components: {
    TestTime,
    testProject: () => import('@/components/awd-test-step/test-form'),
    draggable
  },
  data () {
    return {
      activeNames: [0, 1],
      step: 0,
      templateId: '',
      template: {},
      activeForm: {},
      activeElement: {},
      activeIndex: 0,
      isEdit: false,
      renderFlag: true,
      list: [
        { name: '样品选择', id: 1, checked: true, isEdit: false, forms: [] },
        { name: '清洗', id: 2, forms: [] },
        { name: '冻存', id: 3, forms: [] }
      ],
      dragging: false,
      componentData: {
        props: {
          type: 'transition',
          name: 'flip-list'
        }
      },
      list1: [
        {
          name: '消化液',
          id: 0
        },
        {
          name: '冻存液',
          id: 1,
          col1: '型号'
        }
      ],
      popoverTimes: 1,
      list2: [],
      operatorList: [],
      getUsageList: []
    }
  },
  created () {
    this.templateId = this.$route.query.templateId
    this.start()
    getUsageType().then(res => {
      this.getUsageList = res.data
    })
  },
  mounted () {
    this.detail()
  },
  methods: {
    changeTemp (index, label) {
      for (const item of this.getUsageList) {
        if (item.value === label) {
          this.list[this.step].forms[index].usageDesc = item.label
          this.list[this.step].forms[index].usageType = item.value
        }
      }
    },
    save () {
      this.template.content = this.list
      submit(this.template).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功!'
          })
          this.$router.push('/setting/experimenttemplate')
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    goBack () {
      this.$router.push('/setting/experimenttemplate')
    },
    start () {
      operators().then(res => {
        this.operatorList = res.data
      })
    },
    detail () {
      getDetail(this.templateId).then(res => {
        this.template = res.data
        if (res.data.content != null) {
          this.list = res.data.content
          const t = this.list
          for (let i = 0; i < t.length; i++) {
            // if (t[i].checked === true) {
            //   this.step = t[i].id - 1
            // }
            for (const j of t[i].forms) {
              j.complete = false
              j.skip = false
              j.usageType = '4'
            }
          }
        }
      })
    },
    dragEnd ($event) {
      if ($event.oldIndex !== this.step) return
      this.step = $event.newIndex
    },
    add: function (id) {
      this.list.push({ name: '新步骤 ' + (++id), id: id, forms: [] })
    },
    del1 (obj, idx) {
      this.$confirm('确定删除此条记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const arr = []
        for (let i = 0; i < obj.length; i++) {
          if (i !== idx) {
            arr.push(obj[i])
          }
        }
        this.list[this.step].forms = arr
        this.$nextTick()
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
    del () {
      const obj = this.list
      const idx = this.activeIndex
      this.$confirm('确定删除此条记录吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isEdit = false
        if (obj[idx].checked) {
          obj[idx].checked = false
          obj.splice(idx, 1)
          // for (let i = idx; i < obj.length; i++) {
          //   obj[i].id = obj[i].id - 1
          // }
          this.changeStep(idx - 1)
        } else {
          obj.splice(idx, 1)
          // for (let i = idx; i < obj.length; i++) {
          //   obj[i].id = obj[i].id - 1
          // }
        }

        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      })
    },
    clone (e) {
      const t = this
      this.renderFlag = false
      setTimeout(function () {
        t.renderFlag = true
      }, 1000)
      return { ...e, id: getRandomString(5), form: {}}
    },
    log: function (e, originalEvent) {
      const flag = true
      // console.log(this.list[this.step].forms)
      // e.draggedContext.element.id = getRandomString(5)
      // // for (const item of this.list[this.step].forms) {
      // //   if (item.value === e.draggedContext.element.value) {
      // //     flag = false
      // //     if (this.popoverTimes) {
      // //       this.$message({
      // //         message: '已经存在' + item.label + '表单组件',
      // //         type: 'warning'
      // //       })
      // //       this.popoverTimes = 0
      // //     }
      // //     setTimeout(() => {
      // //       this.popoverTimes = 1
      // //     }, 3000)
      // //     return flag
      // //   }
      // // }
      return flag
    },
    handleEdit (index) {
      this.activeIndex = index
      this.activeElement = this.list[index]
      this.isEdit = true
    },
    changeStep (index) {
      this.list = this.list.map(item => {
        item.checked = false
        return item
      })
      this.activeForm = this.list[index]
      this.list[index].checked = true
      this.step = index
    }
  }
}
</script>

<style lang="scss">
.list-group-input{
  -webkit-user-drag: none !important;
}
.el-card__body{
  height: 100%;
}
.side-container{
  display: flex;
  height: calc( 100% - 60px);
}
.pane-edit{
  margin-right: 10px;
}
.side-box{
  border-radius: 2px;
  border: 1px solid #DCDFE6;
  flex-grow: 1;
  margin: 0 10px;
  position: relative;
  height: 100%;
  .box-card-top-bar{
    font-size: 14px;
    color: #606266;
    line-height: 32px;
    border-bottom: 1px solid #DCDFE6;
    margin-bottom: 20px;
    padding-bottom: 20px;
    &>div{
      margin-bottom: 8px;
    }
    span{
      width: 90px;
    }
  }
  &.item{
    flex-grow: 0;
    width: 200px;
    margin: 0;
  }
  .side-box-title{
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background: #EBEEF5;
    border-bottom: 1px solid #DCDFE6;
    line-height: 40px;
    padding-left: 10px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 40px;
  }
  .side-box-content{
    position: absolute;
    left: 0;
    right: 0;
    top: 40px;
    bottom: 0;
    padding: 10px;
    min-height: 50%;
    overflow: auto;
    .pane{
      margin-right: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 70%;
    }
    .list-group-item{
      border: 1px solid #E4E7ED;
      border-radius: 2px;
      line-height: 40px;
      padding: 0 10px;
      font-size: 13px;
      margin-bottom: 10px;
      .pane-del{
        position: absolute;
        right: 20px;
        cursor: pointer;
      }
      .list-group-item-input{
        display: flex;
        position: relative;
      }
      &.active{
        border:1px solid #6699cc;
        background: #6699cc;
        color: #fff;
      }
    }
  }
}
.list-group{
  min-height: 50%;
}
.testOp {
  display: flex;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: #FFF;
  color: #303133;
  cursor: pointer;
  border-bottom: 1px solid #EBEEF5;
  font-size: 13px;
  font-weight: 500;
  -webkit-transition: border-bottom-color .3s;
  transition: border-bottom-color .3s;
  outline: 0;
}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
</style>
