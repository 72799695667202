<template>
    <basic-container>
        <div class="filter-container space-between">
            <div class="search-container start">
                <el-input
@keyup.enter.native="handleFilter"
class="filter-item"
placeholder="类型"
                          v-model="page.type"></el-input>
                <el-button class="filter-item" type="primary" icon="search" @click="handleFilter">搜索</el-button>
                <el-button class="filter-item" @click="handleCreate" type="primary" icon="edit">
                    添加
                </el-button>
            </div>
            <div>
            </div>
        </div>
        <el-table
:data="list"
v-loading.body="listLoading"
element-loading-text="拼命加载中"
border
fit
                  highlight-current-row>
            <el-table-column label="主键" prop="id">
                <template slot-scope="scope">
                    <span>{{scope.row.id}}</span>
                </template>
            </el-table-column>
            <el-table-column label="实验id" prop="expId">
                <template slot-scope="scope">
                    <span>{{scope.row.expId}}</span>
                </template>
            </el-table-column>
            <el-table-column label="孔id" prop="holeId">
                <template slot-scope="scope">
                    <span>{{scope.row.holeId}}</span>
                </template>
            </el-table-column>
            <el-table-column label="孔位：A1A2..." prop="holeName">
                <template slot-scope="scope">
                    <span>{{scope.row.holeName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="用药方案 1-靶向 2-化向" prop="drugType">
                <template slot-scope="scope">
                    <span>{{scope.row.drugType}}</span>
                </template>
            </el-table-column>
            <el-table-column label="药物id" prop="drugId">
                <template slot-scope="scope">
                    <span>{{scope.row.drugId}}</span>
                </template>
            </el-table-column>
            <el-table-column label="药物名称 多种药/分割" prop="drugName">
                <template slot-scope="scope">
                    <span>{{scope.row.drugName}}</span>
                </template>
            </el-table-column>
            <el-table-column label="母液浓度 多种药/分割" prop="motherLiquorConcentration">
                <template slot-scope="scope">
                    <span>{{scope.row.motherLiquorConcentration}}</span>
                </template>
            </el-table-column>
            <el-table-column label="终浓度" prop="finalConcentration">
                <template slot-scope="scope">
                    <span>{{scope.row.finalConcentration}}</span>
                </template>
            </el-table-column>
            <el-table-column label="配比浓度" prop="compoundingConcentration">
                <template slot-scope="scope">
                    <span>{{scope.row.compoundingConcentration}}</span>
                </template>
            </el-table-column>
            <el-table-column label="cmax" prop="cmaxValue">
                <template slot-scope="scope">
                    <span>{{scope.row.cmaxValue}}</span>
                </template>
            </el-table-column>
            <el-table-column label="配药比例" prop="dosageRate">
                <template slot-scope="scope">
                    <span>{{scope.row.dosageRate}}</span>
                </template>
            </el-table-column>
            <el-table-column label="配药量" prop="dosageCount">
                <template slot-scope="scope">
                    <span>{{scope.row.dosageCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="是否加药 0-否 1-是" prop="addFlag">
                <template slot-scope="scope">
                    <span>{{scope.row.addFlag}}</span>
                </template>
            </el-table-column>
            <el-table-column label="加药量" prop="addCount">
                <template slot-scope="scope">
                    <span>{{scope.row.addCount}}</span>
                </template>
            </el-table-column>
            <el-table-column label="实验中心id" prop="centerId">
                <template slot-scope="scope">
                    <span>{{scope.row.centerId}}</span>
                </template>
            </el-table-column>
            <el-table-column fixed="right" label="操作" width="150">
                <template slot-scope="scope">
                    <el-button size="small" type="primary" @click="handleUpdate(scope.row.id)">编辑</el-button>
                    <el-button
v-if="scope.row.status!='deleted'"
size="small"
type="danger"
                               @click="handleDelete(scope.row)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <div v-show="!listLoading" class="end pagination-container">
            <el-pagination
:current-page.sync="page.currentPage"
background
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"
                           :page-sizes="[10,20,30, 50]"
                           :page-size="page.pageSize"
                           layout="total, sizes, prev, pager, next, jumper"
:total="total">
            </el-pagination>
        </div>
        <el-dialog title="实验药信息" :visible.sync="showDialog">
            <el-row :gutter="20">
                <el-form
:model="form"
ref="form"
:rules="rules"
label-position="left"
                         label-width="120px"
class="small-space"
style='padding:20px 60px;'>
                    <el-col :span="12">
                        <el-form-item label="主键：" prop="id">
                            <el-input v-model="form.id"  disabled ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实验id：" prop="expId">
                            <el-input v-model="form.expId" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="孔id：" prop="holeId">
                            <el-input v-model="form.holeId" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="孔位：A1A2...：" prop="holeName">
                            <el-input v-model="form.holeName" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="用药方案 1-靶向 2-化向：" prop="drugType">
                            <el-input v-model="form.drugType" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="药物id：" prop="drugId">
                            <el-input v-model="form.drugId" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="药物名称 多种药/分割：" prop="drugName">
                            <el-input v-model="form.drugName" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="母液浓度 多种药/分割：" prop="motherLiquorConcentration">
                            <el-input v-model="form.motherLiquorConcentration" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="终浓度：" prop="finalConcentration">
                            <el-input v-model="form.finalConcentration" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="配比浓度：" prop="compoundingConcentration">
                            <el-input v-model="form.compoundingConcentration" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="cmax：" prop="cmaxValue">
                            <el-input v-model="form.cmaxValue" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="配药比例：" prop="dosageRate">
                            <el-input v-model="form.dosageRate" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="配药量：" prop="dosageCount">
                            <el-input v-model="form.dosageCount" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="是否加药 0-否 1-是：" prop="addFlag">
                            <el-input v-model="form.addFlag" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="加药量：" prop="addCount">
                            <el-input v-model="form.addCount" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实验中心id：" prop="centerId">
                            <el-input v-model="form.centerId" ></el-input>
                        </el-form-item>
                    </el-col>
                </el-form>
            </el-row>
            <div slot="footer" class="dialog-footer">
                <el-button @click="handleCancel">取 消</el-button>
                <el-button type="primary" @click="handleSubmit">确 定</el-button>
            </div>
        </el-dialog>
    </basic-container>
</template>

<script>
import { getList, remove, submit, getDetail } from '@/api/exp/experimentdosagedrug'

export default {
  data () {
    return {
      total: null,
      list: null,
      listLoading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      showDialog: false,
      form: {
        id: undefined,
        expId: undefined,
        holeId: undefined,
        holeName: undefined,
        drugType: undefined,
        drugId: undefined,
        drugName: undefined,
        motherLiquorConcentration: undefined,
        finalConcentration: undefined,
        compoundingConcentration: undefined,
        cmaxValue: undefined,
        dosageRate: undefined,
        dosageCount: undefined,
        addFlag: undefined,
        addCount: undefined,
        centerId: undefined
      },
      rules: {
        expId: [{ required: true, trigger: 'blur', message: '请输入 实验id' }],
        holeId: [{ required: true, trigger: 'blur', message: '请输入 孔id' }],
        holeName: [{ required: true, trigger: 'blur', message: '请输入 孔位：A1A2...' }],
        drugType: [{ required: true, trigger: 'blur', message: '请输入 用药方案 1-靶向 2-化向' }],
        drugId: [{ required: true, trigger: 'blur', message: '请输入 药物id' }],
        drugName: [{ required: true, trigger: 'blur', message: '请输入 药物名称 多种药/分割' }],
        motherLiquorConcentration: [{ required: true, trigger: 'blur', message: '请输入 母液浓度 多种药/分割' }],
        finalConcentration: [{ required: true, trigger: 'blur', message: '请输入 终浓度' }],
        compoundingConcentration: [{ required: true, trigger: 'blur', message: '请输入 配比浓度' }],
        cmaxValue: [{ required: true, trigger: 'blur', message: '请输入 cmax' }],
        dosageRate: [{ required: true, trigger: 'blur', message: '请输入 配药比例' }],
        dosageCount: [{ required: true, trigger: 'blur', message: '请输入 配药量' }],
        addFlag: [{ required: true, trigger: 'blur', message: '请输入 是否加药 0-否 1-是' }],
        addCount: [{ required: true, trigger: 'blur', message: '请输入 加药量' }],
        centerId: [{ required: true, trigger: 'blur', message: '请输入 实验中心id' }]
      }
    }
  },
  created () {
    this.onLoad(this.page)
  },

  methods: {
    onLoad (page, params = {}) {
      this.listLoading = true
      getList(page.currentPage, page.pageSize, params).then(res => {
        this.listLoading = false
        const data = res.data
        this.total = data.total
        this.list = data.records
      })
    },
    handleFilter () {
      this.onLoad(this.page)
    },
    handleCreate () {
      this.showDialog = true
    },
    handleUpdate (id) {
      getDetail(id).then(res => {
        this.form = res.data
        this.showDialog = true
      })
    },
    handleCurrentChange (val) {
      this.page.currentPage = val
      this.onLoad(this.page)
    },
    handleSizeChange (val) {
      this.page.pageSize = val
      this.onLoad(this.page)
    },
    handleDelete (row) {
      this.$confirm('确认删除吗？', '提示', {}).then(() => {
        remove(row.id).then(res => {
          if (res.success) {
            this.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            })
            this.onLoad(this.page)
          } else {
            this.$notify({
              title: '删除失败',
              message: res.data.message,
              type: 'error',
              duration: 2000
            })
          }
        })
      })
    },
    handleCancel () {
      this.showDialog = false
    },
    handleSubmit () {
      // 保存
      this.$refs.form.validate(valid => {
        if (valid) {
          submit(this.form).then(res => {
            if (res.success) {
              this.$notify({
                title: '成功',
                message: '操作成功',
                type: 'success',
                duration: 2000
              })
              this.showDialog = false
              this.onLoad(this.page)
            } else {
              this.$notify({
                title: '操作失败',
                message: res.data.message,
                type: 'error',
                duration: 2000
              })
            }
          })
        }
      })
    }
  }
}
</script>
