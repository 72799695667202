import { request } from '@/util/http'

export const getList = (current, size, params) => {
  return request({
    url: '/exp/device/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getDetail = (id) => {
  return request({
    url: '/exp/device/detail',
    method: 'get',
    params: {
      id
    }
  })
}

export const remove = (ids) => {
  return request({
    url: '/exp/device/remove',
    method: 'post',
    params: {
      ids
    }
  })
}

export const submit = (row) => {
  return request({
    url: '/exp/device/submit',
    method: 'post',
    data: row
  })
}

export const getDeviceResultList = (current, size, params) => {
  return request({
    url: '/exp/deviceresult/list',
    method: 'get',
    params: {
      ...params,
      current,
      size
    }
  })
}

export const getTreeTest = (params) => {
  return request({
    url: '/exp/deviceresult/pathTree',
    method: 'get',
    params
  })
}

