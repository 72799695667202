<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.market_treatmenthistory_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getPage } from '@/api/market/treatmenthistory'
import { mapGetters } from 'vuex'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market/treatmenthistory',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        span: 24,
        labelWidth: 200,
        align: 'center',
        column: [
          {
            label: '医院',
            prop: 'hospitalId',
            width: 105,
            addDisplay: true,
            overHidden: true,
            type: 'select',
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请选择医院'
            }],
            cascader: ['officeId'],
            cascaderIndex: 1,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            cascader: ['doctorId'],
            search: true,
            filterable: true,
            rules: [{
              required: true,
              message: '请选择科室'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/office/dict?hospitalId={{key}}`
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            search: true,
            cascader: ['patientId'],
            rules: [{
              required: true,
              message: '请选择医生'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/doctor/dict?officeId={{key}}`
          },
          {
            label: '患者',
            prop: 'patientId',
            type: 'select',
            search: true,
            rules: [{
              required: true,
              message: '请选择患者'
            }],
            filterable: true,
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: `/market/patient/dict?doctorId={{key}}`
          },
          {
            label: '手术时间',
            prop: 'operationTime',
            type: 'date',
            width: 100,
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
              type: 'datetime',
              format: 'yyyy-MM-dd'
            },
            rules: [{
              required: false,
              message: '请输入手术时间'
            }]
          },
          {
            label: '是否复发',
            prop: 'isRecurrence',
            type: 'radio',
            dicData: [{
              label: '否',
              value: 0
            }, {
              label: '是',
              value: 1
            }],
            mock: {
              type: 'dic'
            },
            rules: [{
              required: false,
              message: '请输入是否复发'
            }]
          },
          {
            label: '治疗方案',
            prop: 'treatmentPlan',
            type: 'textarea',
            maxlength: 200,
            width: 105,
            addDisplay: true,
            overHidden: true,
            showWordLimit: true,
            rules: [{
              required: false,
              message: '请输入治疗方案'
            }]
          },
          {
            label: '疗效评价',
            prop: 'efficacyEvaluation',
            width: 105,
            addDisplay: true,
            overHidden: true,
            type: 'textarea',
            maxlength: 200,
            showWordLimit: true,
            rules: [{
              required: false,
              message: '请输入疗效评价'
            }]
          },
          {
            label: '最后随访日期',
            width: 100,
            prop: 'followUpDate',
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            mock: {
              type: 'datetime',
              format: 'yyyy-MM-dd'
            },
            rules: [{
              required: false,
              message: '请输入最后随访日期'
            }]
          },
          {
            label: '备注',
            prop: 'remark',
            type: 'textarea',
            maxlength: 200,
            showWordLimit: true,
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            hide: true
          },
          {
            label: '创建人',
            prop: 'creator',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true
          },
          {
            label: '创建时间',
            prop: 'createTime',
            type: 'datetime',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            rules: [{
              required: false,
              message: '请输入备注'
            }],
            disabled: true,
            hide: true,
            value: new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0') + ' 00:00:00'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    permissionList () {
      return {
        addBtn: this.permission.market_treatmenthistory_add,
        viewBtn: this.permission.market_treatmenthistory_view,
        delBtn: this.permission.market_treatmenthistory_delete,
        editBtn: this.permission.market_treatmenthistory_edit
      }
    }
  },
  methods: {
    onLoad (page) {
      getPage(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
      // 延时执行级联加载
      const myThis = this
      setTimeout(function () {
        myThis.$refs.crud.dicInit('cascader')
      }, 500)
    },
    beforeOpen (done, type) {
      this.form.creator = this.userInfo.name
      done()
    }
  }
}
</script>
