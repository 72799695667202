<template>
  <div class="step_wrap">
    <el-steps direction="vertical" :active="active">
      <el-step v-for="(item, index) in list" :key="index" :status="active == index ? 'process':item.status == 1?'finish':'wait'">
        <div class="step_content" slot="title" @click="getStep(index)">{{ item.name }}</div>
      </el-step>
    </el-steps>
  </div>
</template>
<script>
export default {
  name: 'StepLine',
  props: {
    active: {
      type: Number
    },
    list: {
      type: Array
    }
  },
  data () {
    return {}
  },
  methods: {
    getStep (index) {
      if (this.list[index].status === 1) {
        this.$emit('update', index)
      }
    }
  }
}
</script>

<style scoped>
.step_content{
  height: 50px;
  cursor: pointer;
}
.step_wrap{
  width: 200px;
  border-right: 1px solid #DCDFE6;
  overflow-y: auto;
  flex-shrink: 0;
}
</style>
