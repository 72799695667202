<template>
  <basic-container>
    <div style="display: flex">
      <div ref="pie" class="chart"></div>
      <div ref="bar" class="chart"></div>
    </div>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'
import { getNumByStatisticType, getList } from '@/api/market/sample'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'market',
      form: {},
      hospitalData: [],
      cancerXAxis: [],
      cancerYAxis: [],
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        menu: false,
        index: true,
        viewBtn: false,
        selection: false,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        column: [
          {
            label: '样本编号',
            prop: 'code',
            width: 120
          },
          {
            label: '来源类型',
            prop: 'source',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/sys/dict/code/source_type'
          },
          {
            label: '医院',
            prop: 'hospitalId',
            type: 'select',
            width: 105,
            overHidden: true,
            rules: [{
              required: true,
              message: '请输入医院'
            }],
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/hospital/dict'
          },
          {
            label: '科室',
            prop: 'officeId',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/office/dict?hospitalId={{key}}'
          },
          {
            label: '医生',
            prop: 'doctorId',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/doctor/dict?officeId={{key}}'
          },
          {
            label: '患者',
            prop: 'patientId',
            type: 'select',
            props: {
              value: 'value',
              label: 'label'
            },
            dicUrl: '/market/patient/dict?doctorId={{key}}'
          },
          {
            label: '癌种',
            prop: 'cancerType',
            type: 'select',
            props: {
              value: 'value',
              label: 'name'
            },
            dicUrl: '/setting/set/code/cancer_type'
          }
        ]
      },
      data: []
    }
  },
  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    permissionList () {
    }
  },
  created () {
    this.getData()
  },
  mounted () {
    this.inOrigin()
    this.inBar()
  },
  methods: {
    getData () {
      getNumByStatisticType(1).then(res => {
        if (res.success) {
          this.hospitalData = res.data.filter(item => item.name)
          this.inOrigin()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
      getNumByStatisticType(0).then(res => {
        if (res.success) {
          const cancerData = res.data
          for (let i = 0; i < cancerData.length; i++) {
            this.cancerXAxis.push(cancerData[i].name)
            this.cancerYAxis.push(cancerData[i].value)
          }
          this.inBar()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    onLoad (page) {
      getList(page.currentPage, page.pageSize, this.search).then(res => {
        const data = res.data
        this.page.total = data.total
        this.data = data.records
      })
    },
    // 饼状图
    inOrigin () {
      const myChart = this.$echarts.init(this.$refs.pie)
      // 地域分布---圆形饼状图
      const optionPie = {
        title: {
          text: '医院统计',
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left',
          type: 'scroll',
          right: 10,
          top: 20,
          bottom: 20
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: '40%',
            center: ['70%', '50%'],
            avoidLabelOverlap: false,
            data: this.hospitalData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            },
            label: {
              normal: {
                formatter: function (param) {
                  const text = param.data.name
                  if (text.length < 20) {
                    return text + '\n' + param.data.value + '(' + param.percent + '%)'
                  } else {
                    return text.substring(0, 20) + '...' + '\n' + param.data.value + '(' + param.percent + '%)'
                  }
                }
              }
            }
          }
        ]
      }
      // 图标自适应
      const listener = function () {
        myChart.resize()
      }
      window.addEventListener('resize', listener)
      // 图标自适应
      myChart.setOption(optionPie)
    },
    // 柱状图
    inBar () {
      const myChart = this.$echarts.init(this.$refs.bar)
      // 地域分布---圆形饼状图
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        title: {
          text: '样本的癌种信息统计',
          left: 'center'
        },
        xAxis: {
          type: 'category',
          data: this.cancerXAxis,
          axisLabel: {
            interval: 0
          }
        },
        dataZoom: [
          {
            show: true,
            start: 0,
            end: 100
          },
          {
            type: 'inside',
            start: 0,
            end: 100
          }
        ],
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: this.cancerYAxis,
            type: 'bar',
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            }
          }
        ]
      }
      // 图标自适应
      const listener = function () {
        myChart.resize()
      }
      window.addEventListener('resize', listener)
      // 图标自适应
      myChart.setOption(option)
    }
  }
}
</script>
<style lang="scss">
.chart {
  width: 50%;
  height: 400px;
}
</style>
