<template>
  <div style="overflow: auto">
    <div style=" margin-top: 20px; width:552px;display: flex; justify-content: space-between">
      <div style="width: 100px">{{activeCount}} / {{data.count}}</div>
    <div style="text-align: right; width: 552px; margin-bottom: 10px" v-if="!disabled">
      <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
    </div>

    </div>
    <div :class="'tubeStand s' + col" :style="disabled?'filter: grayscale(50%);':''" id="ul">
      <div class="tubehead">
        <span v-for="(item, index) in getName(col)" :key="item">{{index + 1}}</span>
      </div>
      <div style="display: flex">
        <div class="tuberow">
          <span v-for="item in getName(row,'letter')" :key="item">{{item}}</span>
        </div>
        <div class="tubeList normal">
          <div v-for="(item, index) in tubeList" :class="item.checkFlag?'tubeHole active tubeHoleDrag':(disabled?'tubeHole tubeHoleDrag disabled':'tubeHole tubeHoleDrag')" :key="index" :id="'t' + index">
            <el-tooltip class="tubeHole-tip" effect="dark" :disabled="disabled" :content="item.holeIndex.toString()" placement="top">
              <div class="tubeHole-freeze" v-if="item.freeze"><i class="el-icon-lock"></i></div>
              <div class="tubeHole-disabled" v-else-if="disabled"><i class="el-icon-close"></i></div>
              <div @click="handleTube(item,index)" v-else></div>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Selecto from 'selecto'

import { getPlates } from '@/api/exp/experiment'

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const grid = {
  g6: [1, 6],
  g12: [2, 6],
  g24: [4, 6],
  g36: [6, 6],
  g48: [6, 8],
  g96: [8, 12],
  g384: [16, 24]
}
export default {
  name: 'TestTube',
  props: {
    value: {
      type: Object,
      default: () => ({
        barcode: '',
        barcodeId: '',
        componentId: 0,
        holeColumns: 6,
        holeRows: 4,
        sort: 0,
        specificationHoles: 24,
        stepId: 0,
        templateId: 0,
        holeList: []
      })
    },
    data: {
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    activeCount () {
      let num = 0
      for (const item of this.value.holeList) {
        if (item.checkFlag) {
          num++
        }
      }
      return num
    }
  },
  watch: {
    'data.count': function (val) {
      this.init(this.value)
    },
    'data.hole': function (val) {
      this.init(this.value)
    }
  },
  created () {
    getPlates({ experimentId: this.data.experimentId, type: this.data.optionType === 24 ? 2 : 1 }).then(res => {
      this.plateList = res.data
      const value = this.plateList.find(res => res.barcode === this.data.barcode)
      if (!value) {
        this.init(this.value)
      } else {
        this.tubeList = value.holeList
        this.init(value)
        this.$emit('input', value)
      }
    })
  },
  mounted () {
    // const selecto = new Selecto({
    //   container: document.getElementById('ul'),
    //   rootContainer: document.getElementById('ul'),
    //   dragContainer: document.getElementById('ul'),
    //   selectableTargets: ['.tubeHoleDrag'],
    //   continueSelect: true,
    //   hitRate: 5
    // })
    // selecto.on('select', e => {
    //   if (this.disabled) {
    //     return
    //   }
    //   this.isIndeterminate = true
    //   if (this.freeze) {
    //     // let index = 0
    //     // if (e.added.length) {
    //     //   index = parseInt(e.added[0].id.slice(1))
    //     // } else {
    //     //   index = parseInt(e.removed[0].id.slice(1))
    //     // }
    //     // this.handleTube(index, this.tubeList[index])
    //     e.added.forEach(el => {
    //       const index = el.id.slice(1)
    //       if (el.className.indexOf('disabled') === -1) {
    //         this.handleTube(index, this.tubeList[index])
    //       }
    //     })
    //     e.removed.forEach(el => {
    //       const index = el.id.slice(1)
    //       this.handleTube(index, this.tubeList[index])
    //     })
    //   } else {
    //     e.added.forEach(el => {
    //       if (el.className.indexOf('disabled') === -1) {
    //         el.classList.add('active')
    //       }
    //     })
    //     e.removed.forEach(el => {
    //       el.classList.remove('active')
    //     })
    //   }
    //   this.$emit('input', this.getElements())
    // })
    // this.getValue(this.value)
  },
  data () {
    return {
      row: 0,
      col: 0,
      hole: 24,
      tubeList: [],
      checkAll: [],
      isIndeterminate: true,
      plateList: []
    }
  },
  methods: {
    init (value) {
      let row, col
      const hole = this.data.hole || value.specificationHoles
      this.tubeList = []
      this.hole = hole
      if (grid[`g${hole}`]) {
        row = grid[`g${hole}`][0]
        col = grid[`g${hole}`][1]
        this.$emit('input', {
          ...this.value,
          barcode: this.data.barcode,
          holeColumns: col,
          holeRows: row
        })
      }
      this.col = col
      this.row = row
      const conut = row * col
      for (let i = 0; i < conut; i++) {
        const index = i + 1
        if (value.holeList.length) {
          this.tubeList.push({
            ...value.holeList[index],
            blankFlag: 0,
            checkFlag: value.holeList[index] ? value.holeList[index].checkFlag : 0,
            columnOrder: Math.ceil(index / col),
            compareFlag: 0,
            holeIndex: index,
            holeName: `${letters[Math.ceil((i + 1) / col) - 1]}${i % col + 1}`,
            plateId: 0,
            rowOrder: Math.ceil(index / row),
            templateId: 0,
            usedFlag: 0
          })
        } else {
          this.tubeList.push({
            blankFlag: 0,
            checkFlag: 0,
            columnOrder: Math.ceil(index / col),
            compareFlag: 0,
            holeIndex: index,
            holeName: `${letters[Math.ceil((i + 1) / col) - 1]}${i % col + 1}`,
            plateId: 0,
            rowOrder: Math.ceil(index / row),
            templateId: 0,
            usedFlag: 0
          })
        }
      }
    },
    // getElements () {
    //   const elements = document.getElementsByClassName('tubeList')[0].childNodes
    //   const _arr = []
    //   elements.forEach((item, index) => {
    //     if (item.classList.value.indexOf('active') !== -1) {
    //       _arr.push(index)
    //     }
    //   })
    //   function findIndex (index, l) {
    //     for (let j = 0; j < l.length; j++) {
    //       if (l[j] === index) {
    //         return true
    //       }
    //     }
    //     return false
    //   }
    //   const arr = []
    //   if (this.freeze) {
    //     for (let i = 0; i < this.tubeList.length; i++) {
    //       if (this.tubeList[i].freeze) {
    //         arr.push(2)
    //       } else if (findIndex(i, _arr)) {
    //         arr.push(1)
    //       } else if (this.tubeList[i].disabled) {
    //         arr.push(-1)
    //       } else {
    //         arr.push(0)
    //       }
    //     }
    //   } else {
    //     for (let i = 0; i < this.tubeList.length; i++) {
    //       if (this.tubeList[i].freeze) {
    //         arr.push(2)
    //       } else if (this.tubeList[i].disabled) {
    //         arr.push(-1)
    //       } else if (findIndex(i, _arr)) {
    //         arr.push(1)
    //       } else {
    //         arr.push(0)
    //       }
    //     }
    //   }
    //   return arr
    // },
    handleCheckAllChange (value) {
      this.isIndeterminate = false
      this.$emit('input', {
        ...this.value,
        holeColumns: this.col,
        holeRows: this.row,
        specificationHoles: parseInt(this.data.hole),
        holeList: this.tubeList
      })
    },
    getName (number, key) {
      if (key === 'letter') {
        const arr = []
        for (let i = 0; i < number; i++) {
          arr.push(letters[i])
        }
        return arr
      }
      return new Array(number)
    },
    handleTube (item, index) {
      if (this.disabled) {
        return
      }
      if (this.activeCount >= this.data.count) {
        this.$message({
          type: 'warning',
          message: '铺板孔数不能大于方案孔数!'
        })
        item.checkFlag = 0
      } else {
        item.checkFlag = item.checkFlag ? 0 : 1
      }
      this.$emit('input', {
        ...this.value,
        holeColumns: this.col,
        holeRows: this.row,
        specificationHoles: parseInt(this.data.hole),
        holeList: this.tubeList
      })
      // }
    }
  }
}
</script>

<style lang="scss" scoped>
.tubeHole-tip{
  width: 100%;
  height: 100%;
}
.tubeStand{
  padding: 20px 20px 10px 20px;
  width: 552px;
  border-radius: 30px;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  user-select: none;
}
.tubeList{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
}
$width-1x: 80px;
$margin-hole: $width-1x/10;
$width-real: $width-1x - $margin-hole;
.tubeHole{
  border-radius: 50%;
  border: 1px solid #DCDFE6;
  transition: all .2s;
  box-sizing: border-box;
  .tubeHole-freeze{
    color:#409EFF;
    text-align: center;
  }
  .tubeHole-disabled{
    color: #ff5659;
    text-align: center;
  }
}

.s6 .tubeHole{
  width: $width-real;
  height: $width-real;
  font-size:$width-real / 2 ;
  line-height:$width-real;
  flex-basis: 15%;
}
.s8 .tubeHole{
  width: $width-real * 0.75;
  height: $width-real * 0.75;
  font-size:$width-real * 0.75 / 2 ;
  line-height: $width-real * 0.75;
  flex-basis: 11%;
  margin: 0 $margin-hole/2 * 0.75 $margin-hole * 0.75 $margin-hole/2 * 0.75;
}
.s12 .tubeHole{
  width: $width-real * 0.5;
  height: $width-real * 0.5;
  font-size:$width-real * 0.5 / 2 ;
  line-height: $width-real * 0.5;
  flex-basis: 7.4%;
  margin: 0 $margin-hole/2 * 0.5 $margin-hole * 0.5 $margin-hole/2 * 0.5;
}
.s24 .tubeHole{
  width: $width-real * 0.25;
  line-height: $width-real * 0.25;
  font-size:$width-real * 0.25 / 2 ;
  height: $width-real * 0.25;
  margin: 0 $margin-hole/2 * 0.25 $margin-hole * 0.25 $margin-hole/2 * 0.25;
}
.tuberow{
  flex-shrink:0;
  width: 30px;
  span{
    box-sizing: border-box;
    color: #C0C4CC;
    display: block;
    text-align: center;
  }
}
.s6 .tuberow{

  span{
    height: $width-1x;
    line-height: $width-1x;
  }
}
.s8 .tuberow{

  span{
    height: $width-1x * 0.75;
    line-height: $width-1x * 0.75;
  }
}
.s12 .tuberow{

  span{
    height: $width-1x * 0.5;
    line-height: $width-1x * 0.5;
  }
}
.s24 .tuberow{

  span{
    height: $width-1x * 0.25;
    line-height: $width-1x * 0.25;
  }
}
.tubehead{
  display: flex;
  height: 30px;
  line-height: 30px;
  color: #C0C4CC;
  justify-content: space-between;
  padding-left: 30px;
  span{
    display: block;
    width: 60px;
    text-align: center;
  }
}
.s24 .tubehead{
  span{
    width: $width-1x * 0.25;
  }
}
.s12 .tubehead{
  span{
    width: $width-1x * 0.5;
  }
}
.s6 .tubehead{
  span{
    width: $width-1x;
  }
}
.s8 .tubehead{
  span{
    width: $width-1x * 0.75;
  }
}
.tubeHole.disabled{
  border: 1px solid #ff5659;
  background: #ffe2e2;
}
.normal{
  .tubeHole:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #409EFF;
    background: rgb(217, 236, 255);
    cursor: pointer;
  }
  .tubeHole.active{
    background: #409EFF;
    border: 1px solid #409EFF;
  }
}
  .tubeHole.disabled:hover{
    border: 1px solid #ff5659;
    background: #ffe2e2;
    box-shadow: none;
    cursor: not-allowed;
  }

.freeze{
  .tubeHole{
    cursor: not-allowed;
  }
  .tubeHole.active:hover{
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }
  .tubeHole.active{
    background: rgb(217, 236, 255);
    border: 1px solid #409EFF;
  }
}
</style>
