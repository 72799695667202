<template>
  <basic-container>
    <div class="form-wrap">
      <step-line :active="step - 1" :canEdit="stepNo" :list="formList" @update="changeStep"></step-line>
      <div>
        <div>
          <i class="el-icon-arrow-left"></i>
          上一步
        </div>
      </div>
    </div>
  </basic-container>
</template>

<script>
import stepLine from '@/components/step-form/step-line'
import { getCurrentByType } from '@/api/setting/experimenttemplate'
import { getDetail as getExperiment } from '@/api/exp/experiment'
export default {
  components: {
    stepLine
  },
  mounted () {
    this.detail()
  },
  methods: {
    detail () {
      // 若是第一步
      if (this.step === 1 && this.experiment.id == null) {
        getCurrentByType(this.templateType).then(res => {
          if (res.success) {
            if (res.data.content != null) {
              this.formList = res.data.content
              this.experiment.templateId = res.data.id
              this.checkedStep = this.formList.map((item, index) => {
                return index
              })
              this.formList = this.formList.map(item => {
                for (let i = 0; i < item.forms.length; i++) {
                  item.forms[i].form = item.forms[i].form || {}
                }
                return item
              })
            } else {
              this.$message({
                type: 'error',
                message: '暂无可用实验模板!'
              })
              this.$router.push({
                path: '/exp/primaryculture/index'
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.msg
            })
            this.$router.push({
              path: '/exp/primaryculture/index'
            })
          }
        })
      } else {
        getExperiment(this.experiment.id).then(res => {
          this.stepNo = res.data.stepNo
          this.step = res.data.stepNo
          this.experiment = res.data
          this.formList = this.experiment.content
          this.stepIsFinal()
          this.checkoutTube([11, 9])
          // this.initTubeDay([11, 9], [15, 29])
        })
      }
    }
  }
}
</script>

<style scoped>

.form-wrap{
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 20px;
}
</style>
