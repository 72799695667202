<template>
  <basic-container>
    <avue-crud
            :option="option"
            :data="data"
            :page.sync="page"
            :search.sync="search"
            ref="crud"
            @row-del="rowDel"
            v-model="form"
            :permission="permissionList"
            @row-update="rowUpdate"
            @row-save="rowSave"
            :before-open="beforeOpen"
            @search-change="searchChange"
            @search-reset="searchReset"
            @refresh-change="refreshChange"
            @selection-change="selectionChange"
            @on-load="onLoad">
      <template slot="menuLeft">
        <el-button
                type="danger"
                size="small"
                icon="el-icon-delete"
                plain
                v-if="permission.exp_experimentdrug_delete"
                @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import mixin from '@/util/mixin'

export default {
  mixins: [mixin],
  data () {
    return {
      module: 'exp/experimentdrug',
      form: {},
      search: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      selectionList: [],
      option: {
        tip: false,
        border: true,
        index: true,
        viewBtn: true,
        selection: true,
        column: [
          {
            label: '主键',
            prop: 'id',
            rules: [{
              required: true,
              message: '请输入主键'
            }]
          },
          {
            label: '实验中心',
            prop: 'centerId',
            rules: [{
              required: true,
              message: '请输入实验中心'
            }]
          },
          {
            label: '实验id',
            prop: 'experimentId',
            rules: [{
              required: true,
              message: '请输入实验id'
            }]
          },
          {
            label: '样本id',
            prop: 'sampleId',
            rules: [{
              required: true,
              message: '请输入样本id'
            }]
          },
          {
            label: '药品id',
            prop: 'drugId',
            rules: [{
              required: true,
              message: '请输入药品id'
            }]
          },
          {
            label: '图表名称',
            prop: 'name',
            rules: [{
              required: true,
              message: '请输入图表名称'
            }]
          },
          {
            label: '浓度',
            prop: 'concentration',
            rules: [{
              required: true,
              message: '请输入浓度'
            }]
          },
          {
            label: '杀伤力',
            prop: 'kill',
            rules: [{
              required: true,
              message: '请输入杀伤力'
            }]
          },
          {
            label: 'IC50',
            prop: 'ic',
            rules: [{
              required: true,
              message: '请输入IC50'
            }]
          },
          {
            label: '药敏结果图片',
            prop: 'pic',
            rules: [{
              required: true,
              message: '请输入药敏结果图片'
            }]
          }
        ]
      },
      data: []
    }
  },
  computed: {
    permissionList () {
      return {
        addBtn: this.permission.exp_experimentdrug_add,
        viewBtn: this.permission.exp_experimentdrug_view,
        delBtn: this.permission.exp_experimentdrug_delete,
        editBtn: this.permission.exp_experimentdrug_edit
      }
    }
  },
  methods: {
  }
}
</script>
